import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  KeyboardAvoidingView, LayoutAnimation, TextInput, View} from 'react-native';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import Api, {UserInfo} from '../../common/api';
import {
  IconApple,
  IconGoogle,
  IconSyncOn,
} from '../../platform/icons';
import * as Google from 'expo-auth-session/providers/google';
import * as Facebook from 'expo-auth-session/providers/facebook';
import {toastError, toastSuccess} from '../../platform/toast';
import {ResponseType} from 'expo-auth-session';
import {AppContext} from '../../common/context';
import * as Crypto from 'expo-crypto';
import * as AppleAuthentication from 'expo-apple-authentication';
import {
  auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from '../../platform/firebase';
import {User} from '../../common/store';
import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {Dialog, Button, Text} from '@rneui/themed';
import {useTheme} from '@rneui/themed';
import {Prompt} from '../../components/Prompt';
import {dialog} from '../../platform/dialog';
import {useRecoilState} from 'recoil';
import {settingsState} from '../../recoil/atoms';

const GOOGLE_CLIENT_ID = '';
const FACEBOOK_CLIENT_ID = '';

export const Account = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);

  const [user, setUser] = useState<FirebaseAuthTypes.User | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const {loadAppData, actions} = useContext(AppContext);

  const [, responseGg, promptAsyncGg] = Google.useIdTokenAuthRequest({
    clientId: GOOGLE_CLIENT_ID,
  });

  const [, responseFb, promptAsyncFb] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: FACEBOOK_CLIENT_ID,
  });

  const {theme} = useTheme();

  // Login
  useEffect(() => {
    auth?.currentUser?.reload().then(() => {
      setUser(auth?.currentUser || null);
    });

    const unsubscribe = auth?.onAuthStateChanged(function(u) {
      setUser(u);
      loadAppData().catch((e) =>
        toastError('Could not load app data.\nPlease try again later.', e),
      );
    });

    return unsubscribe;
  }, []);

  const refreshUserInfo = async (user: User | null) => {
    await auth?.currentUser?.reload();
    if (user && user.uid) {
      const ui = await Api.Profile.getUserInfo(user.uid);
      setUserInfo(ui);
    } else {
      setUserInfo(null);
    }
  };

  useEffect(() => {
    refreshUserInfo(user);
  }, [user]);

  React.useEffect(() => {
    if (responseGg?.type === 'success') {
      // eslint-disable-next-line camelcase
      const {id_token} = responseGg.params;

      const credential = GoogleAuthProvider.credential(id_token);
      auth?.signInWithCredential(credential).then(() => {
        toastSuccess('User logged in.');
      });
    }
  }, [responseGg]);

  React.useEffect(() => {
    if (responseFb?.type === 'success') {
      // eslint-disable-next-line camelcase
      const {access_token} = responseFb.params;

      const credential = FacebookAuthProvider.credential(access_token);
      // Sign in with the credential from the Facebook user.
      auth?.signInWithCredential(credential);
    }
  }, [responseFb]);

  /**
   * Log out and ask to clear local data.
   */
  const logout = async () => {
    await auth?.signOut();
    dialog(
        'You have been logged out.',
        'Do you want to clear local data on this device? ' +
        'Data will be restored when you log in again.',
        [
          {
            text: 'Remove',
            onPress: async () => {
              await actions.clearLocalData();
            },
          },
          {text: 'Keep'},
        ],
    );
  };

  const deleteAccount = async () => {
    dialog(
        'Delete Account',
        'Do you want to permanently delete your account? This will be ' +
        'executed immediately and cannot be undone.',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'Delete Account',
            style: 'destructive',
            onPress: () => {
              Api.Profile.deleteAccount().then(async () => {
                await actions.clearLocalData();
                await refreshUserInfo(user);
                dialog(
                    'Account Deleted',
                    'Your account and data have been deleted.');
              });
            },
          },
        ],
    );
  };

  return user ? (
    <PreferencesScreen
      sections={[
        ...((user || userInfo) ?
          [] :
          [
            {
              id: 'login',
              title: '',
              items: () => [
                {
                  id: 'login_with_google',
                  title: 'Login with Google',
                  icon: <IconGoogle />,
                  kind: ItemKind.Button,
                  onPress: () => {
                    promptAsyncGg().catch(() =>
                      toastError('There was a problem with logging in.'),
                    );
                  },
                },
                {
                  id: 'login_with_facebook',
                  title: 'Login with Facebook',
                  kind: ItemKind.Button,
                  hidden: true,
                  onPress: () => {
                    promptAsyncFb().catch(() =>
                      toastError('There was a problem with logging in.'),
                    );
                  },
                },
                {
                  id: 'login_with_apple',
                  title: 'Login with Apple',
                  kind: ItemKind.Button,
                  icon: <IconApple />,
                  onPress: () => {
                    const nonce = Math.random().toString(36).substring(2, 10);

                    return Crypto.digestStringAsync(
                        Crypto.CryptoDigestAlgorithm.SHA256,
                        nonce,
                    ).then((hashedNonce) =>
                      AppleAuthentication.signInAsync({
                        requestedScopes: [
                          AppleAuthentication.AppleAuthenticationScope
                              .FULL_NAME,
                          AppleAuthentication.AppleAuthenticationScope.EMAIL,
                        ],
                        nonce: hashedNonce,
                      }),
                    );
                    // .then((appleCredential) => {
                    //   const {identityToken} = appleCredential;
                    //   if (!identityToken) return;
                    //   const provider = new auth.OAuthProvider(
                    //       'apple.com',
                    //   );
                    //   const credential = provider.credential({
                    //     idToken: identityToken,
                    //     rawNonce: nonce,
                    //   });
                    //   return auth?
                    //       .signInWithCredential(credential);
                    //   // Successful sign in is handled by
                    //   // firebase.auth?.onAuthStateChanged
                    // });
                  },
                },
              ],
            },
          ]),
        ...(!user?.emailVerified ? [
          {
            id: 'verification',
            title: '',
            items: () => [
              {
                id: 'verify_email',
                title: 'Resend Verification Email',
                kind: ItemKind.Button,
                onPress: async () => {
                  await user.sendEmailVerification();
                  dialog('Email Verification Sent', 'Please check your email.');
                },
              },
            ],
          },
        ] : []),
        ...(user ?
          [
            {
              id: 'profile',
              title: '',
              items: () => [
                // {
                //   id: 'name',
                //   kind: ItemKind.Input,
                //   title: 'Name',
                //   inline: true,
                //   value: user?.displayName,
                //   readonly: true,
                // },
                {
                  id: 'display_name',
                  kind: ItemKind.Input,
                  title: 'Display Name',
                  inline: true,
                  value: userInfo?.name,
                  onChange: (text: unknown) => {
                    const newUserInfo = {
                      ...userInfo, name: text as string || '',
                    };
                    setUserInfo(newUserInfo);
                    Api.Profile.saveUserInfo(newUserInfo).then(() =>
                      refreshUserInfo(user),
                    );
                  },
                },
                {
                  id: 'email',
                  kind: ItemKind.Input,
                  title: 'Email',
                  inline: true,
                  value: user?.email,
                  readonly: true,
                },
                {
                  id: 'affliation',
                  kind: ItemKind.Input,
                  title: 'Affliation',
                  inline: true,
                  value: userInfo?.affliation,
                  onChange: async (text: unknown) => {
                    if (!userInfo) return;
                    Api.Profile.saveUserInfo({
                      ...userInfo, affliation: text as string});
                    refreshUserInfo(user);
                  },
                },
                {
                  id: 'website',
                  kind: ItemKind.Input,
                  title: 'Website',
                  inline: true,
                  value: userInfo?.website,
                  onChange: async (text: unknown) => {
                    if (!userInfo) return;
                    Api.Profile.saveUserInfo({
                      ...userInfo, website: text as string});
                    refreshUserInfo(user);
                  },
                },
              ],
            },
          ] :
          []),
        {
          id: 'password',
          title: '',
          hidden: !user.email,
          items: () => [
            {
              id: 'change_password',
              title: 'Reset Password',
              onPress: () => {
                if (!user?.email) return;
                auth?.sendPasswordResetEmail(user?.email);
                dialog('Password Reset Email Sent', 'Please check your email.');
              },
            },
          ],
        },
        {
          id: 'others',
          title: '',
          items: () => [
            {
              id: 'sync',
              kind: ItemKind.CheckBox,
              title: 'Auto Sync',
              subTitle: 'Your data is synced accross devices in real-time.',
              value: settings.sync.enabled,
              devOnly: true,
              onPress: () =>
                setSettings({
                  ...settings,
                  sync: {...settings.sync, enabled: !settings.sync.enabled},
                }),
            },
            {
              id: 'sync_now',
              kind: ItemKind.Button,
              title: 'Sync Now...',
              icon: <IconSyncOn />,
              onPress: () => {
                loadAppData();
              },
              disabled: !settings.sync.enabled,
              devOnly: true,
              hidden: true,
            },
            {
              id: 'logout',
              kind: ItemKind.Button,
              title: 'Logout',
              onPress: logout,
            },
            {
              id: 'delete_account',
              kind: ItemKind.Button,
              title: 'Delete Account',
              onPress: deleteAccount,
              color: theme.colors.error,
            },
          ],
        },
      ]}
      extraData={user}
    />
  ) : (<LoginScreen />);
};

export const LoginScreen = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordRetyped, setPasswordRetyped] = React.useState<string>('');
  const [error, setError] = React.useState<string>();
  const {theme} = useTheme();
  const {showModal} = useContext(AppContext);
  const [mode, setMode] = React.useState<'login' | 'signup'>('login');

  const loginPasswordTextInput = useRef<TextInput>(null);

  const onLogin = async () => {
    try {
      console.log(email);
      await auth?.signInWithEmailAndPassword(
          email,
          password,
      );
      close();
    } catch (e) {
      setError((e as Error).message.split(' ').slice(1).join(' '));
    }
  };

  return <View style={{
    padding: 16,
    backgroundColor:
    theme.colors.white,
    flex: 1}}
  >
    {mode === 'login' ? <View style={{flex: 1}}>
      {error && <Text style={{color: theme.colors.error}}>Error: {error}</Text>}
      <TextInput
        testID='login_email'
        value={email}
        placeholder="email@example.com"
        autoCapitalize='none'
        onChangeText={(text) => setEmail(text)}
        style={{
          marginTop: 16,
          borderWidth: 1,
          padding: 12,
          borderColor: theme.colors?.border,
          color: theme.colors?.black,
          backgroundColor: theme.colors.white,
          marginBottom: 12,
        }}
        underlineColorAndroid="transparent"
        returnKeyType="next"
        onSubmitEditing={() => {
          loginPasswordTextInput.current?.focus();
        }}
        blurOnSubmit={false} />
      <TextInput
        testID='login_password'
        secureTextEntry={true}
        value={password}
        placeholder="password"
        onChangeText={(text) => setPassword(text)}
        style={{
          borderWidth: 1,
          padding: 12,
          borderColor: theme.colors?.border,
          marginBottom: 16,
          color: theme.colors?.black,
        }}
        underlineColorAndroid="transparent"
        ref={loginPasswordTextInput}
        onSubmitEditing={onLogin} />
      <View style={{flexDirection: 'row', alignSelf: 'center'}}>
        <Button
          testID="btn_login_submit"
          title="Log In" type="clear" onPress={onLogin} />
      </View>
    </View> : <View style={{flex: 1}}>
      <Dialog.Title
        titleStyle={{color: theme.colors.black}}
        title="Create New Account" />
      {error && <Text style={{color: theme.colors.error}}>Error: {error}</Text>}
      <KeyboardAvoidingView behavior="padding">
        <TextInput
          testID='new_account_email'
          secureTextEntry={false}
          value={email}
          keyboardType='email-address'
          placeholder="email@example.com"
          autoCapitalize='none'
          onChangeText={(text) => setEmail(text)}
          style={{
            marginTop: 16,
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            color: theme.colors?.black,
            marginBottom: 12,
          }}
          underlineColorAndroid="transparent" />
        <TextInput
          testID='new_account_password'
          secureTextEntry={true}
          value={password}
          placeholder="password"
          onChangeText={(text) => setPassword(text)}
          style={{
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            color: theme.colors?.black,
            marginBottom: 12,
          }}
          underlineColorAndroid="transparent" />
        <TextInput
          testID='new_account_password_retype'
          secureTextEntry={true}
          value={passwordRetyped}
          placeholder="password"
          onChangeText={(text) => setPasswordRetyped(text)}
          style={{
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            marginBottom: 16,
            color: theme.colors?.black,
          }}
          underlineColorAndroid="transparent" />
      </KeyboardAvoidingView>
      <View style={{flexDirection: 'row', alignSelf: 'center'}}>
        <Button
          testID="btn_create_account_submit"
          title="Create" type="clear"
          onPress={async () => {
            try {
              if (password !== passwordRetyped) {
                throw Error(' Passwords do not match');
              }
              const user = await auth?.createUserWithEmailAndPassword(
                  email,
                  password,
              );
              user?.user.sendEmailVerification();
              dialog(
                  'Verification Email Sent',
                  'Please check your email to verify your account.');
              close();
            } catch (e) {
              setError((e as Error).message.split(' ').slice(1).join(' '));
            }
          }}
        />
      </View>

    </View>}
    {mode === 'login' &&
    <View style={{flexDirection: 'row', alignSelf: 'center'}}><Button
      title="Forgot Password?"
      type="clear"
      onPress={() => {
        showModal((close) => <Prompt
          title='Forgot Password'
          subTitle='Please enter your registered email to recover your account.'
          submitTitle='Reset Password'
          initialValue={email}
          onCancel={close}
          onSubmit={async (email) => {
            try {
              await auth?.sendPasswordResetEmail(email);
              dialog('Please check your email for instructions' +
            ' to reset password.');
              close();
            } catch (e) {
              dialog((e as Error).message.split(' ').slice(1).join(' '));
            }
          }}
        />, {
        });
      }} /></View>}
    <View style={{flexDirection: 'row', alignSelf: 'center'}}>
      <Button
        testID='btn_create_account'
        title={mode === 'login' ? 'Create New Account' : 'Login'}
        type="clear" onPress={() => {
          setMode(mode === 'login' ? 'signup' : 'login');
          setError(undefined);
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }} />
    </View>
  </View>;
};
