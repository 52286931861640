import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {AppContext} from '../../common/context';
import {
  ArxivPaper,
  CrossRefPaper,
  SemanticScholarPaper,
  Sources,
} from '../../common/sources';
import {SourceKey, SourcePaper} from '../../common/sources/base';
import {IconRefresh} from '../../platform/icons';
import {
  ItemKind,
  PreferenceItemProps,
} from '../Preferences';
import {Text, useTheme} from '@rneui/themed';
import {FlatList} from 'react-native-gesture-handler';
import {Button} from '@rneui/base';
import ActivityIndicator from '../ActivityIndicator';
import {OpenReviewPaper} from '../../common/sources/openReview';
import {useRecoilValue} from 'recoil';
import {
  currentPaperSourcesState, currentPaperState,
} from '../../recoil/atoms/papers';

const getItem = (
    p: SourcePaper,
    field: string,
    value?: string,
): PreferenceItemProps => ({
  id: field,
  title: field,
  kind: ItemKind.Text,
  value: value || ((p as Record<string, unknown>)[field] as string),
  inline: true,
  numberOfLines: 5,
});

const _SourceBase = (
    sourceKey: SourceKey,
    getItemsFn: (p: SourcePaper) => PreferenceItemProps[]) => {
  const {
    actions: {fetchPaper},
  } = useContext(AppContext);
  const paper = useRecoilValue(currentPaperState);
  const source = Sources.find((src) => src.key === sourceKey);
  const [loading, setLoading] = useState<boolean>(false);
  const {theme} = useTheme();
  const paperSources = useRecoilValue(currentPaperSourcesState);

  useEffect(() => {
    if (!paper?.id) return;
  }, []);

  return (
        paper ? (<View style={{flex: 1}}>
          <View style={{
            flexDirection: 'row',
            padding: 16,
            alignItems: 'center',
          }}>
            <View style={{flex: 1}}>
              <Text>List of fields from {source?.name}</Text>
              <Text>Website: {source?.url}</Text>
            </View>
            <Button
              type="clear"
              icon={loading ? <ActivityIndicator /> : <IconRefresh />}
              titleStyle={{color: theme.colors.black}}
              style={{height: 32}}
              onPress={async () => {
                setLoading(true);
                await fetchPaper(paper, [sourceKey], true);
                setLoading(false);
              }}
              disabled={loading}
            />
          </View>
          <FlatList
            testID={`list_metadata_fields`}
            style={{flex: 1}}
            bounces={false}
            data={[
              ...(paperSources[sourceKey] && !paperSources[sourceKey].error ?
              getItemsFn(paperSources[sourceKey]).sort((a, b) => (
                (a.title || '') as string)
                  .localeCompare((b.title || '') as string)) :
              paperSources[sourceKey]?.error ?
              [getItem(paperSources[sourceKey], 'error')] :
              []),
            ]}
            renderItem={({item, index}) => <View
              style={{
                flexDirection: 'row',
                borderColor: theme.colors.border,
                borderBottomWidth: 1,
                borderTopWidth: index === 0 ? 1 : 0,
              }}
            >
              <Text
                style={{
                  padding: 8,
                  flex: 1,
                  maxWidth: 120,
                  backgroundColor: theme.colors.backgroundHighlight}}>
                {item.title}
              </Text>
              <Text
                testID={'field_' + item.id}
                numberOfLines={10}
                style={{
                  flex: 2,
                  padding: 8,
                }}>{item.value}</Text>
            </View>}
          />
        </View>) : <></>
  );
};

export const ArXiv = () =>
  _SourceBase('arxiv', (paper: SourcePaper) => {
    const p = paper as ArxivPaper;
    return [
      getItem(p, 'id'),
      getItem(p, 'title'),
      getItem(p, 'authors', p.authors.join(', ')),
      getItem(p, 'abstract'),
      getItem(p, 'categories', p.categories.join(', ')),
      getItem(p, 'url'),
      getItem(p, 'pdfUrl'),
      getItem(p, 'updated', p.updated.toString()),
      getItem(p, 'published', p.published.toString()),
      getItem(p, 'comment'),
    ];
  });

export const SemanticScholar = () =>
  _SourceBase(
      'semanticScholar',
      (paper: SourcePaper) => {
        const p = paper as SemanticScholarPaper;
        return [
          getItem(p, 'response'),
          getItem(p, 'paperId'),
          getItem(p, 'title'),
          getItem(p, 'abstract'),
          getItem(
              p,
              'authors',
              p.authors &&
          p.authors.map((a) => `${a.name} (${a.authorId})`).join(', '),
          ),
          getItem(p, 'url'),
          getItem(
              p,
              'externalIds',
              p.externalIds &&
          Object.entries(p.externalIds)
              .map(([key, val]) => `${key}: ${val}`)
              .join(', '),
          ),
          getItem(p, 'fieldsOfStudy'),
          getItem(p, 'citationCount', p.citationCount.toLocaleString()),
          getItem(p, 'referenceCount', p.referenceCount.toLocaleString()),
          getItem(p, 'citationVelocity', p.citationVelocity?.toLocaleString()),
          getItem(p, 'isOpenAccess'),
        ];
      },
  );

export const CrossRef = () =>
  _SourceBase('crossRef', (paper: SourcePaper) => {
    const p = paper as CrossRefPaper;
    return [
      getItem(p, 'title'),
      getItem(
          p,
          'authors',
          p.authors.map((a) => `${a.given} ${a.family}`).join(', '),
      ),
      getItem(p, 'url'),
      getItem(p, 'citationCount'),
      getItem(p, 'containerTitle', p.containerTitle.join(', ')),
      getItem(p, 'created'),
      getItem(p, 'deposited'),
      getItem(p, 'indexed'),
      getItem(p, 'publisher'),
    ];
  });

export const PaperShelf = () =>
  _SourceBase('paperShelf', (paper: SourcePaper) => {
    return [
      getItem(paper, 'title'),
      getItem(paper, 'alias'),
    ];
  });

export const OpenReview = () =>
  _SourceBase('openReview', (paper: SourcePaper) => {
    const p = paper as OpenReviewPaper;
    return [
      getItem(paper, 'id'),
      getItem(paper, 'original'),
      getItem(paper, 'title', p.content.title),
      getItem(paper, 'abstract', p.content.abstract),
      getItem(paper, 'tl;dr', p.content['TL;DR']),
      getItem(paper, 'code', p.content.code),
      getItem(paper, 'bibtex', p.content._bibtex),
      getItem(paper, 'replyto'),
      getItem(paper, 'invitation'),
      getItem(paper, 'keywords', p.content.keywords?.join(', ')),
      getItem(paper, 'pdf', p.content.pdf),
      getItem(paper, 'venue', p.content.venue),
      getItem(paper, 'venueid', p.content.venueid),
    ];
  });
