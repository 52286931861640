import React, {useEffect} from 'react';
import {useTheme, useThemeMode} from '@rneui/themed';
import {View, useColorScheme, SafeAreaView} from 'react-native';
import PaperList from './components/PaperList';
import PaperInfo from './components/PaperInfo';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import CollectionDrawer from './components/CollectionDrawer';
import Settings from './screens/Settings';
import {
  createDrawerNavigator,
} from '@react-navigation/drawer';
import PaperViewScreen from './screens/PaperView';
import HTMLPaperViewScreen from './screens/HTMLPaperView';
import PaperInfoHeader from './components/PaperInfoHeader';
import {NavigatorScreenParams, useNavigation} from '@react-navigation/native';
import {SettingsStackParamList} from './screens/Settings/Navigator';
import {ScreenContainer} from './components/ScreenContainer';
import PublicCollections from './screens/PublicCollections';
import {useRecoilValue} from 'recoil';
import {currentPaperState} from './recoil/atoms/papers';
import {settingsState} from './recoil/atoms/settings';
import {
  layoutState,
} from './recoil/selectors';
import {TouchableHighlight} from 'react-native-gesture-handler';
import ThreeColumnScreen from './screens/ThreeColumnScreen';
import {IconMore} from './platform/icons';
import PdfHeaderMenu from './components/PdfHeaderMenu';

const Drawer = createDrawerNavigator();

export type MainStackParamList = {
  Home: undefined;
  PaperList: undefined;
  PaperView: undefined;
  HTMLPaperView: undefined;
  PaperInfo: undefined;
  Preferences: NavigatorScreenParams<SettingsStackParamList>;
  PublicCollectionDetails: { key: string };
  PublicCollections: undefined;
};

const Stack = createStackNavigator<MainStackParamList>();

export const HeaderButton = ({
  icon,
}: {
  icon: React.ReactNode;
}) => <TouchableHighlight style={{marginHorizontal: 8, borderWidth: 1}}>
  {icon}
</TouchableHighlight>;

export const Main = (): JSX.Element => {
  const settings = useRecoilValue(settingsState);
  const currentPaper = useRecoilValue(currentPaperState);
  const {setMode} = useThemeMode();
  const colorScheme = useColorScheme();
  const {theme} = useTheme();
  const layout = useRecoilValue(layoutState);

  useEffect(() => {
    if (settings.theme === 'default') {
      setMode(colorScheme === 'dark' ? 'dark' : 'light');
    } else setMode(settings.theme === 'dark' ? 'dark' : 'light');
  }, [settings.theme, colorScheme]);

  return (
    <View style={{flex: 1}}>
      <Stack.Navigator
        initialRouteName="PaperList"
        screenOptions={{
          headerStyle: {
            backgroundColor: theme.colors?.header,
            borderBottomWidth: 1,
          },
          headerTitleStyle: {color: theme.colors?.black},
          headerBackTestID: 'btn_header_back',
          cardStyle: {flex: 1},
        }}
      >
        <Stack.Screen
          key="Home"
          name="Home"
          component={MainNavigation}
          options={{headerShown: false}}
        />
        <Stack.Screen
          key="PaperList"
          name="PaperList"
          component={
            layout === 'one-column' ? PaperListScreen : (
              layout === 'two-column' ?
              PaperSplitViewScreen : ThreeColumnScreen
            )
          }
          options={{headerShown: false}}
        />
        <Stack.Screen
          key="PaperView"
          name="PaperView"
          component={PaperViewScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerRight: () => (<PdfHeaderMenu>
              <View style={{width: 48}}>
                <IconMore />
              </View>
            </PdfHeaderMenu>),
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
        />
        <Stack.Screen
          key="HTMLPaperView"
          name="HTMLPaperView"
          component={HTMLPaperViewScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
        />
        <Stack.Screen
          key="PaperInfo"
          name="PaperInfo"
          component={PaperInfoScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
        />
        <Stack.Screen
          key="Preferences"
          name="Preferences"
          component={SettingsScreen}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          key="PublicCollections"
          name="PublicCollections"
          component={PublicCollections}
          options={{
            headerTitle: 'Public Collections',
            headerBackTitle: '',
          }}
        />
      </Stack.Navigator>
    </View>
  );
};

const MainNavigation = (): JSX.Element => {
  const {theme} = useTheme();
  return (
    <Drawer.Navigator
      drawerContent={(props) =>
        <CollectionDrawer {...props} />
      }
      screenOptions={{
        headerStyle: {backgroundColor: theme.colors?.white},
        headerTitleStyle: {color: theme.colors?.black},
        sceneContainerStyle: {backgroundColor: theme.colors?.white},
      }}
      useLegacyImplementation={true}
    >
      <Drawer.Screen
        name="Main"
        component={PaperListScreen}
        options={{
          headerShown: false,
        }}
      />
    </Drawer.Navigator>
  );
};

const PaperListScreen = () => {
  const {theme} = useTheme();
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();

  return (
    <ScreenContainer>
      <View
        style={{
          backgroundColor: theme.colors?.white,
        }}
      >
        <PaperList
          onPaperSelected={() => {
            navigation.navigate('PaperInfo');
          }}
          highlightCurrentPaper={false}
        />
      </View>
    </ScreenContainer>
  );
};

/**
 * Two-screen split view: Paper List and Paper Info
 * @returns Screen
 */
const PaperSplitViewScreen = () => {
  const {theme} = useTheme();

  const currentPaper = useRecoilValue(currentPaperState);
  const layout = useRecoilValue(layoutState);

  return (
    <View style={{backgroundColor: theme.colors?.background, flex: 1}}>
      <View
        style={{
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <SafeAreaView
          style={{
            minWidth: 300,
            maxWidth: 400,
            width: '40%',
            backgroundColor: theme.colors?.white,
            borderRightWidth: 1,
            borderRightColor: theme.colors.border,
          }}
        >
          <PaperList
            onLongPress={() => {
              // Do nothing
            }}
            highlightCurrentPaper={true}
          />
        </SafeAreaView>


        {currentPaper ? (
          <ScreenContainer>
            {currentPaper && layout !== 'one-column' &&
              <View style={{
                height: 48,
                paddingVertical: 8,
                paddingHorizontal: 16,
                borderBottomColor: theme.colors.border,
                borderBottomWidth: 1}}>
                <PaperInfoHeader paper={currentPaper} />
              </View>}
            <PaperInfo />
          </ScreenContainer>
          ) : (
            <View></View>
            // <Home showGoBack={false} />
          )}
      </View>
    </View>
  );
};

// const HomeScreen = () => {
//   const {theme} = useTheme();
//   return (
//     <SafeAreaView
//       style={{height: '100%', backgroundColor: theme.colors?.background}}
//     >
//       <Home showGoBack={true} />
//     </SafeAreaView>
//   );
// };

const PaperInfoScreen = () => {
  return <PaperInfo />;
};

const SettingsScreen = () => <ScreenContainer>
  <Settings />
</ScreenContainer>;
