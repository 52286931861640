import React, {useEffect} from 'react';
import Paper, {mergePaper} from './paper';
import Collection, {
  addPaperToCollection, isPaperInCollection, createNewCollection,
  removePaperFromCollection,
  mergeCollection,
} from './collection';
import {defaultSettings, defaultAppData, SortType} from './store';
import {toastError} from '../platform/toast';
import {AppContext, AppContextType} from './context';
import {
  checkLocalStorageInitalized,
  localStorageGetKeys,
  localStorageRemoveItem,
  localStorageSetItem,
} from '../platform/localStorage';
import Tag, {createNewTag} from './tag';
import {assign} from 'lodash';
import _ from 'lodash';
import {analytics, auth} from '../platform/firebase';
import {ModalOptionsType} from '../components/Modal';
import {useColorScheme} from 'react-native';
import * as Device from 'expo-device';
import {SourceKey, SourcePaper} from './sources/base';
import {isWeb} from '../platform';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {
  allPapersState,
  currentPaperSourcesState,
  currentPaperState,
  settingsState,
  allCollectionsState,
  currentCollectionState,
  allTagsState,
  isAdminState,
  isFetchingCurrentPaperState,
  deviceTypeState,
  sortByState,
  isReadyState,
  isModalVisibleState,
  modalContentState,
  modalOptionsState,
} from '../recoil/atoms';
import Api from '../common/api';
import ReactNativeBlobUtil, {
  ReactNativeBlobUtilConfig,
} from 'react-native-blob-util';
import Share from 'react-native-share';

const ContextProvider = ({
  render,
}: {
  render: (
    darkMode: boolean,
  ) => JSX.Element;
}): JSX.Element => {
  const [allPapers, setAllPapers] = useRecoilState(allPapersState);
  const [allCollections, setAllCollections] =
    useRecoilState(allCollectionsState);
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const [currentPaper, setCurrentPaper] = useRecoilState(currentPaperState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const colorScheme = useColorScheme();
  const [settings, setSettings] = useRecoilState(settingsState);

  const useDark = () =>
    settings?.theme === 'dark' ||
    (settings?.theme === 'default' && colorScheme === 'dark');

  const setIsReady = useSetRecoilState(isReadyState);
  const setDeviceType = useSetRecoilState(deviceTypeState);
  const setIsAdmin = useSetRecoilState(isAdminState);
  const setSortBy = useSetRecoilState(sortByState);

  // Modal
  const setModalContent = useSetRecoilState(modalContentState);
  const setIsModalVisible = useSetRecoilState(isModalVisibleState);
  const setModalOptions = useSetRecoilState(modalOptionsState);

  /**
   * Subscribe to real-time changes in the server
   * @returns unsubscribe function
   */
  const onServerDataUpdated = () => {
    console.log('subscribing to server data updates');
    const unsubscribe = [
      Api.Paper.onPaperDeleted(async (_pid) => {
        // TODO: this can be optimized by changing only the affected item.
        console.log('Paper deleted', _pid);
        await loadAppDataFromLocalStorage();
      }),
      Api.Paper.onPaperChanged(async (_pid) => {
        console.log('Paper changed', _pid);
        await loadAppDataFromLocalStorage();
      }),
    ];
    return () => {
      for (const fn of unsubscribe) fn && fn();
    };
  };

  useEffect(() => {
    if (!currentCollection) return;
    setAllCollections(allCollections.map(
        (c) => c.key === currentCollection.key ? currentCollection : c));
  }, [currentCollection]);

  const showModal = (
      content: (close: () => void) => JSX.Element,
      options?: ModalOptionsType,
  ) => {
    setModalContent(content(() => setIsModalVisible(false)));
    setModalOptions(options);
    setIsModalVisible(true);
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  useEffect(() => {
    const unsubscribe = auth?.onAuthStateChanged(function(u) {
      if (!u) return;
      Api.Profile.isAdmin().then((val) => setIsAdmin(val));
      onServerDataUpdated();
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    // Load setting
    Device.getDeviceTypeAsync().then((type) => {
      setDeviceType(type);
    });
  }, []);

  useEffect(() => {
    loadAppData().then(() => setIsReady(true));
  }, []);

  /**
   * Load data from local storage. Data includes papers, collections, and tags.
   */
  const loadAppDataFromLocalStorage = async () => {
    const keys = await localStorageGetKeys();
    const papers = keys
        .filter((key) => key.startsWith('paper:'))
        .map((key) => Api.Paper.load(key.split(':')[1]));
    console.log((await Promise.all(papers)).length);
    setAllPapers(
        Object.fromEntries(
            (await Promise.all(papers))
                .filter((p) => p && p.inLibrary)
                .map((p) => [p?.id, p]) as [string, Paper][],
        ),
    );

    const collections = keys
        .filter((key) => key.startsWith('collection:'))
        .map((key) => Api.Collection.local.get(
            key.split(':')[1]) as Promise<Collection>,
        );
    setAllCollections(await Promise.all(collections));

    const tags = await Promise.all(
        keys
            .filter((key) => key.startsWith('tag:'))
            .map((key) => Api.Tag.load(key.slice(4)) as Promise<Tag>),
    );
    if (tags.length === 0) {
      await actions.resetTags();
    } else {
      setAllTags(Object.fromEntries(tags.map((t) => [t.key, t])));
    }
  };

  /**
   * Load data from local storage and server.
   */
  const loadAppData = async (): Promise<void> => {
    if (await checkLocalStorageInitalized()) {
      await loadAppDataFromLocalStorage();
      setIsReady(true);

      if (Api.isLoggedIn()) {
        await Api.Paper.server.sync();
        await Api.Collection.sync();
        // await Api.Tag.syncFromServer();
        // await Api.Settings.syncFromServer();
        await loadAppDataFromLocalStorage();
      }
    } else {
      // initialize data
      const tps = await Api.Tag.getDefaultTags();
      setAllTags(
          Object.fromEntries(
              Object.entries(tps)
                  .map(([key, tp]) => [key, createNewTag({...tp, key})]),
          ),
      );
      setAllPapers({});
      await localStorageSetItem('initialized', 'done');
      setIsReady(true);
    }
  };

  const setPaperSources = useSetRecoilState(currentPaperSourcesState);
  const setIsFetching = useSetRecoilState(isFetchingCurrentPaperState);

  const onCurrentPaperChanged = async () => {
    if (!currentPaper?.id) return;
    if (allPapers[currentPaper.id]) {
      // update allPapers if the paper was modified
      if (currentPaper.dateModified !==
        allPapers[currentPaper.id].dateModified) {
        setAllPapers({
          ...allPapers,
          [currentPaper.id]: currentPaper,
        });
      }
    }

    setIsFetching(true);
    const paper = await actions.fetchPaper(currentPaper);
    const sources = await Api.Paper.local.loadSources(paper.id);

    if (!currentPaper.inLibrary &&
        Object.keys(currentPaper.dateFetched).length === 0) {
      // refresh paper state when it's not in library
      setCurrentPaper(paper);
    }

    setPaperSources({
      ...Object.fromEntries(
          Object.entries(paper.sources).filter(([_, v]) => v)),
      ...Object.fromEntries(
          Object.entries(sources).filter(([_, v]) => v)),
    } as Record<SourceKey, SourcePaper>);
    setIsFetching(false);
  };

  useEffect(() => {
    onCurrentPaperChanged();
  }, [currentPaper]);

  useEffect(() => {
    if (!currentPaper?.id) return;
    if (currentCollection &&
      !isPaperInCollection(currentPaper?.id, currentCollection)) {
      setCurrentPaper(null);
    }
  }, [currentCollection]);

  const onPaperOrderChanged = (sortType?: SortType) => {
    if (sortType && sortType !== settings.paperList.sortBy) return;
    console.log('order changed', sortType);
    // setPaperListItems(sort(paperListItems, settings.paperList.sortBy));
  };

  const fetchPaper = async (
      paper: Paper,
      sources?: SourceKey[],
      forceRefresh?: boolean,
      updateProgressFn?: (paper: Paper, msg: string) => void,
  ) => {
    try {
      const oldPaper = _.cloneDeep(paper);
      const updatedPaper = await Api.Paper.fetch(
          paper,
          sources || settings.fetchPaperSources,
          forceRefresh,
          updateProgressFn,
      );

      // If the paper is changed
      if (updatedPaper.dateModified !== oldPaper.dateModified) {
        console.log('Paper metadata updated', updatedPaper.id);
        if (updatedPaper.inLibrary && oldPaper.id !== updatedPaper.id) {
          console.log('Paper id changed', oldPaper.id, updatedPaper.id);
          await Api.Paper.remove(oldPaper.id);
          setAllPapers({
            ...Object.fromEntries(Object.entries(allPapers)
                .filter(([key, _]) => key !== oldPaper.id)),
            [updatedPaper.id]: updatedPaper,
          });

          const updateCollection = (c: Collection) => ({
            ...c,
            paperIds: [
              ...c.paperIds.filter((p) => p !== oldPaper.id),
              updatedPaper.id,
            ],
          } as Collection);

          // If paper belongs to a collection, update the collection
          const newAllCollections = await Promise.all(
              allCollections.map((c) => {
                if (isPaperInCollection(oldPaper.id, c)) {
                  return saveCollectionAndSync(updateCollection(c));
                } else return c;
              }),
          );
          setAllCollections(newAllCollections);
          // Update current collection & paper if needed
          if (currentCollection &&
            isPaperInCollection(oldPaper.id, currentCollection)) {
            setCurrentCollection(updateCollection(currentCollection));
          }
          if (currentPaper?.id === oldPaper.id) {
            setCurrentPaper(updatedPaper);
          }
          await Api.Paper.save(updatedPaper);
          await Api.Paper.server.save(updatedPaper);
        } else {
          await syncPaper(updatedPaper);
        }

        // Sort the list if necessary
        onPaperOrderChanged(SortType.ByDateModified);
        if (oldPaper.title !== updatedPaper.title) {
          onPaperOrderChanged(SortType.ByTitle);
        }
        if (oldPaper.numCitations !== updatedPaper.numCitations) {
          onPaperOrderChanged(SortType.ByCitation);
        }
        if (oldPaper.year !== updatedPaper.year) {
          onPaperOrderChanged(SortType.ByYear);
        }
      }

      return updatedPaper;

      // setAllPapers(
      //   Object.fromEntries(
      //     Object.entries(allPapers).filter(([id, p]) => id === p.id)
      //   )
      // );
    } catch (e) {
      toastError(`Error fetching paper "${paper.title}"`, e);
      return paper;
    }
  };

  /**
   * Sync a local paper with a remote paper based on dateModified. The newer
   * paper overrides the older paper to create an updated paper. App state is
   * updated after finishing (currentPaper, allPapers).
   * @param paper - a paper
   */
  async function syncPaper(paper: Paper): Promise<void> {
    if (!paper.id || !paper.inLibrary) return;

    try {
      const remotePaper = await Api.Paper.server.get(paper.id);
      console.log('remotePaper', remotePaper);
      if (!paper.dateModified) throw new Error('Paper has no dateModified');
      if (remotePaper?.dateModified === paper.dateModified) {
        console.log('No sync required');
        return;
      }

      const isLocalPaperNewer = !remotePaper?.dateModified ||
        remotePaper.dateModified < paper.dateModified;
      const updatedPaper = isLocalPaperNewer ?
        mergePaper(remotePaper, paper) : mergePaper(paper, remotePaper);

      console.log('updatedPaper', updatedPaper);
      if (paper.id === currentPaper?.id) setCurrentPaper(updatedPaper);
      else setAllPapers({...allPapers, [paper.id]: updatedPaper});
      await Api.Paper.save(updatedPaper, updatedPaper.dateModified);
      await Api.Paper.server.save(updatedPaper);
    } catch (_e) {
      if (paper.id === currentPaper?.id) setCurrentPaper(paper);
      else setAllPapers({...allPapers, [paper.id]: paper});
    }
  }

  /**
   * Sync a local collection with a remote collection based on dateModified.
   * @param collection - a collection
   */
  async function syncCollection(collection: Collection): Promise<void> {
    try {
      const remoteCollection = await Api.Collection.server.get(collection.key);

      if (remoteCollection?.dateModified === collection.dateModified) {
        console.log('No sync required');
        return;
      }

      const isLocalCollectionNewer = !remoteCollection?.dateModified ||
        remoteCollection.dateModified < collection.dateModified;
      const updatedCollection = isLocalCollectionNewer ?
        mergeCollection(remoteCollection, collection) :
        mergeCollection(collection, remoteCollection);

      if (collection.key === currentCollection?.key) {
        setCurrentCollection(updatedCollection);
      } else {
        setAllCollections(
            allCollections.map(
                (c) => c.key !== collection.key ? c : updatedCollection));
      }
      await Api.Collection.local.save(updatedCollection, true);
      await Api.Collection.server.save(updatedCollection);
    } catch (_e) {
      // do nothing
    }
  }

  /**
   * Save paper first, then sync
   * @param p - a paper
   * @returns - updated paper
   */
  async function savePaperAndSync(p: Paper) {
    const _p = await Api.Paper.save(p);
    await syncPaper(_p);
    return _p;
  }

  /**
   * Save collection first, then sync
   * @param collection - a collection
   * @returns - updated collection
   */
  async function saveCollectionAndSync(collection: Collection) {
    const c = await Api.Collection.local.save(collection);
    await syncCollection(c);
    return c;
  }

  /**
   * Change a paper without saving
   * @param paper - a paper
   */
  function changePaper(paper: Paper) {
    if (paper.id === currentPaper?.id) {
      setCurrentPaper(paper);
    } else {
      setAllPapers({...allPapers, [paper.id]: paper});
    }
  }

  /**
   * Download a paper to internal storage (iOS/android only)
   * @param p - a paper
   */
  async function download(p: Paper) {
    const _download = async (paper: Paper) => {
      if (!paper.pdfUrl) return paper;
      const configOptions: ReactNativeBlobUtilConfig = {
        fileCache: true,
        path: ReactNativeBlobUtil.fs.dirs.DocumentDir +
              `/PaperShelf/${paper.title}.pdf`,
        timeout: 10000,
        followRedirect: false,
      };
      try {
        const res = await ReactNativeBlobUtil.config(configOptions)
            .fetch('GET', paper.pdfUrl);
        const filePath = res.path();
        return await savePaperAndSync({
          ...paper,
          pdfPath: filePath,
          downloading: false,
        });
      } catch (e) {
        changePaper({...paper, downloading: false});
        toastError(`Error downloading paper: ${(e as Error).message}`, e);
      }
    };
    changePaper({...p, downloading: true});
    await _download(p);
  }

  /**
   * Share paper's pdf
   * @param filePath - path to the file
   */
  async function sharePdf(filePath?: string) {
    const paper = currentPaper;
    if (!paper) return;
    let fp: string = filePath || '';
    let downloadedFilePath: string | undefined = undefined;
    if (!fp) {
      // Download pdf if not already downloaded
      if (!paper.pdfPath) {
        if (!paper.pdfUrl) return;
        const res = await ReactNativeBlobUtil.config({
          fileCache: true,
        }).fetch('GET', paper.pdfUrl);
        downloadedFilePath = res.path();
        fp = downloadedFilePath;
      } else {
        fp = paper.pdfPath;
      }
    }
    const options = {
      type: 'application/pdf',
      url: fp, // (Platform.OS === 'android' ? 'file://' + filePath)
    };
    await Share.open(options);
    // remove pdf from device's storage
    if (downloadedFilePath) {
      await ReactNativeBlobUtil.fs.unlink(downloadedFilePath);
    }
  }

  /**
   * Share paper's url
   */
  async function share() {
    const paper = currentPaper;
    try {
      if (!paper || !paper.pdfUrl) throw new Error('No URL found.');
      await Share.open({
        title: 'PaperShelf',
        message: 'Check out this paper: ' + paper.title,
        url: paper.pdfUrl,
      });
    } catch (e) {
      const error = e as Error;
      if (error.message === 'User did not share') {
        // do nothing
      } else {
        toastError(`Could not share the paper: ${error.message}`, e);
      }
    }
  }

  const actions: AppContextType['actions'] = {
    newCollection: async (title: string) => {
      const c = createNewCollection({name: title});
      setAllCollections([...allCollections, c]);
      saveCollectionAndSync(c);
      return c;
    },
    addPaperToLibrary: async (p: Paper) => {
      console.log('paper added to library', p.id);
      if (p.inLibrary) return;
      const updatedPaper = await actions.savePaperAndSync({
        ...p,
        inLibrary: true,
      });
      setAllPapers({...allPapers, [p.id]: updatedPaper});
      await actions.fetchPaper(updatedPaper);
      // save once more time to trigger child_changed event in other clients
      await analytics?.logEvent('add_paper_to_library');
    },
    addPaperToCollection: async (p: Paper, c: Collection) => {
      await actions.addPaperToLibrary(p);
      const newC = addPaperToCollection(p.id, c);
      await saveCollectionAndSync(newC);
      await savePaperAndSync(p);
    },
    removePaperFromCollection: async (p: Paper, c: Collection) => {
      const newC = removePaperFromCollection(p.id, c);
      setAllCollections(
          allCollections.map((_c) => _c.key === newC.key ? newC : _c));
      await saveCollectionAndSync(newC);
      await actions.savePaperAndSync(p);
    },
    removePaperFromLibrary: async (paper: Paper | Paper[]) => {
      setCurrentPaper(null);
      const deletedPapers = Array.isArray(paper) ? paper : [paper];
      const deletedIds = deletedPapers.map((p) => p.id);
      setAllPapers(
          Object.fromEntries(Object.entries(allPapers)
              .filter(([id]) => !deletedIds.includes(id)),
          ),
      );
      await Promise.all(
          deletedPapers.map(async (p) => {
            await Api.Paper.remove(p.id);
            setAllCollections(await Promise.all(
                allCollections.map(async (c) => {
                  if (isPaperInCollection(p.id, c)) {
                    const newC = removePaperFromCollection(p.id, c);
                    return Api.Collection.save(newC);
                  } else return c;
                }),
            ));
          }),
      );
    },
    fetchPaper,
    resetTags: async () => {
      try {
        const tps = await Api.Tag.getDefaultTags();
        const _allTags = assign(
            {},
            allTags,
            Object.fromEntries(
                Object.entries(tps).map(([key, tp]) => [
                  key,
                  createNewTag({...tp, key}),
                ]),
            ),
        );
        await Api.Tag.removeAll();
        setAllTags(_allTags);
        await Promise.all(Object.values(_allTags).map((t) => Api.Tag.save(t)));
        return _allTags;
      } catch (e) {
        toastError('Error loading default tags.', e);
        return allTags;
      }
    },
    clearTags: async () => {
      await Api.Tag.removeAll();
      setAllTags({});
    },
    clearLocalData: async () => {
      setAllPapers(defaultAppData.papers);
      setAllCollections(defaultAppData.collections);
      await actions.resetTags();
      setSettings(_.cloneDeep(defaultSettings));

      if (isWeb) location.reload();

      setCurrentPaper(null);
      setCurrentCollection(null);
      const keys = await localStorageGetKeys();
      await Promise.all(
          keys.map((key) => {
            if (!['settings'].includes(key)) return localStorageRemoveItem(key);
          }),
      );
    },
    deleteCollection: async (c: Collection) => {
      await Promise.all(
          Object.values(allPapers).map(async (p) => {
            if (isPaperInCollection(p.id, c)) {
              await savePaperAndSync(p);
              return p;
            }
          }),
      );
      await Api.Collection.local.remove(c);
      await Api.Collection.server.remove(c);
    },
    savePaperAndSync,
    saveCollectionAndSync,
    download,
    removePdf: async (paper: Paper) => {
      if (!paper.pdfPath) return paper;
      await ReactNativeBlobUtil.fs.unlink(paper.pdfPath);
      return actions.savePaperAndSync({
        ...paper,
        pdfPath: undefined,
      });
    },
    sharePdf,
    share,
  };

  useEffect(() => {
    Api.Settings.save(settings);
    setSortBy(settings.paperList.sortBy);
  }, [settings]);

  return settings ? (
    <AppContext.Provider
      value={
        {
          loadAppData,
          actions,
          showModal,
          closeModal: () => setIsModalVisible(false),
          useDark,
          onPaperOrderChanged,
        } as AppContextType
      }
    >
      {render(
          useDark(),
      )}
    </AppContext.Provider>
  ) : (
    <></>
  );
};

export default ContextProvider;
