import React, {useContext, useEffect, useState} from 'react';
import Paper, {searchPaper} from '../common/paper';
import {toastError} from '../platform/toast';
import {
  FlatList,
  LayoutAnimation,
  RefreshControl,
  View,
} from 'react-native';
import {Button, ButtonGroup, Text, useTheme} from '@rneui/themed';
import {
  IconAddCollection,
  IconClose,
  IconDelete,
  IconDownload,
  IconSort,
} from '../platform/icons';
import {AppContext, ListDisplayType} from '../common/context';
import {Toolbar, ToolbarItem} from './ToolbarItem';
import PaperListItem from './PaperListItem';
import Collection from '../common/collection';
import PaperListHeader from './PaperListHeader';
import ActivityIndicator from './ActivityIndicator';
import {Overlay} from '@rneui/base';
import {PreferenceItem} from './Preferences';
import {GeneralSortBy} from '../screens/Settings/General';
import {dialog} from '../platform/dialog';
import {
  DarkTheme, DefaultTheme, NavigationContainer, useNavigation,
} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {MainStackParamList} from '../Main';
import PublicCollections from '../screens/PublicCollections';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  allPapersState,
  currentPaperState,
  listDisplayTypeState,
  searchQueryState,
  settingsState,
  currentCollectionState,
  webSearchQueryState,
} from '../recoil/atoms';
import {
  paperListItemsState,
  useModalState,
  isPhoneState,
  isWebState,
} from '../recoil/selectors';

type PaperListProps = {
  // eslint-disable-next-line react/require-default-props
  onLongPress?: () => void;
  onPaperSelected?: (p: Paper) => void;
  highlightCurrentPaper: boolean;
};

export type PaperListHandle = {
  refresh: (reload: boolean) => void;
};

type ListTabProps = {
  searchResultTypeOptions: Record<string, ListDisplayType>;
};

const ListTab = ({
  searchResultTypeOptions,
}: ListTabProps) => {
  const {theme} = useTheme();
  const [listDisplayType, setListDisplayType] =
    useRecoilState(listDisplayTypeState);

  return (
    <View
      style={{
        backgroundColor: theme.colors?.white,
        borderRightWidth: 1,
        borderRightColor: theme.colors?.border,
        marginTop: 8,
        marginBottom: 8,
      }}
    >
      <ButtonGroup
        buttons={Object.keys(searchResultTypeOptions)}
        buttonStyle={{
          backgroundColor: theme.colors.white,
        }}
        textStyle={{
          fontSize: 12,
          color: theme.colors.black,
        }}
        selectedButtonStyle={{
          backgroundColor: theme.colors.backgroundHighlight,
        }}
        selectedTextStyle={{
          fontSize: 12,
          color: theme.colors.black,
          fontWeight: 'bold',
        }}
        selectedIndex={Object.values(searchResultTypeOptions).indexOf(
            listDisplayType,
        )}
        onPress={(selectedIndex) => {
          setListDisplayType(
              Object.values(searchResultTypeOptions)[selectedIndex],
          );
        }}
        containerStyle={{height: 32}}
      />
    </View>
  );
};

type EmptyListProps = {
  isLoading: boolean;
  isEmpty: boolean;
  isSearchMode: boolean;
  isCollectionSelected: boolean;
  isLibraryEmpty: boolean;
};

const EmptyList = ({
  isLoading,
  isEmpty,
  isSearchMode,
  isCollectionSelected,
  isLibraryEmpty,
}: EmptyListProps) => {
  const {theme} = useTheme();
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList>>();
  const {showModal, useDark} = useContext(AppContext);
  const useModal = useRecoilValue(useModalState);
  return (
    <View
      style={{
        justifyContent: 'center',
        flexGrow: 1,
        backgroundColor: theme.colors?.white,
      }}
    >
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        isEmpty &&
        (isSearchMode ? (
          <Text style={{padding: 16, textAlign: 'center'}}>
            No results found.
          </Text>
        ) : isCollectionSelected ? (
          <Text style={{padding: 16, textAlign: 'center'}}>
            This collection is empty.
          </Text>
        ) : (
          isLibraryEmpty && (
            <>
              <Text style={{margin: 16, textAlign: 'center', fontSize: 16}}>
                Your library is empty.
              </Text>
              <Button
                type="clear" title="Browse Public Collections"
                titleStyle={{fontSize: 16}}
                onPress={() => {
                  if (useModal) {
                    showModal((_close) => (
                      <NavigationContainer
                        independent={true}
                        theme={useDark() ? DarkTheme : DefaultTheme}>
                        <PublicCollections onChange={_close}/>
                      </NavigationContainer>), {
                      height: 600,
                      width: 600,
                      contentContainerStyle: {padding: 0},
                      title: 'Public Collections',
                    });
                  } else {
                    navigation.navigate('PublicCollections');
                  }
                }} />
            </>
          )
        ))
      )}
    </View>
  );
};

type SelectionToolbarProps = {
  currentPapers: string[];
  currentCollection: Collection | null;
  onClose: () => void;
};

const SelectionToolbar = ({
  currentPapers,
  currentCollection,
  onClose,
}: SelectionToolbarProps) => {
  const {actions} = useContext(AppContext);
  const allPapers = useRecoilValue(allPapersState);

  /**
   * remove all selected papers
   */
  const remove = async () => {
    dialog(
        'Remove',
        `Do you want to remove seletected ${currentPapers.length} paper(s)?`,
        [
          ...(currentCollection ?
          [
            {
              text: 'Remove from Collection',
              onPress: async () => {
                await Promise.all(
                    currentPapers.map((pid) =>
                      actions.removePaperFromCollection(
                          allPapers[pid],
                          currentCollection,
                      ),
                    ),
                );
                onClose();
              },
            },
          ] :
          []),
          {
            text: 'Remove from Library',

            onPress: async () => {
              await actions.removePaperFromLibrary(
                  currentPapers.map((pid) => allPapers[pid]),
              );
              onClose();
            },
          },
          {text: 'Cancel', style: 'cancel'},
        ],
    );
  };

  return (
    <Toolbar borderTop key="multipleSelecToolbar">
      <ToolbarItem
        icon={IconDownload}
        title={'Refresh'}
        onPress={() => {
          onClose();
          Promise.all(
              currentPapers
                  .map((pid) => allPapers[pid])
                  .map((p) => actions.fetchPaper(p)),
          );
        }}
      />
      <ToolbarItem icon={IconDelete} title={'Remove'} onPress={remove} />
      {false && (
        <ToolbarItem icon={IconAddCollection} title="Add to Collection" />
      )}
      <View style={{flex: 1}} />
      <ToolbarItem icon={IconClose} onPress={onClose} />
    </Toolbar>
  );
};

const PaperList = ({
  onLongPress,
  highlightCurrentPaper,
  onPaperSelected,
}: PaperListProps): JSX.Element => {
  const {theme} = useTheme();

  const paperListItems = useRecoilValue(paperListItemsState);
  const [settings, setSettings] = useRecoilState(settingsState);

  const {
    loadAppData,
    showModal,
  } = useContext(AppContext);
  const isPhone = useRecoilValue(isPhoneState);
  const isWeb = useRecoilValue(isWebState);

  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);

  const [isSelectionMode, setIsSelectionMode] = useState<boolean>(false);
  const [currentPapers, setCurrentPapers] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const [webSearchQuery, setWebSearchQuery] =
    useRecoilState(webSearchQueryState);
  const [_webSearchPage, setWebSearchPage] = useState<number>(0);
  const [webSearchPapers, setWebSearchPapers] = useState<Paper[]>([]);
  const [_webSearchCanLoadMore, setWebSearchCanLoadMore] =
    useState<boolean>(true);

  const [isLoadingOnlineResults, setIsLoadingOnlineResults] =
    useState<boolean>(false);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [isOverlayMenuVisible, setIsOverlayMenuVisible] =
    useState<boolean>(false);

  const [searchResultTypeOptions, setSearchResultTypeOptions] = useState<
    Record<string, ListDisplayType>
  >({});

  const allPapers = useRecoilValue(allPapersState);
  const [currentPaper, setCurrentPaper] = useRecoilState(currentPaperState);
  const currentCollection = useRecoilValue(currentCollectionState);
  const [listDisplayType, setListDisplayType] =
    useRecoilState(listDisplayTypeState);

  /**
   * Populates paper list with search results
   * @param query - search query
   * @param papers - current list of papers
   * @param page - page number
   * @param canLoadMore - if more results can be loaded
   */
  const onlineSearch = (
      query: string,
      papers: Paper[],
      page = 0,
      canLoadMore = true,
  ) => {
    if (query === '' || !canLoadMore) {
      setIsLoadingOnlineResults(false);
      return;
    }
    setIsLoadingOnlineResults(true);

    const resPapers: Paper[] = papers;
    const updateResults = (res: Paper[], source: string) => {
      for (const p of res) {
        const rp = resPapers.find((rp) => rp.id === p.id);
        if (!rp) {
          p.channels.push(source);
          resPapers.push(p);
        } else {
          rp.channels.push(source);
        }
      }
      setWebSearchPapers(resPapers.sort((a: Paper, b: Paper) =>
        -(a.numCitations || 0) + (b.numCitations || 0),
      ));
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      // for (const p of resPapers) actions.fetchPaper(p);
      if (resPapers.length === 0) setWebSearchCanLoadMore(false);
    };

    searchPaper(query, settings.searchPaperSources, updateResults, page)
        .then(() => {
          setIsLoadingOnlineResults(false);
          setWebSearchPage(page + 1);
        })
        .catch((e) => {
          toastError('Error loading papers: ' + e.message, e);
          setIsLoadingOnlineResults(false);
        });
  };

  const onSearchModeChanged = () => {
    if (isSearchMode) {
      if (currentCollection) {
        setSearchResultTypeOptions({
          'In Collection': ListDisplayType.CollectionSearch,
          'In Library': ListDisplayType.LibrarySearch,
          'Web Search': ListDisplayType.WebSearch,
        });
        if (listDisplayType === ListDisplayType.Regular) {
          setListDisplayType(ListDisplayType.CollectionSearch);
        }
      } else {
        setSearchResultTypeOptions({
          'In Library': ListDisplayType.LibrarySearch,
          'Web Search': ListDisplayType.WebSearch,
        });
        if (
          listDisplayType === ListDisplayType.Regular ||
          listDisplayType === ListDisplayType.CollectionSearch
        ) {
          setListDisplayType(ListDisplayType.LibrarySearch);
        }
      }
    } else {
      setListDisplayType(ListDisplayType.Regular);
    }
  };

  // Refresh search results if query changed
  useEffect(() => {
    setWebSearchPage(0);
    setWebSearchPapers([]);
    setWebSearchCanLoadMore(true);
    onlineSearch(webSearchQuery, []);
  }, [webSearchQuery]);

  useEffect(() => {
    if (listDisplayType == ListDisplayType.WebSearch) {
      setWebSearchQuery(searchQuery);
    }
  }, [listDisplayType]);

  useEffect(onSearchModeChanged, [isSearchMode]);

  useEffect(() => {
    if (webSearchQuery) setListDisplayType(ListDisplayType.WebSearch);
  }, [webSearchQuery]);

  // const getWebSearchLink = (kw: string) => (
  //   <Text
  //     style={{ marginTop: 16, textAlign: 'center', color: 'blue' }}
  //     onPress={() => {
  //       setIsSearchMode(true);
  //       setLiveSearchQuery(kw);
  //       setSearchQuery(kw);
  //       setListDisplayType(ListDisplayType.SearchOnline);
  //     }}
  //   >
  //     {'"'}
  //     {kw}
  //     {'"'}
  //   </Text>
  // );

  const renderPaperItem = (
      {item: paper}: { item: Paper },
      showChannels = false) => (
    <PaperListItem
      paper={paper}
      showChannels={showChannels}
      selected={
        highlightCurrentPaper &&
        (isSelectionMode ?
          currentPapers.includes(paper.id) :
          paper.id === currentPaper?.id)
      }
      onPaperSelected={() => {
        onPaperSelected && onPaperSelected(paper);
        if (isSelectionMode) {
          if (!currentPapers.includes(paper.id)) {
            setCurrentPapers([...currentPapers, paper.id]);
          } else {
            if (currentPapers.length === 1) setIsSelectionMode(false);
            setCurrentPapers(currentPapers.filter((p) => p !== paper.id));
          }
        } else {
          setCurrentPaper(paper);
        }
      }}
      onLongPress={() => {
        if (!isSelectionMode) {
          setIsSelectionMode(true);
          setCurrentPapers([paper.id]);
        }
        if (onLongPress) {
          // onLongPress();
          // setCurrentPaper(paper);
        }
      }}
      // showActionButtons={listDisplayType === ListDisplayType.WebSearch}
    />
  );

  const webSearchList =
    isLoadingOnlineResults || webSearchPapers.length === 0 ? (
      <EmptyList
        isLoading={isLoadingOnlineResults}
        isEmpty={webSearchPapers.length === 0}
        isSearchMode={isSearchMode}
        isCollectionSelected={currentCollection !== null}
        isLibraryEmpty={Object.keys(allPapers).length === 0}
      />
    ) : (
      <FlatList
        keyExtractor={(item) => item.id}
        data={webSearchPapers}
        // removeClippedSubviews={true}
        renderItem={(args) => renderPaperItem(args, true)}
        extraData={currentPaper}
        bounces={false}
      />
    );

  // Support fetching papers when they come into view
  // const viewabilityConfigCallbackPairs =
  //   useRef<ViewabilityConfigCallbackPairs>([{
  //     viewabilityConfig: {
  //       minimumViewTime: 150,
  //       itemVisiblePercentThreshold: 10,
  //     },
  //     onViewableItemsChanged:
  //       ({viewableItems}: {viewableItems: ViewToken[]}) => {
  //         viewableItems.forEach(({item}) => {
  //           if (!item.title) {
  //             fetchPaper(item as Paper, ['paperShelf']);
  //           }
  //         });
  //       },
  //   }]);

  const libraryList =
    paperListItems.length === 0 ? (
      <EmptyList
        isLoading={false}
        isEmpty={paperListItems.length === 0}
        isSearchMode={isSearchMode}
        isCollectionSelected={currentCollection !== null}
        isLibraryEmpty={Object.keys(allPapers).length === 0}
      />
    ) : (
      <FlatList
        keyExtractor={(item, index) => index.toString()}
        data={paperListItems}
        // removeClippedSubviews={true}
        renderItem={renderPaperItem}
        extraData={{
          currentPaper,
          currentCollection,
        }}
        refreshControl={<RefreshControl
          refreshing={refreshing}
          onRefresh={() => {
            setRefreshing(true);
            loadAppData()
                .catch((e) => {
                  toastError('Could not refresh. \nPlease try again later.', e);
                })
                .finally(() => setRefreshing(false));
          }}
          tintColor={theme.colors.black} />}
        // viewabilityConfigCallbackPairs={
        //   viewabilityConfigCallbackPairs.current
        // }
      />
    );

  return (
    <View style={{
      height: '100%',
      backgroundColor: theme.colors.white,
    }}>
      <PaperListHeader
        isLoading={isLoadingOnlineResults}
        isSearchMode={isSearchMode}
        onChangeText={(value: string) => {
          setSearchQuery(value);
        }}
        searchQuery={searchQuery}
        onEndEditing={() => {
          if (!searchQuery) {
            setIsSearchMode(false);
          }
        }}
        onSubmitEditing={() => {
          setWebSearchQuery(searchQuery);
        }}
        onSearch={() => {
          setWebSearchQuery(searchQuery);
        }}
        onClear={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          setWebSearchQuery('');
          setSearchQuery('');
          setIsSearchMode(false);
        }}
        onCollectionClick={() => {
          setCurrentPaper(null);
        }}
        onStartSearch={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          setIsSearchMode(true);
          if (paperListItems.length === 0) {
            setListDisplayType(ListDisplayType.WebSearch);
          }
        }}
        onOpenMenu={() => {
          setIsOverlayMenuVisible(true);
        }}
      />
      <Overlay
        isVisible={isOverlayMenuVisible}
        onBackdropPress={() => setIsOverlayMenuVisible(false)}
        overlayStyle={{
          width: 200,
          position: 'absolute',
          top: 64,
          left: 132,
          padding: 0,
        }}>
        <PreferenceItem
          id='test'
          title='Sort'
          icon={<IconSort />}
          onPress={() => {
            setIsOverlayMenuVisible(false);
            showModal(
                (close) => (
                  <GeneralSortBy onChange={close} />
                ),
                {
                  title: 'Sort By',
                  width: 300,
                  height: 300,
                },
            );
          }}
          separator={false}
        />
        {(!isPhone && !isWeb &&
        (settings.layout === 'two-column' || settings.layout === 'auto')) &&
          <PreferenceItem
            id='single_column'
            title='Single Layout'
            onPress={() => {
              setIsOverlayMenuVisible(false);
              setSettings({...settings, layout: 'one-column'});
              LayoutAnimation.configureNext(
                  LayoutAnimation.Presets.easeInEaseOut);
            }}
            separator={false}
          />}
        {!isPhone && !isWeb && settings.layout === 'one-column' &&
        <PreferenceItem
          id='double_column'
          title='Side by Side'
          onPress={() => {
            setIsOverlayMenuVisible(false);
            setSettings({...settings, layout: 'two-column'});
            LayoutAnimation.configureNext(
                LayoutAnimation.Presets.easeInEaseOut);
          }}
          separator={false}
        />}
      </Overlay>
      {isSearchMode && (
        <ListTab
          searchResultTypeOptions={searchResultTypeOptions}
        />
      )}

      <View
        style={{
          flex: 1,
        }}
      >
        {listDisplayType === ListDisplayType.WebSearch ?
          webSearchList :
          libraryList}

        {isSelectionMode && (
          <SelectionToolbar
            currentPapers={currentPapers}
            currentCollection={currentCollection}
            onClose={() => {
              setIsSelectionMode(false);
              setCurrentPapers([]);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default PaperList;
