import React, {useContext, useEffect} from 'react';
import {View} from 'react-native';
import {AppContext} from '../common/context';
import Paper from '../common/paper';
import PdfViewer from '../components/PdfViewer';
import {SortType} from '../common/store';
import {analytics} from '../platform/firebase';
import {Text} from '@rneui/themed';
import {useRecoilValue} from 'recoil';
import {currentPaperState} from '../recoil/atoms/papers';
import {ScreenContainer} from '../components/ScreenContainer';

type PaperViewPropsType = {
  paper: Paper;
};

const PaperView = ({paper}: PaperViewPropsType) => {
  const {
    actions: {savePaperAndSync, addPaperToLibrary},
    onPaperOrderChanged,
  } = useContext(AppContext);

  useEffect(() => {
    if ((paper.dateOpened || 0) < Date.now() - 5000) {
      console.log(paper.dateOpened, Date.now());
      const _paper = {
        ...paper,
        dateOpened: Date.now(),
      };
      savePaperAndSync(_paper);
    }
    analytics?.logEvent('read_paper_pdf');

    return () => {
      onPaperOrderChanged(SortType.ByDateOpened);
    };
  }, [paper.id]);

  return (
    <View testID="pdf_viewer_container" style={{flex: 1}}>
      {paper && (
        paper?.pdfUrl ? (
          <PdfViewer paper={paper} addToLibrary={addPaperToLibrary} />
        ) : (
          <Text style={{padding: 16, textAlign: 'center'}}>
            Could not retrieve the paper. Please try again later.
          </Text>
        )
      )}
    </View>
  );
};

export const PaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  return (
    <ScreenContainer edges={['bottom']}>
      {currentPaper && <PaperView paper={currentPaper} />}
    </ScreenContainer>
  );
};

export default PaperViewScreen;

