import {useTheme} from '@rneui/themed';
import React, {useContext} from 'react';
import {StatusBar} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {AppContext} from '../common/context';

export const ScreenContainer = ({
  children,
  testID,
  edges,
}: {
  testID?: string;
  children: React.ReactNode;
  edges?: ('top' | 'right' | 'bottom' | 'left')[];
}) => {
  const {theme} = useTheme();
  const {useDark} = useContext(AppContext);
  return <SafeAreaView
    testID={testID}
    style={{
      flex: 1,
      backgroundColor: theme.colors.white,
    }}
    edges={edges}
  >
    <StatusBar
      backgroundColor={theme.colors.white}
      barStyle={useDark() ? 'light-content' : 'dark-content'} />
    {children}
  </SafeAreaView>;
};
