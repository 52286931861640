import React from 'react';
import {AppContext} from '../common/context';
import {FullTheme, Text, useTheme} from '@rneui/themed';
import {
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import {uniqBy} from 'lodash';
import {
  IconCitation,
} from '../platform/icons';
import Paper from '../common/paper';
import {PaperTag} from './PaperTag';
import {Sources} from '../common/sources';
import {useRecoilValue} from 'recoil';
import {allTagsState, settingsState} from '../recoil/atoms';
import {Settings} from '../common/store';

export type PaperListItemProps = {
  settings?: Settings;
  paper: Paper;
  selected?: boolean;
  onPaperSelected?: () => void;
  onLongPress?: () => void;
  showActionButtons?: boolean;
  theme?: FullTheme;
  containerStyle?: StyleProp<ViewStyle>;
  showChannels?: boolean;
  singleLine?: boolean;
  borderBottom?: boolean;
};

export const CitationNumber = ({num}: {num: number}) => {
  const getNumber = () => {
    if (num < 1000) return num;
    else if (num > 1000) return Math.floor(num / 1000) + 'k+';
  };
  return <View
    testID='citation'
    style={{
      flexDirection: 'row', alignItems: 'center',
      marginRight: 4,
    }}>
    {/* TODO: toLocaleString does not work in Android */}
    <IconCitation size={12} />
    <Text testID='citation_number'> {getNumber()}</Text>
  </View>;
};

export const TagList = ({tags}: {tags: string[]}) => {
  const allTags = useRecoilValue(allTagsState);
  return <View style={{flexDirection: 'row'}}>
    {uniqBy(
        Object.values(allTags).filter(
            (t) => !t.hidden && tags.includes(t.key),
        ),
        (tag) => JSON.stringify([tag.text, tag.icon, tag.style]),
    )
        .sort((t1, t2) => t2.priority - t1.priority)
        .map((t) => (
          <View
            key={t.key}
            style={{
              marginTop: 4,
              marginRight: 4,
            }}
          >
            <PaperTag tag={t} />
          </View>
        ))}
  </View>;
};

/**
 * PaperList's single item
 */
class _PaperListItem extends React.Component<PaperListItemProps> {
  static contextType = AppContext;

  /**
   * Check if the content should be updated based on changed props
   * @param _nextProps - next props
   * @returns true if the component needs to be updated
   */
  shouldComponentUpdate(
      nextProps: Readonly<PaperListItemProps>,
      _nextState: Readonly<Record<string, unknown>>,
      _nextContext: unknown,
  ): boolean {
    const p1 = nextProps.paper;
    const p2 = this.props.paper;
    if (!p1 || !p2) return true;
    if (p1.title !== p2.title) return true;
    if (p1.authorFull !== p2.authorFull) return true;
    if (p1.tags.length !== p2.tags.length) return true;
    if (p1.numCitations !== p2.numCitations) return true;
    return false;
  }

  /**
   * render this component
   */
  render() {
    const {settings, theme} = this.props;
    // Enforce non-null value for type check
    if (!settings || !theme) return <></>;

    const {
      onPaperSelected,
      selected,
      paper,
      onLongPress,
      containerStyle,
    } = this.props;

    const borderBottom = this.props.borderBottom ?? true;
    const singleLine = this.props.singleLine ?? settings.paperList.singleLine;

    const titleComponent =
      (paper.alias ?
        <Text style={{fontSize: 16}}>
          <Text style={{fontWeight: 'bold'}}>[{paper.alias}]</Text>
          <Text> - {paper.title}</Text>
        </Text> :
        <Text
          numberOfLines={singleLine ? 1 : 0}
          style={{width: '100%', fontSize: 16}}
        >
          {paper.title}
        </Text>
      );
    return paper ?
    <Pressable onPress={onPaperSelected} onLongPress={onLongPress}>
      {({pressed}) => (
        <View
          style={[{
            backgroundColor: selected || pressed ?
                    theme.colors?.backgroundHighlight :
                    theme.colors?.white,
            borderBottomWidth: borderBottom ? 1 : 0,
            borderColor: theme.colors.border,
            padding: 16,
          }, containerStyle]}
          testID="paper_list_item"
        >
          {this.props.showChannels &&
                <Text style={{fontSize: 12}}>
                  Returned by {paper.channels.map(
                      (c) => Sources.find((s) => s.key === c)?.name).join(', ')}
                </Text>}
          {titleComponent}
          <Text
            style={{fontStyle: 'italic'}}
            numberOfLines={singleLine ? 1 : 0}
          >
            {settings.paperList.shortAuthorNames ?
                  paper.authorShort :
                  paper.authorFull}</Text>
          <View style={{
            flexDirection: 'row', alignItems: 'flex-end', marginTop: 4,
            width: '100%',
          }}>
            <View
              style={{flex: 1}}
            >
              <Text numberOfLines={settings.paperList.singleLine ? 1 : 0}>
                {[paper.venue, paper.year].join(' ').trim()}
              </Text>
              {settings.paperList.showTags && (
                <TagList tags={paper.tags} />
              )}
            </View>
            <View style={{alignSelf: 'flex-end'}}>
              {paper.numCitations !== undefined &&
                  <CitationNumber num={paper.numCitations} />}
            </View>
          </View>
        </View>)}
    </Pressable> : <></>;
  }
}

const PaperListItem = (props: PaperListItemProps) => {
  const settings = useRecoilValue(settingsState);
  const {theme} = useTheme();
  return <_PaperListItem
    {...props} theme={theme as FullTheme} settings={settings} />;
};

export default PaperListItem;
