import Toast from 'react-native-root-toast';

/**
 * Show a toast with a success message
 * @param msg - message
 */
export function toastSuccess(msg: string): void {
  Toast.show(msg);
}

/**
 * Show a toast with an error message
 * @param msg - message
 * @param e - error
 */
export function toastError(msg: string, e: unknown = undefined): void {
  Toast.show(msg);
  if (__DEV__) throw e;
}
