import {atom} from 'recoil';
import {ListDisplayType} from '../../common/context';
import Paper, {} from '../../common/paper';
import {SourceKey, SourcePaper} from '../../common/sources/base';
import {defaultSettings, SortType} from '../../common/store';

export const allPapersState = atom<Record<string, Paper>>({
  key: 'AllPapers',
  default: {},
});

export const currentPaperState = atom<Paper | null>({
  key: 'CurrentPaper',
  default: null,
});

export const currentPaperSourcesState = atom<Record<SourceKey, SourcePaper>>({
  key: 'CurrentPaperSources',
  default: {} as Record<SourceKey, SourcePaper>,
});

export const listDisplayTypeState = atom<ListDisplayType>({
  key: 'ListDisplayType',
  default: ListDisplayType.Regular,
});

export const isFetchingCurrentPaperState = atom<boolean>({
  key: 'IsFetchingCurrentPaper',
  default: false,
});

export const searchQueryState = atom<string>({
  key: 'SearchQuery',
  default: '',
});

export const webSearchQueryState = atom<string>({
  key: 'WebSearchQuery',
  default: '',
});

export const sortByState = atom<SortType>({
  key: 'SortBy',
  default: defaultSettings.paperList.sortBy,
});
