import {pick} from 'lodash';
import {
  ArxivPaper,
  CrossRefPaper,
  getArxivIdFromUrl,
  SemanticScholarPaper,
  PaperShelfPaper,
  Sources,
} from './sources';
import {PaperQuery, SourceKey, SourcePaper} from './sources/base';
import {SimplePaper} from './simplepaper';
import {md5} from '../platform/misc';
import {OpenReviewPaper} from './sources/openReview';
import {SortType} from './store';
require('format-unicorn');

/**
 * replace any sequence of whitespace with a single space
 * @param title - title
 * @returns normalized title
 */
export function normalizeTitle(title: string): string {
  return title.replace(/\s+/g, ' ');
}

type Outline = { name: string; items: Outline | undefined }[];

export type Author = {
  fullName: string;
};

type PdfHighlightRect = {
  pageNumber?: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  height: number,
  width: number,
}

export type PdfHighlight = {
  id: string,
  content: {
    text?: string,
    image?: string,
  },
  position: {
    boundingRect?: PdfHighlightRect,
    pageNumber: number,
    rects?: PdfHighlightRect[]
  },
  comment?: {
    text: string,
    emoji: string,
  },
}

/**
 * Paper type
 */
type Paper = {
  id: string;
  ids: {
    ss?: string;
    doi?: string;
    arxiv?: string;
    dblp?: string;
    mag?: string;
  };
  title?: string;
  size?: number;
  alias?: string;
  pdfUrl?: string;
  htmlUrl?: string;
  inLibrary: boolean;
  abstract?: string;
  tldr?: string;
  authors: Author[];
  affiliations: string[];
  keywords?: string[];
  tags: string[];
  customTags: string[];
  autoTags: string[];
  year?: string;
  venue?: string;
  authorShort?: string;
  authorFull?: string;
  numCitations?: number;
  numReferences?: number;
  citations?: SimplePaper[];
  references?: SimplePaper[];
  sources: Record<SourceKey, SourcePaper>;
  dateAdded?: number;
  dateModified?: number;
  dateFetched: Record<string, number>;
  dateOpened?: number;
  pdfInfo?: {
    outline?: Outline;
    // destinations: Record<string, Destination>;
    // annotations: Annotation[][];
  };
  notes: {
    dateAdded?: number;
    dateModified?: number;
    content: string;
  }[];
  read: boolean;
  urls: {
    url: string;
    desc: string;
  }[];
  channels: string[];
  pdfHighlights: PdfHighlight[];
  pdfDisplayHorizontal: boolean;
  pdfPath?: string;

  // App state fields
  downloading: boolean;

  // Generated fields
  authorFullLastName?: string;
  authorNames?: string[];
  note: string;
}

/**
 * Create a new paper with default fields
 * @param id - paper id
 * Paper object with default values
 */
export function createNewPaper(id?: string): Paper {
  return {
    id: id || '',
    authors: [],
    affiliations: [],
    tags: [],
    customTags: [],
    autoTags: [],
    citations: [],
    references: [],
    isFetching: false,
    inLibrary: false,
    read: false,
    ids: {},
    notes: [],
    urls: [],
    channels: [],
    sources: {} as Record<SourceKey, SourcePaper>,
    dateFetched: {},
    note: '',
    pdfHighlights: [],
    pdfDisplayHorizontal: false,
    downloading: false,
  } as Paper;
}

/**
 * @returns minimum JSON string of the paper for serialization.
 */
export function getPaperLocalJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'title',
    'abstract',
    'venue',
    'year',
    'alias',
    'pdfUrl',
    'htmlUrl',
    'inLibrary',
    'authors',
    'removed',
    'thumbnail',
    'dateAdded',
    'dateModified',
    'dateFetched',
    'dateOpened',
    'urls',
    'read',
    'currentPage',
    'affiliations',
    'numCitations',
    'numReferences',
    'tags',
    'syncRequired',
    'tldr',
    'note',
    'pdfHighlights',
    'pdfDisplayHorizontal',
    'pdfPath',
  ]);
}

/**
 * @returns minimum JSON string of the paper for serialization.
 */
export function getPaperRemoteJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'ids',
    'title',
    'alias',
    'pdfUrl',
    'htmlUrl',
    'inLibrary',
    'authors',
    'tags',
    'removed',
    'thumbnail',
    'dateAdded',
    'dateModified',
    'dateOpened',
    'read',
    'currentPage',
    'numCitations',
    'year',
    'venue',
    'note',
    'pdfHighlights',
    'pdfDisplayHorizontal',
  ]);
}

/**
 * @returns remote public JSON used to update the paper dataset
 */
export function getPaperRemotePublicJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'id',
    'ids',
    'title',
    'abstract',
    'tldr',
    'authors',
    'autoTags',
    'numCitations',
    'numReferences',
    'year',
    'venue',
  ]);
}

/**
 * Normalize & remove duplicate tags.
 */
function _refreshTags(p: Paper): Paper {
  return {
    ...p,
    tags: [
      ...new Set(
          p.tags
              .map((s) => s?.toLowerCase().replace(/\s+/, '-'))
              .filter((s) => s),
      ),
    ],
    customTags: p.tags.filter((t) => !t.includes(':')),
    autoTags: p.tags.filter((t) => t.includes(':')),
  };
}

/**
 * Refresh authors fields.
**/
function _refreshAuthors(p: Paper): Paper {
  const authorNames = p.authors.map((a) => a.fullName || '');
  return {
    ...p,
    authorNames,
    authorShort: authorNames.length > 2 ? `${authorNames[0]
        .split(' ')
        .slice(-1)
        .pop()} et al.` : authorNames.join(', '),
    authorFull: authorNames.join(', '),
    authorFullLastName: authorNames
        .map((name) => name.split(' ').slice(-1).pop())
        .join(', '),
  };
}

/**
 * Refresh urls.
 */
function _refreshUrls(p: Paper): Paper {
  let urls = p.urls.filter((u) => u.url);
  urls = urls.filter(({url}, index, self) =>
    index === self.findIndex((t) => t.url === url));
  return {...p, urls};
}

/**
 * Called after changing paper's fields to clean if necessary and
 * update other dependent fields.
 */
export function refreshPaper(p: Paper): Paper {
  let paper: Paper = {
    ...p,
    pdfUrl: p.pdfUrl?.replace('http:', 'https:'),
    dateFetched: typeof p.dateFetched === 'object' ? p.dateFetched : {},
  };
  paper = _refreshTags(paper);
  paper = _refreshAuthors(paper);
  paper = _refreshUrls(paper);
  return paper;
}

/**
 * Generate paper's id based on title and authors
 * @returns paper's id
 */
export async function getPaperId(p: Paper) {
  if (!p.title || !p.authorNames) return null;
  const newId =
    p.title +
    p.authorNames
        ?.map((name) => name.split(' ').slice(-1).pop())
        .sort()
        .join();
  return await md5(newId.toLowerCase().replace(/\W/g, ''));
}

/**
 * Add tags
 * @param currentTags - list of current tags
 * @param newTags - list of tag names
 */
export function appendTags(currentTags: string[], newTags: string[]): string[] {
  const tagNames = newTags.map((tag) =>
    tag
        .toLowerCase()
        .replace(/[ .]/g, '-')
        .replace(/[^a-z0-9\-:]/g, ''),
  );
  return [...new Set([...currentTags, ...tagNames])];
}

/**
 * Remove a tag
 * @param tag - a tag name
 */
export function removeTag(tags: string[], tag: string): string[] {
  return tags.filter((t) => t !== tag);
}

/**
 * @param sp - arxiv paper
 */
function _populateFromArxiv(p: Paper, sp: ArxivPaper): Paper {
  p.pdfUrl = sp.pdfUrl;
  p.title = normalizeTitle(sp.title || '');
  p.abstract = p.abstract || sp.abstract;
  p.year =
            p.year || sp.updated.getFullYear().toString();
  p.authors = sp.authors.map((name) => ({
    fullName: name,
  }));
  p.tags = appendTags(p.tags, sp.categories.map((t) => `arXiv:${t}`));
  p.urls.push({
    url: sp.pdfUrl,
    desc: 'ArXiv',
  });
  p.htmlUrl = sp.htmlUrl;
  p.urls.push({
    url: p.htmlUrl,
    desc: 'Ar5iv',
  });
  return p;
}

/**
 * @param sp - semantic paper
 */
function _populateFromSemanticScholar(
    p: Paper, sp: SemanticScholarPaper,
): Paper {
  if (!sp.paperId) return p;
  p.title = p.title || sp.title;
  p.abstract = p.abstract || sp.abstract;
  if (p.authors.length === 0) {
    p.authors = sp.authors.map((a) => ({
      fullName: a.name,
    })) || [];
  }

  p.affiliations = [
    ...new Set(
        sp.authors
            .map((a) => a.affiliations)
            .filter((a) => !!a)
            .flat(),
    ),
  ];
  p.tags = appendTags(p.tags, p.affiliations.map((a) => 'affiliated:' + a));

  p.numCitations = sp.citationCount;
  p.numReferences = sp.referenceCount;
  p.citations = sp.citations?.map(
      (p) =>
        ({
          title: p.title,
          authors: p.authors.map((a) => a.name),
          ids: {ss: p.paperId},
        } as SimplePaper),
  );
  p.references = sp.references?.map(
      (p) =>
        ({
          title: p.title,
          authors: p.authors.map((a) => a.name),
          ids: {ss: p.paperId},
        } as SimplePaper),
  );

  p.year = p.year || sp.year;
  p.venue = sp.venue;
  if (sp.topics) {
    p.tags = appendTags(
        p.tags, sp.topics.map((t) => `ss:${t.topic}`));
  }
  if (sp.venue.toLowerCase() === 'arxiv') {
    p.tags = appendTags(p.tags, ['auto:preprint']);
  }
  p.ids = {
    ss: sp.paperId,
    arxiv: p.ids.arxiv || sp.externalIds.ArXiv,
    doi: p.ids.doi || sp.externalIds.DOI,
    mag: p.ids.mag || sp.externalIds.MAG,
    dblp: p.ids.dblp || sp.externalIds.DBLP,
  };
  p.urls = [
    ...p.urls,
    {
      url: sp.url,
      desc: 'Semantic Scholar',
    },
  ];
  return p;
}

/**
 * @param sp - crossref paper
 */
function _populateFromCrossRef(p: Paper, sp: CrossRefPaper): Paper {
  if (sp.url) {
    p.urls.push({
      url: sp.url,
      desc: 'CrossRef',
    });
  }
  p.venue = p.venue || sp.event?.name;
  return p;
}

/**
 * @param sp - paper shelf paper
 */
function _populateFromPaperShelf(p: Paper, sp: PaperShelfPaper): Paper {
  p.title = p.title || sp.title;
  p.authors = p.authors.length === 0 ?
    sp.authors : sp.authors;
  p.alias = p.alias || sp.alias;
  p.tldr = p.tldr || sp.tldr;
  p.numCitations = p.numCitations || sp.numCitations;
  p.numReferences = p.numReferences || sp.numReferences;
  p.venue = p.venue || sp.venue;
  p.year = p.year || sp.year;
  return p;
}

/**
 * @param sp - open review paper
 */
function _populateFromOpenReview(p: Paper, sp: OpenReviewPaper): Paper {
  if (!sp.content) return p;
  p.title = p.title || sp.title;
  p.tldr = p.tldr || sp.content['TL;DR'];
  if (sp.content.code) {
    p.urls.push({
      url: sp.content.code,
      desc: 'OpenReview (Code)',
    });
  }
  if (sp.forum) {
    p.urls.push({
      url: `https://openreview.net/forum?id=${sp.forum}`,
      desc: 'OpenReview (Forum)',
    });
  }
  p.venue = p.venue || sp.content.venue;
  p.pdfUrl = p.pdfUrl ||
              (sp.content.pdf);
  if (sp.content.keywords) {
    p.tags = appendTags(p.tags, sp.content.keywords.map(
        (kw) => `openreview:${kw}`));
  }
  return p;
}

/**
 * @returns updated paper
 */
export function populateFieldsFromSources(paper: Paper): Paper {
  let p: Paper = {
    ...paper,
    tags: paper.tags.filter((t) => !t.includes(':')),
    urls: [],
  };

  Promise.all(
      (Object.entries(p.sources) as Array<[SourceKey, SourcePaper]>).map(
          ([source, sp]) => {
            if (!sp) return;
            if (sp.error) return;
            p.tags = appendTags(p.tags, [`auto:${source}`]);
            switch (source) {
              case 'arxiv': {
                p = _populateFromArxiv(p, sp as ArxivPaper);
                break;
              }
              case 'semanticScholar': {
                p = _populateFromSemanticScholar(p,
                    sp as SemanticScholarPaper);
                break;
              }
              case 'crossRef': {
                p = _populateFromCrossRef(p, sp as CrossRefPaper);
                break;
              }
              case 'paperShelf': {
                p = _populateFromPaperShelf(p, sp as PaperShelfPaper);
                break;
              }
              // case GoogleScholar.source: {
              //   const p = paper as GoogleScholarPaper;
              //   this.title = this.title || p.title;
              //   this.abstract = this.abstract || p.abstract;
              //   if (this.authors.length === 0)
              //     this.authors = googlePaper.authors.map((a) => a.name);
              //   this.numCitations = this.numCitations || p.numCitations;

              //   if (googlePaper.venue)
              //     this.appendTags([`venue:${googlePaper.venue}`]);
              //   if (googlePaper.year) this.appendTags([`year:${p.year}`]);
              //   break;
              // }
              case 'openReview': {
                p = _populateFromOpenReview(p, sp as OpenReviewPaper);
                break;
              }
              default: {
                break;
              }
            }
          },
      ));

  return refreshPaper(p);
}

/**
 * Convert key-value pairs to Paper object
 * @param papers - list of papers of key-value pairs
 * @returns list of papers
 */
export function getPapersFromObject(papers: Record<string, unknown>): Paper[] {
  return Object.entries(papers).map(
      ([key, paper]) =>
        ({
          ...(paper as Paper),
          id: key,
          inLibrary: true,
        }),
  );

  /*
  try {
    const fileContents = fs.readFileSync(
      `${store.get('dataLocation')}/papers.yml`,
      'utf8'
    );
    const data = yaml.load(fileContents);
    return Object.entries(data!.papers as Paper[]).map(([key, paper]) => ({
      ...paper,
      id: key,
    }));
  } catch (e) {
    console.log(e);
    return [];
  }
  */
}

/**
 * Fetch paper details from different sources
 * @param paper - a paper
 * @param fetchPaperSources - list of source names
 */
export async function fetchPaper(
    paper: Paper,
    fetchPaperSources: SourceKey[],
    forceRefresh?: boolean,
    updateProgressFn?: (paper: Paper, msg: string) => void,
): Promise<Paper> {
  let p: Paper = {...paper};
  const sources = fetchPaperSources;
  for (const sourceKey of sources) {
    if (!forceRefresh && p.dateFetched && p.dateFetched[sourceKey]) continue;
    const src = Sources.find((s) => s.key === sourceKey);
    if (!src) continue;
    const paperQuery: PaperQuery = {
      id: p?.id,
      arxiv:
        p?.ids.arxiv ||
        (p?.pdfUrl ? getArxivIdFromUrl(p?.pdfUrl) || undefined : undefined),
      doi: p?.ids.doi,
      title: p.title,
      authors: p.authorNames,
    };
    updateProgressFn && updateProgressFn(p, `Loading from ${src.name}...`);
    try {
      const sp = await src.cls.fetch(paperQuery);
      if (sp) {
        p = populateFieldsFromSources({
          ...p,
          sources: {
            ...p.sources,
            [sourceKey]: sp,
          },
          dateFetched: {
            ...p.dateFetched,
            [sourceKey]: Date.now(),
          },
          dateModified: Date.now(),
        });
      }
      console.log('fetch paper', sourceKey, p.id, ': success');
    } catch (e) {
      p = {
        ...p,
        sources: {
          ...p.sources,
          [sourceKey]: {error: (e as Error).message || (e as string)},
        },
        dateFetched: {
          ...p.dateFetched,
          [sourceKey]: Date.now(),
        },
        dateModified: Date.now(),
      };
      console.log('fetch paper', sourceKey, p.id, ': failed', e);
    }
  }
  return p;
}

/**
 * search for papers
 * @param query - a string query
 * @param searchPaperSources - list of source names
 * @param callback - callback function called after each source finished
 * @param offset - offset value
 * @param limit - limit value
 * @returns list of papers
 */
export async function searchPaper(
    query: string,
    searchPaperSources: SourceKey[],
    callback?: (p: Paper[], source: string) => void,
    offset = 0,
    limit = 10,
): Promise<Paper[]> {
  let sources = searchPaperSources;
  try {
    const searchResults = await Promise.all(
        Sources.map(async (src) => {
          if (!sources.includes(src.key)) return [];
          try {
            const srcPapers = await src.cls.search(query, offset * 10, limit);
            const papers = srcPapers.map((sp: SourcePaper) =>
              populateFieldsFromSources({
                ...createNewPaper(),
                id: '',
                sources: {[src.key]: sp},
              } as Paper),
            );
            await Promise.all(papers.map(async (p) => {
              p.id = (await getPaperId(p)) || '';
              return p;
            }));
            sources = sources.filter((s) => s !== src.key);
            callback && callback(papers, src.key);
            return papers;
          } catch (e) {
            console.log(e);
            callback && callback([], src.key);
            return [];
          }
        }),
    );
    // Assign ids and remove papers that do not have an id (empty)
    return searchResults.flat();
  } catch (err) {
    return [];
  }
}

/**
 * Sort a list of papers
 * @param paperList - list of papers
 * @param sortType - one of SortType
 * @returns sorted list of papers
 */
export function sortPapers(paperList: Paper[], sortType: SortType) {
  switch (sortType) {
    case SortType.ByDateOpened:
      return paperList.sort((a: Paper, b: Paper) =>
        -(a.dateOpened || 0) + (b.dateOpened || 0),
      );
    case SortType.ByDateAdded:
      return paperList.sort((a: Paper, b: Paper) =>
        a.dateAdded && b.dateAdded ? -a.dateAdded + b.dateAdded : 1,
      );
    case SortType.ByDateModified:
      return paperList.sort((a: Paper, b: Paper) =>
        a.dateModified && b.dateModified ? -a.dateModified + b.dateModified : 1,
      );
    case SortType.ByYear:
      return paperList.sort((a: Paper, b: Paper) =>
        a.year && b.year ? -a.year.toString().localeCompare(b.year) : 1,
      );
    case SortType.ByCitation:
      return paperList.sort((a: Paper, b: Paper) =>
        -(a.numCitations || 0) + (b.numCitations || 0),
      );
    case SortType.ByTitle:
      return paperList.sort((a: Paper, b: Paper) =>
        a.title && b.title ? a.title.localeCompare(b.title) : 1,
      );
    default:
      return paperList;
  }
}

/**
 * Merge two papers
 * @param p1 - paper 1
 * @param p2 - paper 2
 * @returns - merged paper
 */
export function mergePaper(p1: Paper | null, p2: Paper | null): Paper {
  return {
    ...createNewPaper(),
    ...p1 || {},
    ...p2 || {},
    sources: {
      ...p1?.sources,
      ...p2?.sources,
    },
  } as Paper;
}

export default Paper;
