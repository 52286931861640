import React from 'react';
import {ModalOptionsType} from '../components/Modal';
import {PublicCollection, UserInfo} from './api';
import {PublicCollectionGroup} from './api/collection';
import Collection, {createNewCollection} from './collection';
import Paper from './paper';
import {SourceKey} from './sources/base';
import {SortType} from './store';
import Tag from './tag';

export enum ListDisplayType {
  Regular = 'regular',
  CollectionSearch = 'collection_search',
  LibrarySearch = 'library_search',
  WebSearch = 'web_search',
}

export type AppContextType = {
  actions: {
    newCollection: (title: string) => Promise<Collection>;
    addPaperToLibrary: (p: Paper) => Promise<void>;
    addPaperToCollection: (p: Paper, c: Collection) => Promise<void>;
    removePaperFromLibrary: (p: Paper | Paper[]) => Promise<void>;
    removePaperFromCollection: (p: Paper, c: Collection) => Promise<void>;
    fetchPaper: (
      p: Paper,
      source?: SourceKey[],
      forceRefresh?: boolean,
      updateProgressFn?: (p: Paper, msg: string) => void
    ) => Promise<Paper>;
    resetTags: () => Promise<Record<string, Tag>>;
    clearTags: () => Promise<void>;
    clearLocalData: () => Promise<void>;
    deleteCollection: (c: Collection) => Promise<void>;

    /**
     * Save paper first, then sync. This is to ensure that the local paper is
     * the newer when syncing. App state is updated after finishing.
     * @param p - a paper
     */
    savePaperAndSync: (p: Paper) => Promise<Paper>;
    saveCollectionAndSync: (c: Collection) => Promise<Collection>;
    download: (p: Paper) => Promise<void>;
    removePdf: (p: Paper) => Promise<Paper>;
    share: () => Promise<void>;
    sharePdf: (fp?: string) => Promise<void>;
  };
  loadAppData: () => Promise<void>;
  loadSettings: () => Promise<void>;
  showModal: (
    content: (close: () => void) => JSX.Element,
    options: ModalOptionsType
  ) => void;
  closeModal: () => void;
  useDark: () => boolean;

  // Events
  onPaperOrderChanged: (sortType: SortType) => void;
};

export const AppContext = React.createContext<AppContextType>({
  actions: {
    newCollection: (_t: string) => Promise.resolve(createNewCollection()),
    addPaperToLibrary: (_p: Paper) => Promise.resolve(),
    addPaperToCollection: (_p: Paper, _c: Collection) => Promise.resolve(),
    removePaperFromCollection: (_p: Paper, _c: Collection) => Promise.resolve(),
    removePaperFromLibrary: (_p: Paper | Paper[]) => Promise.resolve(),
    fetchPaper: (
        _p: Paper,
        _source?: string[],
        _forceRefresh?: boolean,
        _updateProgressFn?: (p: Paper, msg: string) => void,
    ) => Promise.resolve(_p),
    resetTags: () => Promise.resolve({}),
    clearTags: () => Promise.resolve(),
    clearLocalData: () => Promise.resolve(),
    deleteCollection: (_c: Collection) => Promise.resolve(),
    savePaperAndSync: () => Promise.resolve({} as Paper),
    saveCollectionAndSync: () => Promise.resolve({} as Collection),
    download: (_p: Paper) => Promise.resolve(),
    removePdf: (_p: Paper) => Promise.resolve({} as Paper),
    share: () => Promise.resolve(),
    sharePdf: (_fp?: string) => Promise.resolve(),
  },
  loadAppData: () => Promise.resolve(),
  loadSettings: () => Promise.resolve(),
  showModal: (_content, _options: ModalOptionsType) => {
    /* empty */
  },
  closeModal: () => {
    /* empty */
  },
  useDark: () => false,
  // Events
  onPaperOrderChanged: (_sortType) => {
    /* empty */
  },
});

export type PublicCollectionContextType = {
  allPublicCollections: Record<string, PublicCollection>;
  usersInfo: Record<string, UserInfo>;
  allPublicCollectionGroups: Record<string, PublicCollectionGroup>;
  cardWidth: number;
};

export const PublicCollectionContext =
  React.createContext<PublicCollectionContextType>({
    allPublicCollections: {},
    allPublicCollectionGroups: {},
    usersInfo: {},
    cardWidth: 400,
  });
