import {merge} from 'lodash';
import {
  localStorageSetItem,
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageGetKeys,
} from '../../platform/localStorage';
import Paper, {
  createNewPaper,
  fetchPaper,
  getPaperId,
  getPaperLocalJSON,
  getPaperRemoteJSON,
  getPaperRemotePublicJSON,
  populateFieldsFromSources,
  refreshPaper,
} from '../paper';
import {isLoggedIn, uid, updateLastSynced} from './utils';
import {db, functions} from '../../platform/firebase';
import _ from 'lodash';
import {Sources} from '../sources';
import {SourceKey, SourcePaper} from '../sources/base';

/**
 * Save a paper to local storage. Before saving, fields are refreshed if
 * necessary. dateModified is updated to the current time unless it is
 * specified. Paper is only saved when inLibrary is set to true.
 * @param paper - a paper
 * @param date - the timestamp to set for dateModified. Empty for now.
 * @returns saved paper
 */
const save = async (paper: Paper, date?: number): Promise<Paper> => {
  const p = refreshPaper(paper);
  if (!p.inLibrary) return Promise.resolve(p);
  if (!p.dateAdded) {
    p.dateAdded = date || Date.now();
  }
  p.dateModified = date || Date.now();

  // Save to local storage
  const data = JSON.stringify(getPaperLocalJSON(p));
  await localStorageSetItem(`paper:${p.id}`, data);
  for (const sourceKey of Object.keys(p.sources) as SourceKey[]) {
    if (!p.sources[sourceKey]) continue;
    await localStorageSetItem(
        `paperSource:${sourceKey}:${p.id}`,
        JSON.stringify(p.sources[sourceKey]));
  }
  p.size = data.length * 2;
  console.log('Paper saved', p.id);
  return p;
};

const local = {
  save,
  loadSource: async (pid: string, sourceKey: SourceKey) => {
    const data = await localStorageGetItem(`paperSource:${sourceKey}:${pid}`);
    if (!data) return null;
    return JSON.parse(data) as SourcePaper;
  },
  loadSources: async (pid: string): Promise<Record<string, SourcePaper>> => {
    const sources = await Promise.all(Sources.map(async (src) => {
      return [src.key, await local.loadSource(pid, src.key)];
    }));
    return Object.fromEntries(sources);
  },
};

const server = {
  get: async (pid: string): Promise<Paper | null> => {
    if (!isLoggedIn()) return null;
    if (!db) return null;
    try {
      const snapshot = await db.ref(
          `users/${uid()}/papers/${pid}`).once('value');
      const data = snapshot.val();
      return data ? {
        ...createNewPaper(pid),
        ...data,
      } as Paper : null;
    } catch (e: unknown) {
      console.log(e);
      return null;
    }
  },
  /**
   * Save paper to the server.
   * @param paper - a paper
   */
  save: async (paper: Paper): Promise<void> => {
    if (!isLoggedIn()) return;
    if (!paper.id || !paper.inLibrary) return;
    await db?.ref(`users/${uid()}/papers/${paper.id}`).set(
        JSON.parse(
            JSON.stringify({
              ...getPaperRemoteJSON(paper),
            }),
        ),
    );
    await db?.ref(`users/${uid()}/dateModified/papers/${paper.id}`)
        .set(paper.dateModified);

    await updateLastSynced();
    console.log('Paper saved to server', paper.id);
  },
  sync: async () => {
    if (!isLoggedIn()) return;
    const dateModified = (await db?.ref(
        `users/${uid()}/dateModified/papers/`).once('value')
        )?.val() as Record<string, number> || {};
    const localPaperIds = (await localStorageGetKeys()).filter((key) =>
      key.startsWith('paper:')).map((key) => key.split(':')[1]);
    const allIds = [...(
      new Set([...Object.keys(dateModified), ...localPaperIds]))];
    await Promise.all(allIds.map(async (id) => {
      const localPaper = await load(id);
      if (localPaper && !localPaper.dateModified) {
        throw new Error('Invalid dateModified: ' + id);
      }
      if (!localPaper ||
        (localPaper.dateModified || 0) < (dateModified[id] || 0)) {
        console.log('Downloaded paper: ', id,
            'local:', localPaper?.dateModified,
            'remote:', dateModified[id]);
        const remotePaper = await server.get(id);
        if (!remotePaper) {
          await db?.ref(`users/${uid()}/dateModified/papers/${id}`)
              .remove();
          return;
        }
        const updatedPaper = _.merge(
            localPaper || createNewPaper(id), remotePaper);
        console.log(updatedPaper.id);
        await local.save(updatedPaper, dateModified[id]);
      } else if (!dateModified[id] ||
        (localPaper.dateModified || 0) > dateModified[id]) {
        console.log('Uploaded paper: ' + id);
        await server.save(localPaper);
      }
    }));
  },
  remove: async (pid: string) => {
    if (!isLoggedIn()) return;
    await db?.ref(`users/${uid()}/papers/${pid}`).remove();
    await updateLastSynced();
  },
};

/**
 * Send public, non-personal information about a paper to the server.
 * @param paper - a paper
 */
const updatePublicPaperRecord = async (paper: Paper) => {
  await functions?.httpsCallable('updatePaper')(
      getPaperRemotePublicJSON(paper));
};

/**
 * Fetch info from different sources for a paper.
 *
 * @param paper - a paper
 * @param fetchPaperSources - list of source names
 * @param updateProgressFn - a function that will be called when each source is
 * fetched
 * @param onPaperIdChanged - a function that will be called when the paper id is
 * changed (due to new info from sources)
 * @returns the updated paper
 */
async function fetch(
    paper: Paper,
    fetchPaperSources: SourceKey[],
    forceRefresh?: boolean,
    updateProgressFn?: (paper: Paper, msg: string) => void,
): Promise<Paper> {
  const p = populateFieldsFromSources(
      await fetchPaper(
          paper, fetchPaperSources, forceRefresh, updateProgressFn));

  // Change paper id if needed
  const newId = await getPaperId(p);
  if (newId && p.id !== newId) {
    p.id = newId;
    p.dateModified = Date.now();
  }

  return p;
}

const load = async (pid: string): Promise<Paper | null> => {
  const json = await localStorageGetItem(`paper:${pid}`);
  if (!json) return null;
  const data = JSON.parse(json);
  const paper = refreshPaper({
    ...createNewPaper(pid),
    ...data,
    size: json.length * 2,
  } as Paper);
  return paper;
};

/**
 * Remove a paper in local storage and server.
 * @param pid - paper id
 */
async function remove(pid: string): Promise<void> {
  await localStorageRemoveItem(`paper:${pid}`);
  await server.remove(pid);
}

const onPaperDeleted = (callback: (pid: string) => void) => {
  if (!db || !isLoggedIn()) return;
  db?.ref(`users/${uid()}/papers`).off('child_removed');
  db?.ref(`users/${uid()}/papers`).on('child_removed', async (data) => {
    if (data.key) {
      await localStorageRemoveItem(`paper:${data.key}`);
      callback(data.key);
    }
  });
  return () => db?.ref(`users/${uid()}/papers`).off('child_removed');
};

const onPaperChanged = (callback: (pid: string) => void) => {
  if (!db || !isLoggedIn()) return;
  db?.ref(`users/${uid()}/papers`).off('child_changed');
  db?.ref(`users/${uid()}/papers`).on('child_changed', async (data) => {
    if (!data.key) return;
    const paperJson = await localStorageGetItem(`paper:${data.key}`);
    const d = paperJson ?
        merge(JSON.parse(paperJson), data.val()) :
        data.val();
    await localStorageSetItem(`paper:${data.key}`, JSON.stringify(d));
    callback(data.key);
  });
  return () => db?.ref(`users/${uid()}/papers`).off('child_changed');
};

export default {
  save,
  fetch,
  load,
  remove,
  onPaperDeleted,
  onPaperChanged,
  updatePublicPaperRecord,
  server,
  local,
};
