import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import React from 'react';
import {Linking} from 'react-native';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {
  IconAccount,
  IconCollection,
  IconConfig,
  IconPaper,
  IconTag,
} from '../../platform/icons';
import {SettingsStackParamList} from './Navigator';

const Main = (): JSX.Element => {
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();
  return (
    <PreferencesScreen
      sections={[
        {
          id: 'general',
          items: () => [
            {
              id: 'general',
              kind: ItemKind.Menu,
              title: 'General',
              onPress: () => {
                navigation.navigate('General');
              },
              icon: <IconConfig />,
            },
            {
              id: 'account',
              kind: ItemKind.Menu,
              title: 'Account',
              onPress: () => navigation.navigate('Account'),
              icon: <IconAccount />,
            },
            {
              id: 'papers',
              kind: ItemKind.Menu,
              title: 'Papers',
              onPress: () => navigation.navigate('Papers'),
              icon: <IconPaper />,
            },
            {
              id: 'collections',
              kind: ItemKind.Menu,
              title: 'Collections',
              onPress: () => navigation.navigate('Collections'),
              icon: <IconCollection />,
            },
            {
              id: 'tags',
              kind: ItemKind.Menu,
              title: 'Tags',
              onPress: () => navigation.navigate('Tags'),
              icon: <IconTag />,
            },
          ],
        },
        // { title: "Feed", route: "Feed" },
        {
          id: 'support',
          title: '',
          items: () => [
            {
              id: 'contact',
              kind: ItemKind.Button,
              title: 'Contact Us',
              onPress: () => {
                Linking.openURL('mailto:contact@papershelf.app');
              },
            },
            {
              id: 'website',
              kind: ItemKind.Button,
              title: 'Website',
              onPress: () => {
                Linking.openURL('https://www.papershelf.app');
              },
            },
          ],
        },
      ]}
    />
  );
};

export default Main;
