import {
  localStorageGetItem,
  localStorageSetItem,
} from '../../platform/localStorage';
import {db, auth, analytics} from '../../platform/firebase';

/**
 * Unauthenticated error
 */
export class UnauthenticatedActionError extends Error {
  /**
   * Constructor
   * @param msg - error message
   */
  constructor(msg?: string) {
    super(msg);
    Object.setPrototypeOf(this, UnauthenticatedActionError.prototype);
  }
}

/**
 * @returns authenticated user id
 */
export function uid(): string {
  if (!auth) return '';
  const uid = auth?.currentUser?.uid || '';
  return uid;
}

/**
 * @returns true if the user is logged in
 */
export const isLoggedIn = (): boolean => !!uid();

/**
 * update local storage's last synced time
 */
export async function updateLastSynced(): Promise<void> {
  if (!isLoggedIn()) return Promise.resolve();
  const date = Date.now();
  await db?.ref('users').child(uid()).child('lastUpdated').set(date);
  return await localStorageSetItem('lastUpdated', date.toString());
}

/**
 * @returns timestamp of the last time data in local storage was updated.
 */
export async function getLocalLastUpdated(): Promise<number | undefined> {
  const last = await localStorageGetItem('lastUpdated');
  return last ? parseInt(last) : undefined;
}

/**
 * @returns timestamp of the last time data in server was updated.
 */
export async function getServerLastUpdated(): Promise<number | undefined> {
  return (
    (
      await db?.ref('users').child(uid()).child('lastUpdated').once('value')
    )?.val() || 0
  );
}

/**
 * Log an event in analytics
 * @param name - name
 * @param params - params
 */
export async function logEvent(name: string, params: Record<string, unknown>) {
  if (!analytics) return;
  analytics?.logEvent(name, params);
}
