import React, {useContext, useEffect, useState} from 'react';

import Paper, {PdfHighlight} from '../common/paper';
// import Pdf from '../components/Pdf';
import {View} from 'react-native';
import {LinearProgress, Text, useTheme} from '@rneui/themed';
import {Toolbar, ToolbarItem} from './ToolbarItem';
import {IconClose, IconHighlight} from '../platform/icons';
import {AppContext} from '../common/context';
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  AreaHighlight,
  IHighlight,
} from './PdfView';
import {uuid} from '../platform/misc';
import HighlightList from './HighlightList';
import {settingsState} from '../recoil/atoms';
import {useRecoilState} from 'recoil';

type PdfViewerProps = {
  paper: Paper | null;
  addToLibrary: (paper: Paper) => void;
};

const PdfViewer = ({paper = null}: PdfViewerProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [_numPages, _setNumPages] = useState<number>(0);

  const {theme} = useTheme();
  const {actions: {savePaperAndSync}} = useContext(AppContext);
  const [currentHighlight, setCurrentHighlight] =
    useState<PdfHighlight | null>(null);
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);
  const [settings, setSettings] = useRecoilState(settingsState);

  useEffect(() => {
    console.log('updated');
    setErrorMsg(undefined);
    setHighlights(paper?.pdfHighlights || []);
    console.log(paper);
  }, [paper?.id]);

  const updateHighlights = async (hs: PdfHighlight[]) => {
    setHighlights(hs);
    const newPaper = {...paper, pdfHighlights: hs} as Paper;
    await savePaperAndSync(newPaper);
  };

  const addCurrentHighlight = async () => {
    if (!paper) return;
    if (!currentHighlight) return;
    if (highlights.find((h) => h.id === currentHighlight.id)) return;
    updateHighlights([
      ...highlights,
      currentHighlight,
    ]);
  };

  // const updateHighlight = (
  //     highlightId: string, position: Object, content: Object) => {
  //   console.log('Updating highlight', highlightId, position, content);

  //   // this.setState({
  //   //   highlights: this.state.highlights.map((h) => {
  //   //     const {
  //   //       id,
  //   //       position: originalPosition,
  //   //       content: originalContent,
  //   //       ...rest
  //   //     } = h;
  //   //     return id === highlightId
  //   //       ? {
  //   //           id,
  //   //           position: { ...originalPosition, ...position },
  //   //           content: { ...originalContent, ...content },
  //   //           ...rest,
  //   //         }
  //   //       : h;
  //   //   }),
  //   // });
  // };

  const noPaperView = (<View
    style={{
      flex: 1,
      justifyContent: 'center',
      padding: 16,
      alignItems: 'center',
    }}
  >
    <Text>{errorMsg || 'No paper selected.'}</Text>
  </View>);

  const toolbar = <Toolbar borderBottom={true}>
    {/* <ToolbarItem icon={<IconNote />} onPress={() => {
      setIsNoteVisible(!isNoteVisible);
      const newPaper = {
        ...paper,
        note: noteContent,
      } as Paper;
      savePaperAndSync(newPaper);
    }} /> */}
    <ToolbarItem
      icon={IconHighlight}
      disabled={!!highlights.find((h) => h.id === currentHighlight?.id) ||
        !currentHighlight?.content.text}
      onPress={addCurrentHighlight} />
    <View style={{flex: 1}} />
    <ToolbarItem
      icon={IconClose}
      onPress={() => {
        setSettings({
          ...settings,
          showNotes: !settings.showNotes,
        });
      }} />
  </Toolbar>;

  const pdfLoader = <PdfLoader
    url={paper?.pdfUrl || ''}
    beforeLoad={<LinearProgress />}
  >
    {(pdfDocument) => (
      <PdfHighlighter
        pdfDocument={pdfDocument}
        onScrollChange={() => {
          document.location.hash = '';
        } }
        highlights={highlights as IHighlight[]}
        onSelectionFinished={(position, content) => {
          setCurrentHighlight({
            id: uuid(),
            content,
            position,
            comment: undefined,
          });
          return null;
        } }
        highlightTransform={(
            highlight,
            index,
            setTip,
            hideTip,
            viewportToScaled,
            screenshot,
            isScrolledTo) => {
          const isTextHighlight = !(
            highlight.content && highlight.content.image);

          const component = isTextHighlight ? (
            <Highlight
              isScrolledTo={isScrolledTo}
              position={highlight.position}
              comment={highlight.comment}
              onClick={() => {
                console.log(highlight);
                setCurrentHighlight(highlight);
              } } />
          ) : (
            <AreaHighlight
              isScrolledTo={isScrolledTo}
              highlight={highlight}
              onChange={(boundingRect) => {
                // updateHighlight(
                //     highlight.id,
                //     {boundingRect: viewportToScaled(boundingRect)},
                //     {image: screenshot(boundingRect)},
                // );
              } } />
          );

          return (
            component
            // <Popup
            //   popupContent={
            //     <div className="Highlight__popup">
            //       <Text>{highlight.id}</Text>
            //     </div>
            //   }
            //   onMouseOver={(popupContent) =>
            //     setTip(highlight, (highlight) => popupContent)
            //   }
            //   onMouseOut={hideTip}
            //   key={index}
            // >{component}</Popup>
          );
        }}
        scrollRef={function(scrollTo: (highlight: IHighlight) => void): void {
          // throw new Error('Function not implemented.');
        }}
        enableAreaSelection={function(event: MouseEvent): boolean {
          // throw new Error('Function not implemented.');
          return false;
        }} />
    )}
  </PdfLoader>;

  return errorMsg || !paper || !paper?.pdfUrl ? noPaperView :
  <View style={{flexDirection: 'column', flex: 1}}>
    <View style={{flexDirection: 'row', height: '100%'}}>
      {settings.showNotes && <View style={{
        flex: 1, flexDirection: 'column',
        maxWidth: 200,
        width: '30%',
        backgroundColor: theme.colors.background,
        borderRightWidth: 1,
        borderColor: theme.colors.border,
      }}>
        {toolbar}
        <HighlightList
          highlights={highlights}
          currentHighlight={currentHighlight}
          setCurrentHighlight={setCurrentHighlight}
          updateHighlights={updateHighlights}
        />
      </View>}
      <View style={{
        flex: 1,
      }}>
        {pdfLoader}
      </View>
    </View>
  </View>;
};

export default PdfViewer;
