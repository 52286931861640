import {isLoggedIn, uid, UnauthenticatedActionError} from './utils';
import {db, auth, firestore} from '../../platform/firebase';

export type UserInfo = {
  name: string;
  affliation?: string;
  website?: string;
};

export default {
  // changeName: async (newName: string): Promise<void> => {
  //   const uid = auth?.currentUser?.uid;
  //   if (!uid) throw UnauthenticatedActionError;
  //   await db?.ref('userDetails').child(uid).child('name').set(newName);
  // },
  getUserInfo: async (uid: string): Promise<UserInfo> => {
    try {
      // const snapshot = await db?.ref('userDetails').child(uid).once('value');
      // return snapshot?.val() as UserInfo;
      const snapshot = await firestore?.collection('UserDetails')
          .doc(uid).get();
      return snapshot?.data() as UserInfo;
    } catch (e) {
      return {
        name: 'Anonymous',
      } as UserInfo;
    }
  },
  saveUserInfo: async (userInfo: UserInfo): Promise<void> => {
    if (!isLoggedIn()) throw UnauthenticatedActionError;
    // await db?.ref('userDetails').child(uid()).set(userInfo);
    await firestore?.collection('UserDetails').doc(uid())
        .set(userInfo);
  },
  isAdmin: async () => {
    if (!isLoggedIn()) return Promise.resolve(false);
    const snapshot = await firestore?.collection('Roles').doc(uid()).get();
    const roles = snapshot?.data();
    console.log(roles);
    return roles?.admin;
  },
  deleteAccount: async (): Promise<void> => {
    if (!isLoggedIn()) throw UnauthenticatedActionError;
    const userId = uid();
    await db?.ref('userDetails').child(userId).remove();
    await db?.ref(`users/${userId}`).remove();
    await auth?.currentUser?.delete();
  },
};
