import {useTheme} from '@rneui/themed';
import React, {useContext, useEffect} from 'react';
import {View} from 'react-native';
import {AppContext} from '../common/context';
import Paper from '../common/paper';
import {SortType} from '../common/store';
import {analytics} from '../platform/firebase';
import {Text} from '@rneui/themed';
import WebView from 'react-native-webview';
import {useRecoilValue} from 'recoil';
import {currentPaperState} from '../recoil/atoms/papers';

type PaperViewPropsType = {
  paper: Paper;
};

const HTMLPaperView = ({paper}: PaperViewPropsType) => {
  const {
    onPaperOrderChanged,
    actions: {savePaperAndSync},
  } = useContext(AppContext);

  useEffect(() => {
    if ((paper.dateOpened || 0) < Date.now() - 5000) {
      const _paper = {
        ...paper,
        dateOpened: Date.now(),
      };
      savePaperAndSync(_paper);
    }
    analytics?.logEvent('read_paper_html');

    return () => {
      onPaperOrderChanged(SortType.ByDateOpened);
    };
  }, [paper]);

  return (
    <View testID="html_viewer_container" style={{flex: 1}}>
      {paper && (
        paper?.htmlUrl ? (
          <WebView source={{uri: paper.htmlUrl}} />
        ) : (
          <Text style={{padding: 16, textAlign: 'center'}}>
            Could not retrieve the paper. Please try again later.
          </Text>
        )
      )}
    </View>
  );
};

export const HTMLPaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  const {theme} = useTheme();
  return (
    <View
      style={{height: '100%', backgroundColor: theme.colors?.background}}
    >
      {currentPaper && <HTMLPaperView paper={currentPaper} />}
    </View>
  );
};

export default HTMLPaperViewScreen;

