import React, {useEffect, useState} from 'react';
import {Pressable, RefreshControl, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import Api from '../common/api';
import Collection from '../common/collection';
import {ListItem, useTheme} from '@rneui/themed';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {MainStackParamList} from '../Main';
import {useSetRecoilState} from 'recoil';
import {currentCollectionState} from '../recoil/atoms';

const PublicCollections = (
    {onChange}: {onChange?: (c: Collection) => void},
) => {
  const [collections, setCollections] = useState<Collection[]>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const {theme} = useTheme();
  const setCurrentCollection = useSetRecoilState(currentCollectionState);
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();


  const loadPublicCollections = async () => {
    setRefreshing(true);
    const cs = await Api.Collection.getPublicCollections(10);
    console.log('cs', cs);
    setCollections(cs);
    setRefreshing(false);
  };

  useEffect(() => {
    loadPublicCollections();
  }, []);

  return <View style={{flex: 1}}>
    <FlatList
      style={{flex: 1}}
      data={collections}
      renderItem={({item: c}) => <Pressable
        onPress={async () => {
          const papers = await Api.Collection.getPublicCollectionPapers(c.key);
          if (papers) {
            c.paperIds = papers.map((p) => p.id);
            c.papers = Object.fromEntries(papers.map((p) => [p.id, p]));
          }
          setCurrentCollection(c);
          onChange && onChange(c);
          navigation.navigate('PaperList');
        }}>{
          ({pressed}) => <ListItem containerStyle={{
            backgroundColor: pressed ? theme.colors?.backgroundHighlight :
              theme.colors?.white,
            borderBottomColor: theme.colors?.border,
            borderBottomWidth: 1,
          }}>
            <ListItem.Content>
              <ListItem.Title>{c.name}</ListItem.Title>
              <ListItem.Subtitle>
                {c.publicInfo?.numPapers} papers
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>}</Pressable>}
      refreshControl={<RefreshControl
        refreshing={refreshing}
        onRefresh={() => {
          loadPublicCollections();
        }}
        tintColor={theme.colors.black} />}
    />
  </View>;
};

export default PublicCollections;
