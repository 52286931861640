import React, {useContext} from 'react';
import {
  View,
} from 'react-native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {
  ItemKind,
  PreferenceItemProps,
  PreferencesScreen,
} from './Preferences';
import {AppContext} from '../common/context';
import {
  IconClear,
  IconCollection,
  IconHome,
  IconInfo,
  IconTag,
} from '../platform/icons';
import {uniqBy} from 'lodash';
import {createNewTag, tagCompareFn} from '../common/tag';
import Main from './PaperInfo/Main';
import Metadata from './PaperInfo/Metadata';
import {MainStackParamList} from '../Main';
import {StackNavigationProp} from '@react-navigation/stack';
import {useNavigation} from '@react-navigation/native';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  allCollectionsState,
  allTagsState,
  currentPaperState,
} from '../recoil/atoms';
import Paper, {appendTags, removeTag} from '../common/paper';
import {isPaperInCollection} from '../common/collection';
import {layoutState} from '../recoil/selectors';

// const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

// const Citations = () => {
//   const {currentPaper: paper} = useContext(AppContext);
//   return (
//     paper && (
//       <PreferencesScreen
//         items={() =>
//           paper.citations?.map((r) => ({
//             id: r.title,
//             title: r.title,
//             subTitle: r.authors.join(', '),
//           })) || []
//         }
//       />
//     )
//   );
// };

// const References = () => {
//   const {currentPaper: paper} = useContext(AppContext);
//   return (
//     paper && (
//       <PreferencesScreen
//         items={() =>
//           paper.references?.map((r) => ({
//             id: r.title,
//             title: r.title,
//             subTitle: r.authors.join(', '),
//           })) || []
//         }
//       />
//     )
//   );
// };

const Tags = () => {
  const {
    actions: {savePaperAndSync},
  } = useContext(AppContext);
  const [paper, setCurrentPaper] = useRecoilState(currentPaperState);
  const allTags = useRecoilValue(allTagsState);

  return (
    <PreferencesScreen
      padItem={false}
      sections={[
        {
          id: 'clear',
          items: () => [
            {
              id: 'clear',
              kind: ItemKind.Button,
              title: 'Clear',
              icon: <IconClear />,
              onPress: async () => {
                if (paper) {
                  setCurrentPaper({
                    ...paper,
                    tags: [],
                  });
                  await savePaperAndSync(paper);
                }
              },
            },
          ],
        },
        {
          id: 'main',
          items: () => [
            ...uniqBy(
                [
                  ...Object.values(allTags).sort(tagCompareFn),
                  ...(paper?.tags.map((key) => createNewTag({key})) || []),
                ],
                (t) => t.key,
            ).map(
                (tag) =>
                  ({
                    id: tag.key,
                    kind: ItemKind.CheckBox,
                    title: tag.key,
                    value: !!(paper && paper.id) &&
                      paper.tags.includes(tag.key),
                    disabled: tag.key.includes(':'),
                    onChange: (_val) => {
                      if (!paper) return;
                      const newPaper: Paper = {...paper};
                      if (paper && paper.id) {
                        if (paper.tags.includes(tag.key)) {
                          newPaper.tags = removeTag(
                              newPaper.tags || [], tag.key);
                        } else {
                          newPaper.tags = appendTags(newPaper.tags, [tag.key]);
                        }
                        savePaperAndSync(newPaper);
                        setCurrentPaper(newPaper);
                      }
                    },
                  } as PreferenceItemProps),
            ),
          ]}]}
    />
  );
};

const Collections = () => {
  const {
    actions,
  } = useContext(AppContext);
  const paper = useRecoilValue(currentPaperState);
  const allCollections = useRecoilValue(allCollectionsState);
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList>>();
  return (
    <PreferencesScreen
      padItem={false}
      sections={[
        {
          id: 'manage',
          items: () => [
            {
              id: 'manage_collections',
              title: 'Manage Collections',
              icon: <IconCollection />,
              onPress: () => {
                navigation.navigate('Preferences', {screen: 'Collections'});
              },
            },
          ],
        },
        {
          id: 'main',
          items: () => [
            ...(allCollections.length > 0 ? [...allCollections]
                .sort((a, b) => a.order - b.order)
                .map((c) => ({
                  id: c.name,
                  kind: ItemKind.CheckBox,
                  title: c.name,
                  value: paper && isPaperInCollection(paper.id, c),
                  onPress: () => {
                    if (paper) {
                      if (isPaperInCollection(paper.id, c)) {
                        actions.removePaperFromCollection(paper, c);
                      } else {
                        actions.addPaperToCollection(paper, c);
                      }
                    }
                  },
                })) : []
            ),
          ]}]}
    />
  );
};

const PaperInfo = (): JSX.Element => {
  const paper = useRecoilValue(currentPaperState);
  const layout = useRecoilValue(layoutState);

  return paper ? (
    <View style={{flex: 1}}>
      <Tab.Navigator screenOptions={{
        headerShown: false,
        headerStyle: {
          height: paper && layout !== 'one-column' ? 48 : 0,
        },
        headerBackgroundContainerStyle: {
          borderWidth: 1,
        },
        headerTitleContainerStyle: {
          flex: 1000,
          padding: 0,
        },
      }}>
        <Tab.Screen
          key="PaperInfoMain"
          name="Overview"
          component={Main}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconHome color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Metadata"
          name="Metadata"
          component={Metadata}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconInfo color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Collections"
          name="Collections"
          component={Collections}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconCollection color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Tags"
          name="Tags"
          component={Tags}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconTag color={color} size={size} />
            ),
          }}
        />
      </Tab.Navigator>
    </View>
  ) : (
    <></>
  );
};

export default PaperInfo;
