import React, {useContext} from 'react';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {View, Pressable} from 'react-native';
import {AppContext} from '../../common/context';
import {MainStackParamList} from '../../Main';
import {toastSuccess} from '../../platform/toast';
import {PreferencesScreen, ItemKind} from '../Preferences';
import {
  IconAddLibrary,
  IconAlias,
  IconDelete,
  IconShare,
  IconAddCollection,
  IconRemoveCollection,
  IconPdf,
  IconHtml,
  IconUpload,
  IconDownload,
  IconClear,
  IconProps,
} from '../../platform/icons';
import Api from '../../common/api';
import {Text, useTheme} from '@rneui/themed';
import {isWeb} from '../../platform';
import {dialog} from '../../platform/dialog';
import {analytics} from '../../platform/firebase';
import {useRecoilState, useRecoilValue} from 'recoil';
import {currentPaperState} from '../../recoil/atoms/papers';
import {isPaperInCollection} from '../../common/collection';
import {currentCollectionState} from '../../recoil/atoms';
import ActivityIndicator from '../ActivityIndicator';
import {isPhoneState} from '../../recoil/selectors';

const SimpleButton = ({testID, title, onPress, icon, disabled = false}: {
  testID?: string;
  title: string,
  onPress?: () => void,
  icon: (props: IconProps) => React.ReactNode,
  disabled?: boolean,
}) => {
  const {theme} = useTheme();
  return <Pressable
    testID={testID}
    onPress={onPress} style={{flex: 1}} disabled={disabled}>
    {({pressed}) => <View style={{
      padding: 12,
      backgroundColor: pressed ?
      theme.colors.backgroundHighlight : theme.colors.white}}>
      {icon({
        size: 24,
        color: disabled ? theme.colors.disabled : theme.colors.black,
      })}
      <Text style={{
        width: '100%',
        textAlign: 'center',
        paddingTop: 8,
        color: disabled ? theme.colors.disabled :
        theme.colors.black}}>{title}</Text>
    </View>}
  </Pressable>;
};

const Main = () => {
  const {
    actions,
    actions: {savePaperAndSync, share, download},
  } = useContext(AppContext);
  const [paper, setPaper] = useRecoilState(currentPaperState);
  const currentCollection = useRecoilValue(currentCollectionState);
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList, 'PaperInfo'>>();
  const isPhone = useRecoilValue(isPhoneState);

  // const summarize = (items?: string[], maxItems = 3) => {
  //   if (!items || items.length === 0) return 'None';
  //   const displayItems =
  //     items.length > maxItems ? items.slice(0, maxItems) : items;
  //   return (
  //     displayItems.join(', ') +
  //     (items.length > maxItems ?
  //       `, and ${items.length - maxItems} other` : '')
  //   );
  // };

  return paper ? (
    <View style={{height: '100%'}}>
      <PreferencesScreen
        testID="pref_paper_info"
        padItem={false}
        sections={[
          {
            id: 'info',
            title: '',
            items: () => [
              {
                id: 'title',
                title: 'Header',
                kind: ItemKind.Custom,
                pressable: false,
                component: (
                  <View
                    style={{
                      alignContent: 'center',
                      justifyContent: 'center',
                      padding: 16,
                      flex: 1,
                    }}
                  >
                    <Text
                      h4={true}
                      style={{marginBottom: 8, textAlign: 'center'}}
                    >
                      {paper?.title}
                    </Text>
                    <Text style={{textAlign: 'center'}}>
                      {paper.authorFull}
                    </Text>
                  </View>
                ),
              },
              {
                id: 'read',
                kind: ItemKind.Custom,
                component: <View style={{width: '100%', flexDirection: 'row'}}>
                  {!paper.inLibrary && <SimpleButton
                    testID="btn_add_to_library"
                    title="Library"
                    onPress={() => {
                      if (paper) {
                        actions.addPaperToLibrary(paper);
                      }
                    }} icon={IconAddLibrary} />}
                  <SimpleButton
                    testID='btn_pdf' title="PDF" onPress={() => {
                      navigation.navigate('PaperView');
                    }} icon={IconPdf} />
                  <SimpleButton
                    testID='btn_html'
                    title="HTML"
                    disabled={!paper.htmlUrl}
                    onPress={() => {
                      navigation.navigate('HTMLPaperView');
                    }}
                    icon={IconHtml} />
                  {!isWeb &&
                    <SimpleButton title="Share" icon={IconShare}
                      onPress={share} />}
                  {!isWeb && paper.inLibrary &&
                    paper.pdfUrl &&
                    <SimpleButton
                      title="Download"
                      disabled={paper.downloading || !!paper.pdfPath}
                      icon={paper.downloading ?
                        ActivityIndicator : IconDownload}
                      onPress={() => download(paper)} />}
                </View>,
              },
              {
                id: 'TL;DR',
                kind: ItemKind.Input,
                editable: false,
                hidden: !paper.tldr,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    TL;DR
                  </Text>
                ),
                value: paper.tldr,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'abstract',
                kind: ItemKind.Input,
                hidden: !paper.abstract,
                editable: false,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    Abstract
                  </Text>
                ),
                value: paper.abstract,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'notes',
                kind: ItemKind.Input,
                hidden: !paper.note,
                editable: false,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    Notes
                  </Text>
                ),
                value: paper.note,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'alias',
                kind: ItemKind.Input,
                title: 'Alias',
                value: paper.alias,
                icon: <IconAlias />,
                inline: true,
                onChange: (val) => {
                  if (paper) {
                    const newPaper = {
                      ...paper,
                      alias: val as string,
                    };
                    setPaper(newPaper);
                    analytics?.logEvent('set_paper_alias');
                    savePaperAndSync(newPaper);
                  }
                },
              },
              // {
              //   id: 'collections',
              //   title: 'Collections',
              //   subTitle: summarize(
              //       allCollections
              //           .filter((c) => paper && paper.id && c.has(paper.id))
              //           .map((c) => c.name),
              //   ),
              //   route: 'Collections',
              //   icon: <IconCollection />,
              // },
              // {
              //   id: 'tags',
              //   title: 'Tags',
              //   subTitle: summarize(paper?.tags),
              //   route: 'Tags',
              //   icon: <IconTag />,
              // },
            ],
          },
          {
            id: 'actions',
            title: '',
            items: () => [
              {
                id: 'remove_from_collection',
                kind: ItemKind.Button,
                hidden: !currentCollection ||
                  !isPaperInCollection(paper.id, currentCollection) ||
                  currentCollection.isPublic,
                title: 'Remove from ' + currentCollection?.name,
                icon: <IconRemoveCollection />,
                onPress: () => {
                  if (paper && currentCollection) {
                    actions.removePaperFromCollection(
                        paper, currentCollection);
                  }
                },
              },
              ...(currentCollection ?
                (isPaperInCollection(paper.id, currentCollection) ?
                [] :
                [
                  {
                    id: 'add_to_collection',
                    kind: ItemKind.Button,
                    title: 'Add to ' + currentCollection.name,
                    icon: <IconAddCollection />,
                    onPress: () => {
                      if (paper && currentCollection) {
                        actions.addPaperToCollection(paper, currentCollection);
                      }
                    },
                  },
                ]) : []),
              ...(paper.inLibrary ?
                [
                  {
                    id: 'share_pdf',
                    kind: ItemKind.Button,
                    title: 'Share PDF',
                    icon: <IconShare />,
                    hidden: !paper.pdfPath || !isPhone,
                    onPress: async () => {
                      if (!paper) return;
                      await actions.sharePdf();
                    },
                  },
                  {
                    id: 'remove_from_downloaded',
                    kind: ItemKind.Button,
                    title: 'Remove Downloaded PDF',
                    icon: <IconClear />,
                    hidden: !paper.pdfPath || !isPhone,
                    onPress: async () => {
                      if (!paper) return;
                      await actions.removePdf(paper);
                    },
                  },
                  {
                    id: 'delete',
                    kind: ItemKind.Button,
                    title: 'Delete',
                    hidden: !paper?.inLibrary,
                    onPress: () => {
                      dialog(
                          'Delete',
                          'Do you want to remove this paper from the library?',
                          [
                            {
                              text: 'Delete',
                              style: 'destructive',
                              onPress: () => {
                                if (!paper) return;
                                actions
                                    .removePaperFromLibrary(paper)
                                    .then(() => {
                                      toastSuccess(
                                          'Paper removed from the library.',
                                      );
                                      if (!isWeb) navigation.popToTop();
                                    });
                              },
                            },
                            {text: 'Cancel'},
                          ],
                      );
                    },
                    icon: <IconDelete />,
                  },
                ] :
                []),
              {
                id: 'update_public',
                title: 'Update Public Paper Record',
                adminOnly: true,
                onPress: async () => {
                  await Api.Paper.updatePublicPaperRecord(paper);
                  dialog('Done.');
                },
                icon: <IconUpload />,
              },
            ],
          },
        ]}
      />
    </View>
  ) : (
    <Text>No paper selected</Text>
  );
};

export default Main;
