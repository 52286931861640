import {pick} from 'lodash';
import {uuid} from '../platform/misc';

export type CollectionProps = {
  key: string;
  name: string;
  paperIds: string[];
  papers: Record<string, unknown>;
  hidden: boolean;
  icon: string;
};

type CollectionPublicInfo = {
  createdBy: string;
  updatedAt: number;
  numPapers: number;
}

/**
 * Collection class
 */
type Collection = {
  key: string;
  name: string;
  description: string;
  paperIds: string[];
  papers: Record<string, unknown>;
  hidden: boolean;
  starred: boolean;
  icon: string;
  isPublic: boolean;
  publicInfo?: CollectionPublicInfo;
  publishedAt?: number;
  updatedAt?: number;
  order: number;
  dateModified: number;
}

/**
 * Create an empty collection
 * @param props - props
 * @returns collection type object
 */
export function createNewCollection(
    props?: Record<string, unknown>): Collection {
  return {
    key: uuid(),
    name: 'New Collection',
    description: '',
    paperIds: [],
    papers: {},
    hidden: false,
    starred: false,
    icon: 'none',
    isPublic: false,
    order: 1000,
    ...props || {},
    dateModified: 0,
  } as Collection;
}

/**
 * Check if a paper is included in this collection
 * @param id - paper id
 * @returns true if the paper is included
 */
export function isPaperInCollection(pid: string, c: Collection): boolean {
  return c.paperIds.includes(pid);
}

/**
 * Add a paper to the collection
 * @param id  - paper id
 */
export function addPaperToCollection(pid: string, c: Collection): Collection {
  return {
    ...c,
    paperIds: [...new Set([...c.paperIds, pid])],
  };
}

/**
 * Remove a paper from the collection
 * @param pid - paper id
 */
export function removePaperFromCollection(
    pid: string, c: Collection): Collection {
  return {
    ...c,
    paperIds: c.paperIds.filter((id) => id !== pid),
  };
}

/**
 * Get minimal json to serialize the collection
 * @returns key-value pairs of collection attributes
 */
export function getCollectionJSON(c: Collection): Record<string, unknown> {
  return pick(c, [
    'key',
    'name',
    'papers',
    'paperIds',
    'hidden',
    'starred',
    'icon',
    'description',
    'isPublic',
    'publishedAt',
    'updatedAt',
    'createdBy',
    'order',
    'dateModified',
  ]);
}

/**
 * Return a list of Collection objects from fields
 * @param collections - key-value pairs of collection id and collection
 *    attributes
 */
export function getCollectionsFromObject(
    collections: Record<string, unknown>,
): Collection[] {
  return Object.entries(collections).map(
      ([key, collection]) =>
        ({
          ...(collection as Collection),
          key,
        } as Collection),
  );
}

/**
 * Merge two collections
 * @param c1 - collection 1
 * @param c2 - collection 2
 * @returns - merged collection
 */
export function mergeCollection(
    c1: Collection | null, c2: Collection | null): Collection {
  return {
    ...createNewCollection(),
    ...c1 || {},
    ...c2 || {},
  } as Collection;
}

export default Collection;
