// import categories from '../arxiv_categories.json';
import {SourcePaper, Source, PaperQuery} from './base';
import {firestore} from '../../platform/firebase';
import {Author} from '../paper';


export type PaperShelfPaper = SourcePaper & {
  alias: string;
  tldr: string;
  title: string;
  authors: Author[];
  year: string;
  venue: string;
  numCitations: number;
  numReferences: number;
};

export const PaperShelf: Source = {
  source: 'PaperShelf',
  canSearch: false,
  search: async () => [],
  fetch: async (paperQuery: PaperQuery) => {
    if (!paperQuery.id) throw Error('Paper not found.');
    const doc = await firestore?.collection('Papers').doc(paperQuery.id).get();
    if (!doc?.exists) throw Error('Paper not found.');
    return doc?.data() as PaperShelfPaper;
  },
};
