import React, {useContext, useState} from 'react';
import {
  View,
} from 'react-native';
import {AppContext} from '../common/context';
import {
  IconDownload,
  IconNote,
  IconNoteOff,
  IconShare,
} from '../platform/icons';
import {Tooltip, TooltipProps, useTheme} from '@rneui/themed';
import {useRecoilState, useRecoilValue} from 'recoil';
import {isWebState} from '../recoil/selectors';
import {currentPaperState, settingsState} from '../recoil/atoms';
import {HeaderMenuItem} from './HeaderMenu';
import ActivityIndicator from './ActivityIndicator';

const PdfHeaderMenu: React.FC<TooltipProps & {children: React.ReactNode}> =
(props) => {
  const {actions} = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const {theme} = useTheme();
  const isWeb = useRecoilValue(isWebState);
  const paper = useRecoilValue(currentPaperState);
  const [settings, setSettings] = useRecoilState(settingsState);

  const close = () => {
    setOpen(false);
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const menuItems = [
    <HeaderMenuItem
      key="notes"
      icon={settings.showNotes ? IconNoteOff : IconNote}
      title={settings.showNotes ? 'Hide Notes' : 'Show Notes'}
      onPress={() => {
        close();
        setSettings({...settings, showNotes: !settings.showNotes});
        // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }} />,
  ];

  if (!isWeb) {
    if (!paper?.pdfPath) {
      menuItems.push(<HeaderMenuItem
        key="download"
        icon={paper?.downloading ? ActivityIndicator : IconDownload}
        disabled={paper?.downloading}
        title="Download"
        onPress={async () => {
          close();
          if (!paper) return;
          await actions.download(paper);
        }}
      />);
    }

    menuItems.push(<HeaderMenuItem
      key="share"
      icon={IconShare}
      title="Share Link"
      onPress={async () => {
        close();
        await new Promise((r) => setTimeout(r, 100));
        await actions.share();
        // TODO: close header menu before share dialog appears
      }}
    />);

    if (paper?.pdfPath) {
      menuItems.push(<HeaderMenuItem
        key="share_pdf"
        icon={IconShare}
        title="Share PDF"
        onPress={async () => {
          close();
          await new Promise((r) => setTimeout(r, 100));
          await actions.sharePdf();
          // TODO: close header menu before share dialog appears
        }}
      />);
    }
  }

  return <Tooltip
    // ModalComponent={Modal}
    visible={open}
    onOpen={() => {
      setOpen(true);
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }}
    onClose={close}
    popover={<View style={{
      flexDirection: 'column',
      width: '100%',
    }}>
      {menuItems}
    </View>}
    width={200}
    height={48 * menuItems.length}
    withPointer={true}
    backgroundColor={theme.colors?.white}
    withOverlay={true}
    containerStyle={{
      borderColor: theme.colors.border,
      borderWidth: 1,
      borderRadius: 0,
      width: 200,
      backgroundColor: theme.colors.white,
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0,
    }}
    {...props} />;
};

export default PdfHeaderMenu;
