import 'react-native-gesture-handler';
import React, {useCallback, useEffect, useState} from 'react';
import {Dimensions, Platform, PlatformIOSStatic, View} from 'react-native';
import {RootSiblingParent} from 'react-native-root-siblings';
import {
  ThemeProvider, createTheme, lightColors, darkColors,
} from '@rneui/themed';

import * as Sentry from 'sentry-expo';
import * as SplashScreen from 'expo-splash-screen';
import ContextProvider from './src/common/ContextProvider';
import Navigation from './src/Navigation';
import {analytics} from './src/platform/firebase';
import Modal from './src/components/Modal';
import {DarkTheme, DefaultTheme} from '@react-navigation/native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {defaultSettings, Settings} from './src/common/store';
import {localStorageGetItem} from './src/platform/localStorage';
import yaml from 'js-yaml';
import _ from 'lodash';
import {Sources} from './src/common/sources';
import Api from './src/common/api';
import {isWeb} from './src/platform';
import {RecoilRoot, useRecoilState, useRecoilValue} from 'recoil';
import {settingsState} from './src/recoil/atoms/settings';
import { isModalVisibleState, modalContentState, modalOptionsState } from './src/recoil/atoms/modal';
import { isReadyState } from './src/recoil/atoms';


Sentry.init({
  dsn: 'https://e39abe5bdcb74a1ba6b3005c505fa8d4@o1005744.ingest.sentry.io/5966279',
  enableInExpoDevelopment: false,
  debug: false, // Sentry will try to print out useful debugging information
  // if something goes wrong with sending an event. Set this to `false` in
  // production.
});

if (!__DEV__) {
  console.log = () => {/**/};
}

// Keep the splash screen visible while we fetch resources
// SplashScreen.preventAutoHideAsync();

declare module '@rneui/themed' {
  export interface Colors {
    readonly background: string;
    readonly backgroundHighlight: string;
    readonly backgroundModal: string;
    readonly border: string;
    readonly header: string;
    readonly realWhite: string;

  }

  export interface FullTheme {
    colors: Colors;
  }
}

/**
 * App
 */
const AppContent = (): JSX.Element => {
  // TODO: make this work for other platforms
  // const isNarrowScreen = !(Platform as PlatformIOSStatic).isPad &&
  //   Platform.OS !== 'web';
  const isMac = Dimensions.get('window').width > 1800;
  /*
  const onDimensionChanged = ({
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => setIsNarrowScreen(screen.width < 500 || screen.height > screen.width);

  // Adjust dimensions
  useEffect(() => {
    const screen = Dimensions.get('screen');
    setIsNarrowScreen(screen.width < 500 || screen.height > screen.width);
    Dimensions.addEventListener('change', onDimensionChanged);
    return () => Dimensions.removeEventListener('change', onDimensionChanged);
  }, []);
  */

  const loadSettings = async () => {
    const s = await Api.Settings.load();
    setSettings(s);
  };

  useEffect(() => {
    if (!isWeb) {
      analytics?.setAnalyticsCollectionEnabled(!__DEV__);
    }
    loadSettings();
  }, []);

  const getTheme = () => {
    return createTheme({
      lightColors: {
        white: '#fff',
        background: 'rgb(242, 242, 247)',
        header: lightColors.white,
        backgroundHighlight: 'rgb(242, 242, 247)',
        backgroundModal: 'rgb(255, 255, 255)',
        border: '#dadada',
        ...Platform.select({
          default: lightColors.platform.android,
          ios: lightColors.platform.ios,
        }),
        primary: '#0275d8',
        warning: '#f0ad4e',
        disabled: '#999999',
        success: '#5cb85c',
        realWhite: '#fff',
      },
      darkColors: {
        background: 'rgb(37, 37, 38)',
        backgroundHighlight: 'rgb(43, 45, 46)',
        backgroundModal: 'rgb(50, 50, 50)',
        border: 'rgb(50, 50, 50)',
        white: 'rgb(30, 30, 30)',
        header: 'rgb(30, 30, 30)',
        ...Platform.select({
          default: darkColors.platform.android,
          ios: darkColors.platform.ios,
        }),
        primary: '#007AFF',
        warning: '#FF9500',
        realWhite: '#fff',
      },
      components: Object.assign(
          {
            Badge: {
              badgeStyle: {
                height: 28,
                padding: 0,
                paddingHorizontal: 4,
                borderWidth: 0,
              },
              textStyle: {
                fontSize: 12,
              },
            },
          },
        isMac ? {
          Text: {
            style: {
              fontSize: 20,
            },
          },
          ListItemTitle: {
            style: {
              fontSize: 20,
            },
          },
          ListItemSubtitle: {
            style: {
              fontSize: 18,
            },
          },
        } : {},
      ),
    });
  };

  const getNavigationTheme = (darkMode: boolean) => ({
    ...(darkMode ? DarkTheme : DefaultTheme),
  });

  const [settings, setSettings] = useRecoilState(settingsState);
  const [isModalVisible, setIsModalVisible] =
    useRecoilState(isModalVisibleState);
  const modalContent = useRecoilValue(modalContentState);
  const modalOptions = useRecoilValue(modalOptionsState);
  const isReady = useRecoilValue(isReadyState);

  return (Object.keys(settings).length > 0 ?
      <ContextProvider
        render={(darkMode) => {
          const onLayoutRootView = useCallback(async () => {
            if (isReady) {
            // This tells the splash screen to hide immediately! If we call
            // this after `setAppIsReady`, then we may see a blank screen while
            // the app is loading its initial state and rendering its first
            // pixels. So instead, we hide the splash screen once we know the
            // root view has already performed layout.
              await SplashScreen.hideAsync();
            }
          }, [isReady]);
          return (
            <SafeAreaProvider>
              <View
                style={{flex: 1, height: 500}}
                onLayout={onLayoutRootView}
              >
                <ThemeProvider theme={getTheme()}>
                  <RootSiblingParent>
                    <Navigation
                      modal={<Modal
                        visible={isModalVisible}
                        setVisible={setIsModalVisible}
                        content={modalContent}
                        options={modalOptions} />}
                      theme={getNavigationTheme(darkMode)} />
                  </RootSiblingParent>
                </ThemeProvider>
              </View>
            </SafeAreaProvider>
          );
        }}
      /> : <></>
  );
}

const App = () => {
  return <RecoilRoot>
    <AppContent />
  </RecoilRoot>;
}

export default App;