import React, {useContext} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Main from './Main';
import {General, GeneralLayout, GeneralSortBy, GeneralTheme} from './General';
import {Button, Pressable} from 'react-native';
import {
  Collections,
} from './Collections';
import {
  PapersSearchSources, PapersFetchSources, Papers, PapersPdfReader,
} from './Papers';
import {Account} from './Account';
import {AppContext} from '../../common/context';
import {IconClose} from '../../platform/icons';
import {useTheme} from '@rneui/themed';
import {CollectionDetails} from './CollectionDetails';
import {Tags} from './Tags';
import {TagDetails, TagIconScreen, TagStyle} from './TagDetails';
import {useRecoilValue} from 'recoil';
import {isWebState, useModalState} from '../../recoil/selectors';

export type SettingsStackParamList = {
  Main: undefined;
  General: undefined;
  GeneralTheme: undefined;
  GeneralLayout: undefined;
  PapersSearchSources: undefined;
  PapersFetchSources: undefined;
  PapersPdfReader: undefined;
  Papers: undefined;
  Account: undefined;
  SortBy: undefined;
  Collections: undefined;
  CollectionDetails: { collectionKey?: string };
  Tags: undefined;
  TagDetails: { tag: string };
  TagStyle: { tag: string };
  TagIcon: { tag: string };
};

const Stack = createStackNavigator<SettingsStackParamList>();

const SettingsNavigator = ({
  initialRouteName,
}: {
  initialRouteName?: keyof SettingsStackParamList;
}): JSX.Element => {
  const {closeModal} = useContext(AppContext);
  const isWeb = useRecoilValue(isWebState);
  const useModal = useRecoilValue(useModalState);
  const {theme} = useTheme();
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName || 'Main'}
      screenOptions={{
        headerBackTitle: 'Back',
        animationEnabled: false,
        headerStatusBarHeight: 0,
        headerRight: () => useModal && isWeb ?
        <Pressable
          testID='btn_close'
          onPress={closeModal}
          style={{marginRight: 16}}>
          <IconClose />
        </Pressable> :
        (useModal ? <Button title="Close" onPress={closeModal} /> : null),
        headerStyle: {
          borderBottomColor: theme.colors?.border,
          borderBottomWidth: 1,
          backgroundColor: theme.colors?.white,
        },
      }}
    >
      <Stack.Screen
        key="Main"
        name="Main"
        component={Main}
        options={{
          headerBackTitle: 'Back',
          title: 'Preferences',
        }}
      />
      <Stack.Screen key="General" name="General" component={General} />
      <Stack.Screen
        key="GeneralTheme"
        name="GeneralTheme"
        component={GeneralTheme}
        options={{
          headerTitle: 'Theme',
        }}
      />
      <Stack.Screen
        key="GeneralLayout"
        name="GeneralLayout"
        component={GeneralLayout}
        options={{
          headerTitle: 'Layout',
        }}
      />
      <Stack.Screen
        key="PapersSearchSources"
        name="PapersSearchSources"
        component={PapersSearchSources}
        options={{
          headerTitle: 'Search Sources',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="PapersFetchSources"
        name="PapersFetchSources"
        component={PapersFetchSources}
        options={{
          headerTitle: 'Metadata Sources',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="Papers"
        name="Papers"
        component={Papers}
        options={{
          headerTitle: 'Papers',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="PapersPdfReader"
        name="PapersPdfReader"
        component={PapersPdfReader}
        options={{
          headerTitle: 'Reader',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="Account"
        name="Account"
        component={Account}
        options={{
          headerTitle: 'Account',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="SortBy"
        name="SortBy"
        component={GeneralSortBy}
        options={{
          headerTitle: 'Sort By',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="Collections"
        name="Collections"
        component={Collections}
        options={{
          headerTitle: 'Collections',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="CollectionDetails"
        name="CollectionDetails"
        component={CollectionDetails}
        options={{
          headerTitle: 'Collection Details',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="Tags"
        name="Tags"
        component={Tags}
        options={{
          headerTitle: 'Tags',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="TagDetails"
        name="TagDetails"
        component={TagDetails}
        options={{
          headerTitle: 'Tag Details',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="TagStyle"
        name="TagStyle"
        component={TagStyle}
        options={{
          headerTitle: 'Tag Style',
          headerBackTitle: 'Back',
        }}
      />
      <Stack.Screen
        key="TagIcon"
        name="TagIcon"
        component={TagIconScreen}
        options={{
          headerTitle: 'Tag Icon',
          headerBackTitle: 'Back',
        }}
      />
    </Stack.Navigator>
  );
};

export default SettingsNavigator;
