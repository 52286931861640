import {
  DarkTheme, DefaultTheme, NavigationContainer, useNavigation,
} from '@react-navigation/native';
import React, {useContext, useState} from 'react';
import {
  Pressable,
  View,
} from 'react-native';
import {AppContext} from '../common/context';
import {MainStackParamList} from '../Main';
import {
  IconRefresh,
  IconProps,
  IconAccount,
  IconLogin,
} from '../platform/icons';
import {Text, Tooltip, TooltipProps, useTheme} from '@rneui/themed';
import {useRecoilValue} from 'recoil';
import {useModalState} from '../recoil/selectors';
import {Account} from '../screens/Settings/Account';
import {StackNavigationProp} from '@react-navigation/stack';
import {isLoggedIn} from '../common/api/utils';

export const HeaderMenuItem = ({
  title,
  icon,
  testID,
  onPress,
  disabled,
  noBottomBorder = false,
}: {
  title: string,
  icon: (props: IconProps) => React.ReactNode,
  testID?: string,
  onPress: () => void,
  disabled?: boolean,
  noBottomBorder?: boolean,
}) => {
  const {theme} = useTheme();
  return <Pressable
    onPress={onPress}
    testID={testID}
    disabled={disabled}
    style={{
      justifyContent: 'center',
      borderBottomWidth: noBottomBorder ? 0 : 1,
      borderBottomColor: theme.colors.border,
    }}>
    {({pressed}) => (<View style={{
      padding: 16,
      height: 48,
      flexDirection: 'row',
      backgroundColor: pressed ?
        theme.colors.backgroundHighlight : theme.colors.white}}>
      {icon && <View style={{paddingRight: 12}}>{icon({size: 16})}
      </View>}
      <Text style={{
        color: disabled ? theme.colors.disabled : theme.colors.black,
      }}>{title}</Text>
    </View>)}
  </Pressable>;
};

const HeaderMenu: React.FC<TooltipProps & {children: React.ReactNode}> =
(props) => {
  const {loadAppData, showModal} = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const {theme} = useTheme();
  const useModal = useRecoilValue(useModalState);
  const {useDark} = useContext(AppContext);
  const navigation =
    useNavigation<
      StackNavigationProp<MainStackParamList>
    >();

  const close = () => {
    setOpen(false);
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const navigateToAccount = () => {
    if (useModal) {
      showModal(() => (
        <NavigationContainer
          independent={true}
          theme={useDark() ? DarkTheme : DefaultTheme}>
          <Account />
        </NavigationContainer>), {
        height: 600,
        width: 600,
        contentContainerStyle: {padding: 0},
        title: 'Account',
      });
    } else {
      navigation.navigate('Preferences', {screen: 'Account'});
    }
  };

  const popover = <View style={{flexDirection: 'column', width: '100%'}}>
    <HeaderMenuItem
      title="Refresh"
      icon={IconRefresh}
      testID="btn_refresh"
      onPress={() => {
        loadAppData();
        close();
      }}
    />
    {!isLoggedIn() ? <HeaderMenuItem
      testID="btn_go_to_login"
      title="Login"
      icon={IconLogin}
      onPress={() => {
        navigateToAccount();
        close();
      }}
    /> :
    <HeaderMenuItem
      testID="btn_go_to_account"
      title="Account"
      icon={IconAccount}
      onPress={() => {
        navigateToAccount();
        close();
      }}
    />}
  </View>;
  return <Tooltip
    // ModalComponent={Modal}
    visible={open}
    onOpen={() => {
      setOpen(true);
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }}
    onClose={close}
    popover={popover}
    width={200}
    height={48 * 2}
    withPointer={true}
    backgroundColor={theme.colors?.white}
    withOverlay={true}
    containerStyle={{
      borderColor: theme.colors.border,
      borderWidth: 1,
      borderRadius: 0,
      width: 200,
      backgroundColor: theme.colors.white,
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0,
    }}
    {...props} />;
};

export default HeaderMenu;
