import React, {useContext, useEffect, useState} from 'react';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {AppContext} from '../../common/context';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import Collection, {createNewCollection} from '../../common/collection';
import {
  IconDelete,
} from '../../platform/icons';
import Api from '../../common/api';
import moment from 'moment';
import {dialog} from '../../platform/dialog';
import {SettingsStackParamList} from './Navigator';
import {useRecoilState} from 'recoil';
import {allCollectionsState, currentCollectionState} from '../../recoil/atoms';

export const CollectionDetails = ({
  goBack,
}: {
  goBack?: () => void;
}): JSX.Element => {
  const route =
    useRoute<RouteProp<SettingsStackParamList, 'CollectionDetails'>>();
  const {
    actions,
  } = useContext(AppContext);
  const [collection, setCollection] = useState<Collection>();
  const [allCollections, setAllCollections] =
    useRecoilState(allCollectionsState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const naviation = useNavigation();

  useEffect(() => {
    if (!route.params?.collectionKey) {
      setCollection(createNewCollection({name: 'New Collection'}));
    } else {
      setCollection(
          allCollections.find((c) => c.key === route.params.collectionKey),
      );
    }
  }, [route.params]);

  const publish = () => {
    // if (collection) {
    //   if (!collection.isPublic) {
    //     dialog(
    //         'Publish Collection',
    //         'This collection is currently private. Do you want ' +
    //         'to allow everyone to view papers in this collection?',
    //         [
    //           {
    //             text: 'Publish',
    //             onPress: () => {
    //               collection.isPublic = true;
    //               Api.Collection.publish(collection, allPapers)
    //                   .then(() => toastSuccess('Collection published.'))
    //                   .catch((e) =>
    //                     toastError(
    //                         `Unable to publish this collection.
    //                                     Please try again later.`,
    //                         e,
    //                     ),
    //                   );
    //             },
    //           },
    //           {text: 'Cancel', style: 'cancel'},
    //         ],
    //     );
    //   } else {
    //     Api.Collection.publish(collection, allPapers)
    //         .then(() => toastSuccess('Collection updated.'))
    //         .catch((e) =>
    //           toastError(
    //               `Unable to update this collection.
    //                           Please try again later.`,
    //               e,
    //           ),
    //         );
    //   }
    // }
  };

  const unpublish = () => {
    // if (collection) {
    //   const unpublishFn = () => {
    //     collection.isPublic = false;
    //     Api.Collection.unpublish(collection)
    //         .then(() => toastSuccess('Collection has been unpublished.'))
    //         .catch((e) => toastError(
    //             `Unable to unpublish this collection.`, e));
    //   };
    //   dialog(
    //       'Unpublish Collection',
    //       'Do you want to remove this collection from public collections?',
    //       [
    //         {
    //           text: 'Unublish',
    //           style: 'destructive',
    //           onPress: unpublishFn,
    //         },
    //         {text: 'Cancel', style: 'cancel'},
    //       ],
    //   );
    // }
  };

  const deleteCollection = () => {
    dialog(
        'Delete Collection',
        `Do you want to delete ${collection?.name}? This cannot be undone.`,
        [
          {
            text: 'Delete',
            style: 'destructive',
            onPress: async () => {
              if (!collection) return;
              setAllCollections(
                  allCollections.filter((c) => c.key !== collection.key),
              );
              if (currentCollection?.key === collection.key) {
                setCurrentCollection(null);
              }
              // await Api.Collection.unpublish(collection);
              await actions
                  .deleteCollection(collection);
            goBack ? goBack() : naviation.goBack();
            },
          },
          {
            text: 'Cancel',
          },
        ],
    );
  };

  const changeCollection = (newCollection: Collection) => {
    if (!collection) return;
    setAllCollections(allCollections.map(
        (c) => c.key === collection.key ? newCollection : c));
    setCollection(newCollection);
    Api.Collection.save(newCollection);
  };

  return (
    <PreferencesScreen
      sections={[
        {
          id: 'general',
          title: '',
          items: () => [
            {
              id: 'key',
              kind: ItemKind.Text,
              title: 'Key',
              subTitle: collection?.key,
              devOnly: true,
              inline: true,
            },
            {
              id: 'papers',
              kind: ItemKind.Text,
              title: 'Paper Count',
              subTitle: collection?.paperIds.length.toString(),
              devOnly: true,
              inline: true,
            },
            {
              id: 'name',
              kind: ItemKind.Input,
              title: 'Name',
              value: collection?.name,
              inline: true,
              onChange: (val) => {
                changeCollection({
                  ...collection,
                  name: (val as string || ''),
                } as Collection);
              },
            },
            {
              id: 'description',
              kind: ItemKind.Input,
              title: 'Description',
              value: collection?.description,
              inline: true,
              onChange: (val) => {
                changeCollection({
                  ...collection,
                  description: (val as string || ''),
                } as Collection);
              },
            },
            {
              id: 'starred',
              kind: ItemKind.CheckBox,
              title: 'Starred',
              value: collection?.starred,
              onChange: (_val) => {
                changeCollection({
                  ...collection,
                  starred: !collection?.starred,
                } as Collection);
              },
            },
            {
              id: 'hidden',
              kind: ItemKind.CheckBox,
              title: 'Hidden',
              value: collection?.hidden,
              onChange: (_val) => {
                changeCollection({
                  ...collection,
                  hidden: !collection?.hidden,
                } as Collection);
              },
            },
          ],
        },
        {
          id: 'sharing',
          title: 'Sharing',
          hidden: true,
          items: () => [
            {
              id: 'status',
              kind: ItemKind.Input,
              title: 'Status',
              value: collection?.isPublic ?
                `Published (${moment(collection.publishedAt).calendar()})` :
                'Private',
              readonly: true,
            },
            {
              id: 'publish',
              kind: ItemKind.Button,
              title: 'Publish...',
              onPress: publish,
            },
            {
              id: 'unpublish',
              kind: ItemKind.Button,
              title: 'Unpublish...',
              hidden: !collection?.isPublic,
              onPress: unpublish,
            },
          ],
        },
        {
          id: 'actions',
          title: '',
          items: () => [
            {
              id: 'delete',
              kind: ItemKind.Button,
              icon: <IconDelete />,
              title: 'Delete Collection',
              onPress: deleteCollection,
            },
            {
              id: 'save',
              kind: ItemKind.Button,
              title: 'Save to Server',
              onPress: () => {
                if (!collection) return;
                Api.Collection.server.save(collection);
              },
            },
          ],
        },
      ]}
    />
  );
};
