import _ from 'lodash';
import {
  localStorageGetItem, localStorageSetItem,
} from '../../platform/localStorage';
import {Sources} from '../sources';
import {defaultSettings, Settings} from '../store';

/**
 * save settings to local storage
 */
async function save(settings: Settings): Promise<void> {
  console.log('save settings', settings);
  await localStorageSetItem('settings', JSON.stringify(settings));
}

/**
 * validate settings
 * @param s - settings
 * @returns
 */
async function validate(s: Settings) {
  let valid = true;
  for (const key of s.fetchPaperSources) {
    const src = Sources.find((src) => src.key === key);
    if (!src || !src.canFetch) {
      console.log('invalid fetch paper sources', s.fetchPaperSources);
      s.fetchPaperSources = defaultSettings.fetchPaperSources;
      valid = false;
    }
  }
  for (const key of s.searchPaperSources) {
    const src = Sources.find((src) => src.key === key);
    if (!src || !src.canSearch) {
      s.searchPaperSources = defaultSettings.searchPaperSources;
      valid = false;
    }
  }
  return valid;
}

/**
 * load settings from local storage
 */
async function load(): Promise<Settings> {
  const data = await localStorageGetItem('settings');
  const localSettings = data ? (JSON.parse(data) as Settings) : null;
  console.log(localSettings);
  const s = localSettings ?
      {
        ..._.cloneDeep(defaultSettings),
        ...localSettings,
      } :
      _.cloneDeep(defaultSettings);
  console.log('load settings', localSettings, s);
  // Validate settings
  if (!validate(s)) {
    await save(s);
  }
  await localStorageSetItem('settings', JSON.stringify(s));
  return s;
}

export default {
  save,
  load,
};
