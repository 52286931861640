import React, {useContext} from 'react';
import {ListItem, Text, useTheme, Badge} from '@rneui/themed';
import {
  IconBookshelf,
  IconConfig,
  IconEdit,
  IconPublicCollection,
} from '../platform/icons';
import {Pressable, View} from 'react-native';
import {
  DrawerContentComponentProps,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import {
  CompositeNavigationProp,
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  useNavigation,
} from '@react-navigation/native';
import {AppContext} from '../common/context';
import {AppStackParamList} from '../Navigation';
import {StackNavigationProp} from '@react-navigation/stack';
import {MainStackParamList} from '../Main';
import Settings from '../screens/Settings';
import {ScrollView} from 'react-native-gesture-handler';
import {ScreenContainer} from './ScreenContainer';
import {Button, Divider} from '@rneui/base';
import PublicCollections from '../screens/PublicCollections';
import {useRecoilState, useRecoilValue} from 'recoil';
import {allPapersState} from '../recoil/atoms/papers';
import {
  allCollectionsState,
  currentCollectionState,
} from '../recoil/atoms';
import {
  isWebState,
  useModalState,
} from '../recoil/selectors';

const CollectionListItem = ({
  testID,
  title,
  onPress,
  selected = false,
  icon,
  badgeText,
}: {
  testID?: string;
  title: string;
  onPress: () => void;
  selected?: boolean;
  icon?: React.ReactNode;
  badgeText?: string;
}) => {
  const {theme} = useTheme();
  return (
    <Pressable
      testID={testID}
      onPress={onPress}
    >
      {({pressed}) => (
        <ListItem
          containerStyle={{
            backgroundColor:
              selected || pressed ?
                theme.colors?.backgroundHighlight :
                theme.colors?.white,
          }}
        >
          {icon}
          <ListItem.Content>
            <View style={{
              flexDirection: 'row', width: '100%', paddingRight: 16,
              alignItems: 'center'}}>
              <ListItem.Title style={{flex: 1}} numberOfLines={1}>
                {title}
              </ListItem.Title>
              {badgeText && <View testID="collection_badge"><Badge
                containerStyle={{flex: 0}}
                badgeStyle={{height: 24, padding: 0}}
                textStyle={{fontSize: 12}}
                value={badgeText} /></View>}
            </View>
          </ListItem.Content>
        </ListItem>
      )}
    </Pressable>
  );
};

type DrawerSectionProps = {
  title: string;
  items: JSX.Element[];
};

const DrawerSection = ({title, items}: DrawerSectionProps) => {
  const {theme} = useTheme();

  return items.length > 0 ? (
    <View>
      <Text
        style={{
          color: theme.colors?.grey0,
          marginTop: 20,
          marginBottom: 8,
          marginLeft: 8,
          fontSize: 12,
        }}
      >
        {title}
      </Text>
      {items}
    </View>
  ) : (
    <></>
  );
};

const CollectionDrawer = ({
  navigation,
}: DrawerContentComponentProps): JSX.Element => {
  return (
    <ScreenContainer
      testID="collection_drawer"
      edges={['top', 'bottom', 'left']}>
      <CollectionList toggleDrawer={navigation.toggleDrawer} />
    </ScreenContainer>
  );
};

type CollectionListProps = {
  toggleDrawer: () => void;
};

export const CollectionList = ({toggleDrawer}: CollectionListProps) => {
  const {
    showModal,
  } = useContext(AppContext);
  const allPapers = useRecoilValue(allPapersState);
  const allCollections = useRecoilValue(allCollectionsState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const {theme} = useTheme();
  const {useDark} = useContext(AppContext);
  const useModal = useRecoilValue(useModalState);
  const isWeb = useRecoilValue(isWebState);
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        DrawerNavigationProp<AppStackParamList, 'Drawer'>,
        StackNavigationProp<MainStackParamList>
      >
    >();

  return (
    <View
      testID='drawer'
      style={{flex: 1, backgroundColor: theme.colors?.white}}
    >
      <View style={{
        height: isWeb ? 64 : 48,
        flexDirection: 'row',
        paddingHorizontal: 16,
        backgroundColor: theme.colors.white,
      }}>
        <Text style={{
          paddingTop: isWeb ? 20 : 8,
          fontSize: 20,
          fontWeight: 'bold',
          flex: 1,
        }}>Your Library</Text>
        <Button
          testID='btn_edit_collections'
          type='clear' icon={<IconEdit />} style={{
            paddingTop: isWeb ? 16 : 4,
          }}
          onPress={() => {
            navigation.navigate('Preferences', {screen: 'Collections'});
          }}
        />
      </View>
      <CollectionListItem
        title="All"
        onPress={() => {
          toggleDrawer();
          setCurrentCollection(null);
        }}
        selected={!currentCollection}
        icon={<IconBookshelf />}
        badgeText={Object.keys(allPapers).length.toString()}
      />
      <ScrollView style={{flex: 1, backgroundColor: theme.colors.white}}>
        <DrawerSection
          title="Starred"
          items={allCollections
              .filter((c) => !c.hidden)
              .filter((c) => c.starred)
              .sort((a, b) => a.order - b.order)
              .map((c) => (
                <CollectionListItem
                  testID='drawer_collection_item_starred'
                  key={c.key}
                  title={c.name}
                  badgeText={c.paperIds.length.toString()}
                  onPress={() => {
                    toggleDrawer();
                    setCurrentCollection(c);
                  }}
                  selected={c.key === currentCollection?.key}
                />
              ))}
        />

        <DrawerSection
          title="My Collections"
          items={allCollections
              .filter((c) => !c.hidden)
              .filter((c) => !c.starred)
              .sort((a, b) => a.order - b.order)
              .map((c) => (
                <CollectionListItem
                  testID='drawer_collection_item'
                  title={c.name}
                  badgeText={c.paperIds.length.toString()}
                  key={c.key}
                  onPress={() => {
                    toggleDrawer();
                    setCurrentCollection(c);
                  }}
                  selected={c.key === currentCollection?.key}
                />
              ))}
        />

        <DrawerSection
          title="Public Collections"
          items={[
            ...(currentCollection &&
              currentCollection.isPublic ? [<CollectionListItem
                key={currentCollection.key}
                title={currentCollection?.name}
                onPress={() => {
                  /**/
                }}
                selected={true}
              />] : []),
            <CollectionListItem
              key="more"
              title="More..."
              onPress={() => {
                if (useModal) {
                  showModal((_close) => (
                    <NavigationContainer
                      independent={true}
                      theme={useDark() ? DarkTheme : DefaultTheme}>
                      <PublicCollections onChange={_close}/>
                    </NavigationContainer>), {
                    height: 600,
                    width: 600,
                    contentContainerStyle: {padding: 0},
                    title: 'Public Collections',
                  });
                } else {
                  navigation.navigate('PublicCollections');
                }
              }}
              icon={<IconPublicCollection />}
            />,
          ]}
        />
      </ScrollView>
      <Divider />
      <CollectionListItem
        testID="btn_preferences"
        title="Preferences"
        onPress={() => {
          if (useModal) {
            toggleDrawer();
            showModal(() => (
              <NavigationContainer
                independent={true}
                theme={useDark() ? DarkTheme : DefaultTheme}>
                <Settings />
              </NavigationContainer>), {
              height: 600,
              width: 600,
              contentContainerStyle: {padding: 0},
            });
          } else {
            navigation.navigate('Preferences', {screen: 'Main'});
          }
        }}
        icon={<IconConfig />}
      />
    </View>
  );
};

export default CollectionDrawer;
