import {Text, useTheme} from '@rneui/themed';
import React, {useState} from 'react';
import {FlatList, SectionList, View} from 'react-native';
import {useRecoilValue} from 'recoil';
import {isAdminState} from '../../recoil/atoms';
import {
  PreferenceItem,
  PreferenceItemProps,
  PreferenceSectionProps,
} from './PreferenceItem';

export const PreferencesScreen = ({
  items,
  sections,
  extraData,
  testID,
  padItem = true,
}: {
  items?: () => PreferenceItemProps[];
  sections?: PreferenceSectionProps[];
  extraData?: unknown;
  maxWidth?: number;
  testID?: string;
  padItem?: boolean;
}): JSX.Element => {
  const {theme} = useTheme();
  const [update, setUpdate] = useState(0);
  const isAdmin = useRecoilValue(isAdminState);

  return (
    <View
      testID={testID}
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      {sections ? (
        <SectionList
          testID={'preferences_section_list'}
          bounces={false}
          sections={(__DEV__ ?
            sections :
            sections.filter((sec) => !sec.hidden)
          ).map((sec) => ({
            ...sec,
            data: __DEV__ ?
              sec.items().filter((it) => !it.hidden &&
                (!it.adminOnly || isAdmin)) :
              sec.items().filter((it) => !it.devOnly && !it.hidden &&
                (!it.adminOnly || isAdmin)),
          }))}
          renderSectionHeader={({section: {title, id, hidden}}) =>
            title ? (
              <Text
                testID={`pref_header_${id}`}
                style={{
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingTop: 8,
                  backgroundColor: theme.colors.backgroundHighlight,
                }}
              >
                {title} {__DEV__ && hidden && '(dev)'}
              </Text>
            ) : <View style={{
              // height: id !== sections[0].id ? 16 : 0,
              height: padItem ? 16 : (id !== sections[0].id ? 8 : 0),
            }}></View>
          }
          renderItem={({item, index, section}) => (
            <PreferenceItem
              key={item.id}
              refresh={() => {
                setUpdate(update + 1);
              }}
              firstItem={index === 0}
              lastItem={index === section.data.length - 1}
              padItem={padItem}
              {...item}
            />
          )}
          ListFooterComponent={<View style={{height: 16}} />}
        />
      ) : (
        <FlatList
          bounces={false}
          data={items ? items().filter((it) => !it.hidden && !it.devOnly) : []}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({item}) => (
            <PreferenceItem
              refresh={() => {
                setUpdate(update + 1);
              }}
              {...item}
            />
          )}
          extraData={extraData}
        />
      )}
    </View>
  );
};
