import {useTheme} from '@rneui/themed';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useRecoilValue} from 'recoil';
import {CollectionList} from '../components/CollectionDrawer';
import PaperInfo from '../components/PaperInfo';
import PaperInfoHeader from '../components/PaperInfoHeader';
import PaperList from '../components/PaperList';
import {ScreenContainer} from '../components/ScreenContainer';
import {currentPaperState} from '../recoil/atoms';

/**
 * Three-screen split view: Collection List, Paper List, and Paper Info
 * @returns Screen
 */
const ThreeColumnScreen = () => {
  const {theme} = useTheme();

  const dividerWidth = 1;
  const cMinWidth = 200;
  const pMinWidth = 200;
  const cMaxWidth = 300;
  const pMaxWidth = 500;

  const currentPaper = useRecoilValue(currentPaperState);
  const [cWidth, setCWidth] = useState<number>(250);
  const [pWidth, setPWidth] = useState<number>(300);
  const [cSeparatorXPosition, setCSeparatorXPosition] = useState<number>();
  const [pSeparatorXPosition, setPSeparatorXPosition] = useState<number>();
  const [cDragging, setCDragging] = useState(false);
  const [pDragging, setPDragging] = useState(false);

  const onCMouseDown = (e: React.MouseEvent) => {
    setCSeparatorXPosition(e.clientX);
    setCDragging(true);
  };

  const onPMouseDown = (e: React.MouseEvent) => {
    setPSeparatorXPosition(e.clientX);
    setPDragging(true);
  };

  const onMouseMove = (e: MouseEvent) => {
    if (cDragging && cWidth && cSeparatorXPosition) {
      const newLeftWidth = Math.min(
          Math.max(e.clientX, cMinWidth), cMaxWidth);
      setCSeparatorXPosition(e.clientX);
      setCWidth(newLeftWidth);
    }
    if (pDragging && pWidth && pSeparatorXPosition && cWidth) {
      const newLeftWidth = Math.min(
          Math.max(e.clientX - cWidth - dividerWidth, pMinWidth),
          pMaxWidth);
      setPSeparatorXPosition(e.clientX);
      setPWidth(newLeftWidth);
    }
  };

  const onMouseUp = () => {
    setCDragging(false);
    setPDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <View
      testID='container'
      style={{backgroundColor: theme.colors?.background, flex: 1}}>
      <View
        testID='container2'
        style={{
          flexDirection: 'row',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            height: '100%',
            width: cWidth,
          }}
        >
          <View style={{
            backgroundColor: theme.colors?.background,
            borderRightColor: theme.colors.border,
            height: '100%'}}>
            <ScreenContainer>
              <CollectionList
                toggleDrawer={() => {
                // Do nothing
                }}
              />
            </ScreenContainer>
          </View>
        </div>

        <div style={{
          width: dividerWidth,
          height: '100%',
          margin: 1,
          cursor: 'col-resize',
        }} onMouseDown={onCMouseDown}/>

        <div
          style={{
            backgroundColor: theme.colors?.background,
            borderRightWidth: 1,
            borderRightColor: theme.colors.border,
            width: pWidth,
          }}
        >
          <View style={{height: '100%'}}>
            <ScreenContainer>
              <PaperList
                onLongPress={() => {
                // Do nothing
                }}
                highlightCurrentPaper={true}
              />
            </ScreenContainer>
          </View>
        </div>

        <div style={{
          width: dividerWidth,
          height: '100%',
          margin: 1,
          cursor: 'col-resize',
        }} onMouseDown={onPMouseDown}/>

        <div
          style={{
            backgroundColor: theme.colors.background,
            flexGrow: 1,
            width: 0,
          }}
        >
          <View style={{height: '100%'}}>
            {currentPaper ? (<ScreenContainer>
              <View style={{
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}>
                <View style={{
                  height: 48,
                  width: '100%',
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  flexDirection: 'column',
                  borderBottomColor: theme.colors.border,
                  borderBottomWidth: 1}}>
                  <PaperInfoHeader paper={currentPaper} />
                </View>
                <PaperInfo />
              </View>
            </ScreenContainer>
          ) : (
            <View></View>
            // <Home showGoBack={false} />
          )}
          </View>
        </div>
      </View>
    </View>
  );
};

export default ThreeColumnScreen;
