import {
  localStorageRemoveItem,
  localStorageSetItem,
  localStorageGetItem,
  localStorageGetKeys,
} from '../../platform/localStorage';
import Collection, {
  createNewCollection, getCollectionJSON, mergeCollection,
} from '../collection';
import {isLoggedIn, uid, updateLastSynced} from './utils';
import {firestore} from '../../platform/firebase';
import Paper, {createNewPaper, refreshPaper} from '../paper';

export type PublicPaper = {
  authors: string[];
  numCitations: number;
  pdfUrl: string;
  tags: string[];
  title: string;
  venue: string;
  year: string;
};

export type PublicCollection = {
  key: string;
  name: string;
  description: string;
  papers: Record<
    string,
    {
      title: string;
      authors: string[];
      year: string;
      venue: string;
      pdfUrl: string;
      tags: string[];
      numCitations: number;
      alias: string;
    }
  >;
  numPapers: number;
  createdBy: string;
  publishedAt: number;
  updatedAt: number;
  image?: string;
  imageUrl?: string;
};

export type PublicCollectionGroup = {
  key: string;
  title: string;
  collections: string[];
};

const removeLocal = async (c: Collection): Promise<void> => {
  await localStorageRemoveItem(`collection:${c.key}`);
};

const removeServer = async (c: Collection): Promise<void> => {
  if (!firestore) return;
  if (!isLoggedIn()) return;
  await firestore.collection('Collections').doc(c.key).delete();
  await firestore.collection('Sync').doc(uid())
      .collection('Collections').doc(c.key).delete();
  await updateLastSynced();
};

/**
 * Save a collection to local storage and remote server
 * @param collection - collection
 */
async function saveLocal(
    collection: Collection, keepDate = false): Promise<Collection> {
  const c = {
    ...collection,
    dateModified: keepDate ? collection.dateModified : Date.now(),
  } as Collection;
  // Save to local storage
  await localStorageSetItem(
      `collection:${c.key}`,
      JSON.stringify(getCollectionJSON(c)));
  console.log('Collection saved', c.key);
  return c;
}

/**
 * Save a collection to remote server
 * @param c - collection
 * @returns updated collection
 */
async function saveServer(c: Collection): Promise<void> {
  if (!isLoggedIn) return;
  await firestore?.collection('Collections').doc(c.key).set({
    ...getCollectionJSON(c),
    ownedBy: uid(),
  });
  await firestore?.collection('Sync').doc(uid())
      .collection('Collections').doc(c.key)
      .set({dateModified: c.dateModified});

  await updateLastSynced();
  console.log('Collection saved to server', c.key);
}

const getLocal = async (cid: string): Promise<Collection | null> => {
  const json = await localStorageGetItem(`collection:${cid}`);
  const c = json ?
    createNewCollection({
      ...JSON.parse(json),
      key: cid,
    }) :
    null;

  // Migration code. TODO: remove after a few releases
  if (c && Array.isArray(c.papers)) {
    c.paperIds = c?.papers as unknown as string[];
  }

  return c;
};

const getServer = async (cid: string): Promise<Collection | null> => {
  if (!isLoggedIn()) return null;
  if (!firestore) return null;
  try {
    const doc = await firestore.collection('Collections').doc(cid).get();
    const data = doc.data();
    return data ? createNewCollection({
      ...data,
      key: cid,
    }) as Collection : null;
  } catch (e: unknown) {
    return null;
  }
};

/**
 * Sync collections with server
 */
async function sync() {
  if (!isLoggedIn()) return;
  if (!firestore) return;
  const dateModified = Object.fromEntries(
      (await firestore.collection('Sync').doc(uid())
          .collection('Collections').get())
          .docs.map((d) => ([
            d.id, d.data().dateModified as number | undefined])));

  const localCollectionKeys = (await localStorageGetKeys()).filter((key) =>
    key.startsWith('collection:')).map((key) => key.split(':')[1]);
  const allKeys = [...(
    new Set([...Object.keys(dateModified), ...localCollectionKeys]))];
  await Promise.all(allKeys.map(async (key) => {
    const localCollection = await getLocal(key);
    if (!localCollection ||
        (localCollection.dateModified || 0) < (dateModified[key] || 0)) {
      // remote collection is newer or local collection does not exist
      console.log('local collection updated', key);
      const remoteCollection = await getServer(key);
      const updatedCollection = mergeCollection(
          localCollection || createNewCollection(), remoteCollection);
      await saveLocal(updatedCollection, true);
    } else if (!dateModified[key] ||
        (localCollection.dateModified || 0) > (dateModified[key] || 0)) {
      // remote collection is older or does not have a dateModified
      console.log('remote collection updated', key);
      await saveServer(localCollection);
    }
  }));
}

// const publish = async (
//     c: Collection,
//     allPapers: Record<string, Paper>,
// ): Promise<void> => {
//   if (!isLoggedIn()) return Promise.resolve();
//   if (!c.isPublic) return Promise.resolve();
//   const now = Date.now();
//   if (!c.publishedAt || c.publishedAt < 1000) c.publishedAt = now;
//   c.updatedAt = now;

//   await storage
//       ?.ref('collections')
//       .child(uid())
//       .child(c.key)
//       .put(
//           new Blob(
//               [
//                 JSON.stringify(
//                     Object.fromEntries(
//                         c.papers.map((paperId) => {
//                           const p = allPapers[paperId];
//                           return [
//                             p.id,
//                             pick(p, [
//                               'title',
//                               'authors',
//                               'year',
//                               'venue',
//                               'pdfUrl',
//                               'tags',
//                               'numCitations',
//                               'alias',
//                             ]),
//                           ];
//                         }),
//                     ),
//                     null,
//                     2,
//                 ),
//               ],
//               {type: 'application/json'},
//           ),
//       );

//   await db
//       ?.ref('collections')
//       .child(c.key)
//       .set({
//         ...pick(c, ['key', 'name', 'description', 'publishedAt',
// 'updatedAt']),
//         numPapers: c.paperIds.length,
//         createdBy: auth?.currentUser?.uid,
//         publishedAt: c.publishedAt,
//         updatedAt: c.updatedAt,
//       } as PublicCollection);
//   c.updatedAt = now;
//   await save(c);
// };

// /**
//  * unpublish a collection
//  * @param c - collection
//  */
// async function unpublish(c: Collection): Promise<void> {
//   await db?.ref('collections').child(c.key).remove();
//   c.publishedAt = undefined;
//   c.updatedAt = undefined;
//   await save(c);
// }

// /**
//  * Get list of papers in a public collection
//  * @param collection - public collection
//  * @returns PublicPaper list
//  */
// async function getPublicCollectionPapers(
//     collection: PublicCollection,
// ): Promise<Record<string, PublicPaper>> {
//   const url = await storage
//       ?.ref('collections')
//       .child(collection.createdBy)
//       .child(collection.key)
//       .getDownloadURL();
//   if (url) {
//     const res = await fetch(url);
//     return res.json() as unknown as Record<string, PublicPaper>;
//   } else {
//     return {};
//   }
// }

/**
 * @param numItems - manimum number of returned collections
 * @returns list of public collections
 */
async function getPublicCollections(
    numItems = 10,
): Promise<Collection[]> {
  if (!firestore) throw new Error('Firestore not available.');
  const ref = firestore.collection('PublicCollections');
  const query = ref.limit(numItems);
  const snapshot = await query.get();

  return await Promise.all(
      snapshot.docs.map(async (doc) => {
        // let imageUrl = undefined;
        // try {
        //   imageUrl = c.image ?
        //   ((await storage
        //       ?.ref('collections')
        //       .child('images')
        //       .child(c.image as string)
        //       .getDownloadURL()) as string) :
        //   undefined;
        // } catch (e) {
        // // Do nothing
        // }
        return createNewCollection({
          key: doc.id,
          // imageUrl,
          ...doc.data(),
        });
      }),
  );
}

/**
 * get all public collection papers
 * @param cid - collection id
 */
async function getPublicCollectionPapers(cid: string) {
  const papers = await firestore?.collection(`PublicCollections`).doc(cid)
      .collection('papers').limit(10).get();
  return papers?.docs.map((p) => refreshPaper({
    ...createNewPaper(),
    ...p.data(),
    id: p.id} as Paper));
}

// /**
//  * @param key - public collection id
//  * @returns a public collection specified by `key`
//  */
// async function getPublicCollection(key: string): Promise<PublicCollection> {
//   const snapshot = await db?.ref('collections').child(key).once('value');
//   const data = snapshot?.val() as Record<string, unknown>;
//   const imageUrl = data.image ?
//     ((await storage
//         ?.ref('collections')
//         .child('images')
//         .child(data.image as string)
//         .getDownloadURL()) as string) :
//     undefined;
//   return {
//     key,
//     imageUrl,
//     ...data,
//   } as PublicCollection;
// }

// /**
//  * @returns list of groups of public collections
//  */
// async function getPublicCollectionGroups(): Promise<PublicCollectionGroup[]>
// {
//   const snapshot = await db?.ref('collectionGroups').once('value');
//   const data = snapshot?.val() as Record<string, Record<string, unknown>>;
//   return Object.entries(data).map(
//       ([key, c]) => ({key, ...c} as PublicCollectionGroup),
//   );
// }

export default {
  save: saveLocal,
  load: getLocal,
  server: {
    save: saveServer,
    get: getServer,
    remove: removeServer,
  },
  local: {
    save: saveLocal,
    get: getLocal,
    remove: removeLocal,
  },
  sync,
  // publish,
  // unpublish,
  getPublicCollectionPapers,
  getPublicCollections,
  // getPublicCollection,
  // getPublicCollectionGroups,
};
