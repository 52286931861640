import {SourcePaper, Source, PaperQuery} from './base';

type Paper = {
  title: string;
  paperId: string;
  authors: {
    authorId: string;
    name: string;
  }[];
};

export type SemanticScholarPaper = SourcePaper & {
  paperId: string;
  arxivId: string;
  externalIds: {
    MAG: string;
    DOI: string;
    ArXiv: string;
    DBLP: string;
  };
  title: string;
  url: string;
  venue: string;
  year: string;
  abstract: string;
  authors: {
    authorId: string;
    name: string;
    url: string;
    affiliations: string[];
  }[];
  topics: { topic: string; topicId: string; url: string }[];
  isOpenAccess: boolean;
  isPublisherLicensed: boolean;
  numCiting: number;
  numCitedBy: number;
  citationVelocity: number;
  corpusId: number;
  fieldsOfStudy: string[];
  influentialCitationCount: number;
  referenceCount: number;
  citationCount: number;
  citations: Paper[];
  references: Paper[];
};

const getAuthors = async (
    authors: {
    authorId: string;
    name: string;
  }[],
) =>
  await Promise.all(
      authors?.map(async (a) => {
        if (a.authorId === null) {
          return {
            authorId: a.authorId,
            name: a.name,
          };
        }
        try {
          const response = await fetch(
              `https://api.semanticscholar.org/graph/v1/author/${a.authorId}?fields=affiliations,authorId,name,url`,
          );
          return await response.json();
        } catch (e) {
          return a;
        }
      }),
  );

export const SemanticScholar: Source = {
  source: 'Semantic Scholar',
  canSearch: true,
  fetch: async (paperQuery: PaperQuery) => {
    if (paperQuery.arxiv) {
      const query = `arxiv:${paperQuery.arxiv}`;
      const response = await fetch(
          `https://api.semanticscholar.org/graph/v1/paper/${query}?fields=paperId,externalIds,url,title,abstract,venue,year,referenceCount,citationCount,influentialCitationCount,isOpenAccess,fieldsOfStudy,authors`,
      );
      if (response.status !== 200) {
        throw Error(`Status code: ${response.status}`);
      }
      const data = await response.json();
      return {
        ...data,
        authors: await getAuthors(data.authors),
      } as SemanticScholarPaper;
    }
    throw Error('Paper not found.');
  },
  search: async (searchQuery: string, offset: number, limit: number) => {
    const endpoint = 'https://api.semanticscholar.org/graph/v1/paper/search';
    const normalize = (t: string) => t.toLowerCase().replace(/\W/g, ' ');
    const urlSearchParams = new URLSearchParams({
      query: normalize(searchQuery),
      offset: offset.toString(),
      limit: limit.toString(),
      fields: 'externalIds,url,title,venue,year,citationCount,authors',
    }).toString();
    const response = await (
      await fetch(`${endpoint}?${urlSearchParams}`)
    ).json();
    const papers = await Promise.all(
        response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            async (d: any) =>
              ({
                title: d.title,
                paperId: d.paperId,
                externalIds: d.externalIds,
                url: d.url,
                venue: d.venue,
                year: d.year,
                citationCount: d.citationCount,
                authors: d.authors,
              } as SemanticScholarPaper),
        ),
    );
    return papers;
  },
};
