import {selector} from 'recoil';
import * as Device from 'expo-device';
import {Platform} from 'react-native';
import {deviceTypeState, settingsState} from '../atoms';
import {Layout} from '../../common/store';

export const isPhoneState = selector<boolean>({
  key: 'IsPhone',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.PHONE;
  },
});

export const isPadState = selector<boolean>({
  key: 'IsPad',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.TABLET;
  },
});

export const isTabletState = selector<boolean>({
  key: 'IsTablet',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.TABLET;
  },
});

export const isWebState = selector<boolean>({
  key: 'IsWeb',
  get: () => {
    return Platform.OS === 'web';
  },
});

export const useModalState = selector<boolean>({
  key: 'UseModal',
  get: ({get}) => {
    return get(isWebState);
  },
});

export const layoutState = selector<Layout>({
  key: 'LayoutState',
  get: ({get}) => {
    const layout = get(settingsState).layout;
    if (layout !== 'auto') return layout;
    if (get(isPhoneState)) return 'one-column';
    if (get(isPadState)) return 'two-column';
    if (get(isWebState)) return 'three-column';
    return 'one-column';
  },
});
