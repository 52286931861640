import React, {useEffect, useState} from 'react';
import {
  ItemKind,
  PreferenceItemProps,
  PreferencesScreen,
  PreferenceSectionProps,
} from '../../components/Preferences';
import Paper, {createNewPaper, refreshPaper} from '../../common/paper';
import {Sources} from '../../common/sources';
import {useNavigation} from '@react-navigation/native';
import {PdfReaderType} from '../../common/store';
import {StackNavigationProp} from '@react-navigation/stack';
import {SettingsStackParamList} from './Navigator';
import {sortByText} from './General';
import {SourceKey} from '../../common/sources/base';
import {useRecoilState} from 'recoil';
import {settingsState} from '../../recoil/atoms';
import {View} from 'react-native';
import {Text} from '@rneui/themed';
import PaperListItem from '../../components/PaperListItem';

export const PapersFetchSources = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const [sources, setSources] = useState<SourceKey[]>();

  useEffect(() => {
    setSources(settings.fetchPaperSources.filter(
        (s) => !!Sources.find((src) => src.key === s && src.canFetch)));
  }, []);

  useEffect(() => {
    if (sources === undefined) return;
    setSettings({...settings, fetchPaperSources: sources});
  }, [sources]);

  return (
    <PreferencesScreen
      sections={[{
        id: 'main',
        title: '',
        items: () =>
          Object.entries(Sources).map(
              ([_, s]) =>
                ({
                  title: s.name,
                  kind: ItemKind.CheckBox,
                  value: sources?.includes(s.key),
                  subTitle: s.url,
                  disabled: !s.deselectable,
                  onPress: () => {
                    if (settings.fetchPaperSources.includes(s.key)) {
                      setSources(settings.fetchPaperSources.filter(
                          (n) => n !== s.key));
                    } else {
                      setSources([...settings.fetchPaperSources, s.key]);
                    }
                  },
                } as PreferenceItemProps),
          ),
      }]}
    />
  );
};

export const PapersSearchSources = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const [sources, setSources] = useState<SourceKey[]>();

  useEffect(() => {
    setSources(settings.searchPaperSources.filter(
        (s) => !!Sources.find((src) => src.key === s && src.canSearch)));
  }, []);

  useEffect(() => {
    if (sources === undefined) return;
    setSettings({...settings, searchPaperSources: sources});
  }, [sources]);

  return (
    <PreferencesScreen
      sections={[{
        id: 'main',
        items: () =>
          Object.entries(Sources)
              .filter(([, s]) => s.cls.canSearch)
              .map(
                  ([_, s]) =>
                    ({
                      title: s.name,
                      kind: ItemKind.CheckBox,
                      disabled: !s.deselectable,
                      subTitle: s.url,
                      value: sources?.includes(s.key),
                      onChange: (_val: boolean) => {
                        if (settings.searchPaperSources.includes(s.key)) {
                          setSources(settings.searchPaperSources
                              .filter((n) => n !== s.key));
                        } else {
                          setSources([
                            ...settings.searchPaperSources,
                            s.key,
                          ]);
                        }
                      },
                    } as PreferenceItemProps),
              ),
      }]}
    />
  );
};

export const PapersPdfReader = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  return (
    <PreferencesScreen
      items={() =>
        ['default']
            .map(
                (s) =>
                  ({
                    title: s,
                    kind: ItemKind.CheckBox,
                    onChange: (_val: boolean) => {
                      setSettings({
                        ...settings,
                        pdfReader: s as PdfReaderType,
                      });
                    },
                  } as PreferenceItemProps),
            )
      }
    />
  );
};

export const Papers = (): JSX.Element => {
  const _paperSample: Paper = refreshPaper({
    ...createNewPaper(''),
    title: 'Protein measurement with the Folin phenol reagent',
    authors: [
      {fullName: 'O. H. Lowry'},
      {fullName: 'N. J. Rosebrough'},
      {fullName: 'A. Farr'},
      {fullName: 'R. J. Randall'},
    ],
    tags: ['important', 'starred'],
    year: '1951',
    venue: 'The Journal of Biological Chemistry',
    numCitations: 290506,
  });

  const _paperListTemplates = {
    'Minimal': {
      headerFormat: '{title}',
      contentFormat: '{authorShort}',
      content2Format: '',
    },
    'Full': {
      headerFormat: '{title}',
      contentFormat: '{authorFull}',
      content2Format: '{venueAndYear} - Cited by {numCitations}',
    },
    'Authors First': {
      headerFormat: '{authorShort}',
      contentFormat: '{title}',
    },
  };

  const [settings, setSettings] = useRecoilState(settingsState);
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();

  return (
    <PreferencesScreen
      sections={
        [
          {
            id: 'display',
            title: '',
            // subTitle:
            //   'Specify how items appear in the list. Supported fields: ' +
            //   '{title}, {authorShort}, {venue}, {year}, {venueAndYear}, ' +
            //   '{abstract}',
            items: () => [
              {
                kind: ItemKind.Custom,
                component: (
                  <View
                    style={{
                      width: '100%',
                      height: 250,
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 16,
                    }}
                  >
                    <Text>List Item Preview</Text>
                    <PaperListItem
                      paper={_paperSample}
                      containerStyle={{
                        borderWidth: 1, width: 400, maxWidth: '100%'}}
                    />
                  </View>
                ),
              },
              {
                kind: ItemKind.CheckBox,
                title: 'Truncate Text',
                value: settings.paperList.singleLine,
                onChange: (val) => {
                  setSettings({
                    ...settings,
                    paperList: {
                      ...settings.paperList,
                      singleLine: !!val,
                    },
                  });
                },
              },
              {
                kind: ItemKind.CheckBox,
                title: 'Show First Author Only',
                value: settings.paperList.shortAuthorNames,
                onChange: (val) => {
                  setSettings({
                    ...settings,
                    paperList: {
                      ...settings.paperList,
                      shortAuthorNames: !!val,
                    },
                  });
                },
              },
              {
                kind: ItemKind.CheckBox,
                title: 'Show Tags',
                value: settings.paperList.showTags,
                onChange: (val) => {
                  setSettings({
                    ...settings,
                    paperList: {
                      ...settings.paperList,
                      showTags: val as boolean,
                    },
                  });
                },
              },
            ],
          },
          {
            id: 'sort_by',
            items: () => [
              {
                kind: ItemKind.Menu,
                title: 'Sort By',
                subTitle: sortByText[settings.paperList.sortBy],
                onPress: () => {
                  navigation.navigate('SortBy');
                },
              },
            ],
          },
          {
            id: 'sources',
            title: '',
            items: () => [
              {
                id: 'search_sources',
                kind: ItemKind.Menu,
                title: 'Search Sources',
                subTitle: settings.searchPaperSources.map((key) =>
                  Sources.find((s) => s.key === key)?.name).join(', '),
                onPress: () => navigation.navigate('PapersSearchSources'),
              },
              {
                id: 'fetch_sources',
                kind: ItemKind.Menu,
                title: 'Metatdata Sources',
                subTitle: settings.fetchPaperSources.map((key) =>
                  Sources.find((s) => s.key === key)?.name).join(', '),
                onPress: () => navigation.navigate('PapersFetchSources'),
              },
            ],
          },
          {
            id: 'pdf',
            title: '',
            items: () => [
              {
                kind: ItemKind.CheckBox,
                title: 'Cache PDF',
                subTitle: 'Previously opened PDF files can be read offline.',
                value: settings.cachePdf,
                onChange: (val) => {
                  setSettings({...settings, cachePdf: val as boolean});
                },
              },
              {
                kind: ItemKind.Input,
                title: 'Cache Expiration (days)',
                value: `${settings.cacheExpiration}`,
                disabled: !settings.cachePdf,
                inline: true,
                onChange: (txt) => {
                  const val = txt ? parseInt(txt as string) : 0;
                  setSettings({
                    ...settings,
                    cacheExpiration: val <= 30 ? val : 0,
                  });
                },
              },
            ],
          },
        ] as PreferenceSectionProps[]
      }
    />
  );
};
