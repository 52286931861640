import {Input, Text, Dialog, useTheme} from '@rneui/themed';
import React from 'react';
import {View} from 'react-native';

export const Prompt = ({
  title,
  subTitle,
  initialValue,
  onSubmit,
  onCancel,
  submitTitle = 'Change',
  cancelTitle = 'Cancel',
}: {
  title: string,
  subTitle: string,
  initialValue: string,
  onSubmit?: (value: string) => void,
  onCancel?: () => void,
  submitTitle?: string,
  cancelTitle?: string,
}) => {
  const [value, setValue] = React.useState<string>(initialValue);
  const {theme} = useTheme();

  return <View style={{padding: 16}}>
    <Dialog.Title titleStyle={{color: theme.colors.black}} title={title} />
    <Text>{subTitle}</Text>
    <Input
      testID='prompt_input'
      value={value} onChangeText={(text) => setValue(text)} />
    <Dialog.Actions>
      <Dialog.Button
        testID='prompt_submit' title={submitTitle} onPress={() => {
          onSubmit && onSubmit(value);
        }} containerStyle={{marginRight: 8}} />
      <Dialog.Button
        testID='prompt_cancel' title={cancelTitle} onPress={() => {
          onCancel && onCancel();
        }} />
    </Dialog.Actions>
  </View>;
};
