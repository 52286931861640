import {selector} from 'recoil';
import {ListDisplayType} from '../../common/context';
import Paper, {createNewPaper, sortPapers} from '../../common/paper';
import {
  currentCollectionState,
  settingsState,
  listDisplayTypeState,
  searchQueryState,
  sortByState,
  allPapersState,
} from '../atoms';
import Fuse from 'fuse.js';

export const paperListItemsState = selector<Paper[]>({
  key: 'PaperListItems',
  get: ({get}) => {
    console.log('refresh paper list items');
    const listDisplayType = get(listDisplayTypeState);
    if (listDisplayType === ListDisplayType.WebSearch) return [];
    const searchQuery = get(searchQueryState);
    const allPapers = get(allPapersState);
    const currentCollection = get(currentCollectionState);
    const settings = get(settingsState);
    const sortBy = get(sortByState);
    let papers: Paper[] = [];
    if (!searchQuery) {
      if (!currentCollection) papers = Object.values(allPapers);
      else {
        papers = currentCollection.paperIds.map((pid) => {
          if (allPapers[pid]) return allPapers[pid];
          else if (currentCollection.papers[pid]) {
            return {
              ...createNewPaper(),
              ...(currentCollection.papers[pid] as Paper),
              id: pid,
            } as Paper;
          }
        }).filter((p) => p) as Paper[];
      }
    } else if (searchQuery[0] === '#') {
      // Search by hashtag
      const hashTags = searchQuery
          .split(' ')
          .map((s) => (s.length > 0 ? s.substring(1) : ''))
          .filter((s) => s !== '');
      papers = Object.values(allPapers).filter((p: Paper) =>
        hashTags.every((tag) => [...p.tags].join(' ').indexOf(tag) >= 0),
      );
    } else {
      const fuse = new Fuse(Object.values(allPapers), {
        keys: settings.searchFields,
        threshold: settings.searchThreshold,
      });
      papers = fuse.search(searchQuery).map((p) => p.item);
    }
    return sortPapers(papers, sortBy);
  },
});
