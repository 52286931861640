import Collection, {PublicCollection} from './collection';
import Settings from './settings';
import Paper from './paper';
import Tag from './tag';
import News, {NewsItem} from './news';
import Profile, {UserInfo} from './profile';
import {
  isLoggedIn,
  updateLastSynced,
  getLocalLastUpdated,
  getServerLastUpdated,
  uid,
} from './utils';
import {db} from '../../platform/firebase';

export type {PublicCollection, UserInfo, NewsItem};

export default {
  Collection,
  Settings,
  Paper,
  Tag,
  Profile,
  News,
  isLoggedIn,
  updateLastSynced,
  getLocalLastUpdated,
  getServerLastUpdated,
  uid,
  db,
};
