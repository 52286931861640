import {PaperQuery, SourcePaper} from './base';

export const comparePapers = (p1: SourcePaper, p2: SourcePaper): boolean => {
  if (
    p1.title?.replace(/\W/g, '').toLowerCase() !==
    p2.title?.replace(/\W/g, '').toLowerCase()
  ) {
    return false;
  }
  return true;
};

export const comparePaperWithQuery = (
    p: SourcePaper,
    q: PaperQuery,
): boolean => {
  if (
    q.title &&
    p.title?.replace(/\W/g, '').toLowerCase() !==
      q.title.replace(/\W/g, '').toLowerCase()
  ) {
    return false;
  }
  return true;
};

export const fetchWithTimeout = async (
    input: RequestInfo,
    _timeout = 3000,
): Promise<Response> => {
  return Promise.race([
    fetch(input),
    // TODO: temporarily disable timeout since it causes issues with tests
    // new Promise((_, reject) =>
    //   setTimeout(() => reject(new Error('Timeout')), timeout),
    // ),
  ]).then();
};
