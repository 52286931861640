import {Pressable, View} from 'react-native';
import {CheckBox, ListItem, useTheme, Text} from '@rneui/themed';
import React, {useContext, useState} from 'react';
import {dialog} from '../../platform/dialog';
import {AppContext} from '../../common/context';
import {Prompt} from '../Prompt';
import {useRecoilValue} from 'recoil';
import {isAdminState} from '../../recoil/atoms';
import {isWebState} from '../../recoil/selectors';

export enum ItemKind {
  Parent,
  Menu,
  Separator,
  Section,
  CheckBox,
  Input,
  Button,
  Text,
  Custom,
}

export type PreferenceItemProps = {
  id: string;
  kind?: ItemKind;
  title?: string | JSX.Element;
  icon?: JSX.Element;
  subTitle?: string | JSX.Element;
  onPress?: () => void;
  onChange?: (value?: string | boolean) => void;
  refresh?: () => void;
  value?: boolean | string | null;
  disabled?: boolean;
  pressable?: boolean;
  hidden?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  readonly?: boolean;
  inline?: boolean;
  numberOfLines?: number;
  showValueOnPress?: boolean;
  component?: JSX.Element;
  separator?: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  padItem?: boolean;
  color?: string;
};

export type PreferenceSectionProps = {
  id: string;
  title?: string;
  items: () => PreferenceItemProps[];
  subTitle?: string;
  hidden?: boolean;
};

export const PreferenceItem = ({
  id,
  title,
  subTitle,
  disabled,
  icon,
  kind = ItemKind.Parent,
  onPress,
  onChange,
  value,
  refresh,
  readonly,
  pressable = true,
  inline,
  numberOfLines,
  showValueOnPress,
  component,
  devOnly,
  adminOnly,
  separator = true,
  firstItem = false,
  lastItem = false,
  padItem = true,
  color,
}: PreferenceItemProps): JSX.Element => {
  const [_editMode, setEditMode] = useState<boolean>(false);
  const {showModal} = useContext(AppContext);
  const isAdmin = useRecoilValue(isAdminState);
  const isWeb = useRecoilValue(isWebState);

  const _onPress = () => {
    if (disabled) return;
    if (!pressable) return;
    if (kind === ItemKind.Input && !readonly) {
      // prompt(
      //   typeof title === 'string' ? title : '',
      //   subTitle || '',
      //   value as string,
      //   (val) => onChange && onChange(val),
      // );
      showModal((close) =>
        <Prompt
          title={title as string || ''}
          subTitle={subTitle as string || ''} /* TODO: support component */
          initialValue={value as string}
          onSubmit={(val) => {
            onChange && onChange(val);
            close();
          }}
          onCancel={close} />, {
        title: '',
        maxWidth: 400,
      });
    } else if (kind == ItemKind.Text) {
      if (showValueOnPress) {
        dialog(typeof title === 'string' ? title : '', transformValue(value));
      }
    }
    if (onPress) onPress();
    if (onChange && kind === ItemKind.CheckBox) onChange(!value);
    if (refresh) refresh();
  };
  const {theme} = useTheme();

  const transformValue = (value: unknown) => {
    if (typeof value === 'string') return value;
    if (typeof value === 'boolean') return value ? 'yes' : 'no';
  };

  const getSubTitle = () => {
    if (subTitle) return subTitle;
    if (kind === ItemKind.CheckBox) return null;
    if (kind === ItemKind.Input) return transformValue(value) || 'N/A';
    return null;
  };

  const getContent = (highlight: boolean) => {
    if (kind === ItemKind.Custom) {
      return component;
    } else if (kind == ItemKind.Separator) {
      return <></>;
    } else {
      return (
        <>
          {icon && <View style={{minWidth: 16, marginRight: 16}}>{icon}</View>}
          <ListItem.Content
            style={{
              flexDirection: inline ? 'row' : 'column',
              justifyContent: 'flex-start',
            }}
          >
            {__DEV__ && devOnly && <Text style={{fontSize: 8}}>DEV</Text>}
            {adminOnly && isAdmin && <Text style={{fontSize: 8}}>ADMIN</Text>}

            {typeof title === 'string' ? (<ListItem.Title
              style={{
                flex: inline ? undefined : 1,
                textAlign: 'left',
                paddingRight: 32,
                color: color || (
                  disabled ? theme.colors?.grey4 : theme.colors?.black),
              }}
              numberOfLines={numberOfLines || 1}
              testID={`pref_item_title_${id}`}
            >
              {title}
            </ListItem.Title>) : (title)}

            {getSubTitle() && (
              <ListItem.Subtitle
                style={{
                  color: disabled ? theme.colors?.grey4 : theme.colors?.grey2,
                  flex: 1,
                  flexDirection: 'row',
                  textAlign: inline ? 'right' : 'left',
                }}
                testID={`pref_item_subtitle_${id}`}
                numberOfLines={numberOfLines || 1}
                onPress={(isWeb && kind === ItemKind.Input) ? () => {
                  setEditMode(true);
                } : undefined}
              >
                {getSubTitle()}
                {/* {editMode && kind === ItemKind.Input && isWeb && (
                  <Input
                    containerStyle={{
                      flex: 1,
                      margin: 0,
                      padding: 0,
                      height: 19,
                    }}
                    inputStyle={{
                      fontSize: 14,
                      paddingHorizontal: 8,
                      paddingVertical: 0,
                      margin: 0,
                      textAlign: 'right',
                    }}
                    style={{}}
                    inputContainerStyle={{height: 19}}
                    selectTextOnFocus={true}
                    value={transformValue(value)}
                    onChangeText={(text) => {
                      if (onChange) onChange(text);
                    }}
                  />
                )} */}
              </ListItem.Subtitle>
            )}
          </ListItem.Content>
          {kind === ItemKind.CheckBox && (
            <CheckBox
              right
              iconRight
              containerStyle={{
                margin: 0, padding: 0,
                height: 20,
                backgroundColor: highlight ? theme.colors?.backgroundHighlight :
              theme.colors?.white,
              }}
              checked={value as boolean}
              onPress={(_e) => _onPress()}
              disabled={disabled}
            />
          )}
          {kind === ItemKind.Menu && <ListItem.Chevron />}
          {kind == ItemKind.Section && <ListItem.Accordion />}
        </>
      );
    }
  };

  return (
    <Pressable
      // onPress={isWeb ? _onPress : undefined}
      // onPressIn={isPad ? _onPress : undefined}
      onPress={_onPress}
      testID="pref_item"
      disabled={disabled}
      style={{marginHorizontal: padItem ? 16 : 0}}
    >
      {({pressed}) => <ListItem
        disabled={disabled}
        testID={`pref_item_${id}`}
        containerStyle={{
          padding: kind === ItemKind.Custom ? 0 : 16,
          backgroundColor: pressed && pressable ?
              theme.colors?.backgroundHighlight :
              theme.colors.white,
          borderBottomColor: theme.colors.border,
          borderBottomWidth: separator && !lastItem ? 1 : 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopColor: theme.colors.border,
          borderTopWidth: 0,
          borderTopLeftRadius: firstItem && padItem ? 12 : 0,
          borderTopRightRadius: firstItem && padItem ? 12 : 0,
          borderBottomLeftRadius: lastItem && padItem ? 12 : 0,
          borderBottomRightRadius: lastItem && padItem ? 12 : 0,
        }}
      >
        {getContent(pressed && pressable)}
      </ListItem>}
    </Pressable>
  );
};
