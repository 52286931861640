import React, {useEffect, useState} from 'react';
import Paper from '../common/paper';
import {TouchableOpacity, View} from 'react-native';
import ActivityIndicator from '../components/ActivityIndicator';
import {Text} from '@rneui/themed';
import {useNavigation, NavigationProp} from '@react-navigation/native';
import {MainStackParamList} from '../Main';
import {useRecoilValue} from 'recoil';
import {settingsState} from '../recoil/atoms/settings';
import {isFetchingCurrentPaperState} from '../recoil/atoms';

require('format-unicorn');

type PaperInfoHeaderType = { paper: Paper };

const PaperInfoHeader = ({paper}: PaperInfoHeaderType): JSX.Element => {
  const [paperHistory, setPaperHistory] = useState<Paper[]>([]);
  const settings = useRecoilValue(settingsState);

  const navigation =
    useNavigation<NavigationProp<MainStackParamList, 'PaperList'>>();

  useEffect(() => {
    if (!paper) return;
    setPaperHistory(
        [paper, ...paperHistory.filter((p) => p.id !== paper.id)].slice(
            0,
        settings.useTab ? 10 : 1,
        ),
    );
  }, [paper]);

  const isFetching = useRecoilValue(isFetchingCurrentPaperState);

  return (
    <View style={{flex: 1, height: 32}}>
      {paperHistory.map((p, idx) => (
        <View style={{flex: 1, flexDirection: 'row'}} key={idx}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('PaperView');
            }}
            style={{flex: 1}}
          >
            <Text numberOfLines={1} style={{fontWeight: 'bold', width: '90%'}}>
              {p.title}
            </Text>
            <Text
              numberOfLines={1}
              style={{fontSize: 11, fontStyle: 'italic', width: '90%'}}
            >
              {p.authorFull}
            </Text>
          </TouchableOpacity>
          {isFetching &&
          <ActivityIndicator testID='fetching_loading_indicator' />}
        </View>
      ))}
    </View>
  );
};

export default PaperInfoHeader;
