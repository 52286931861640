import {useTheme} from '@rneui/themed';
import React from 'react';
import {
  ActivityIndicator,
} from 'react-native';
import {IconProps} from '../platform/icons';

const _ActivityIndicator = ({testID, size}: IconProps) => {
  const {theme} = useTheme();
  return <ActivityIndicator
    size={size}
    testID={testID}
    color={theme.colors.black} />;
};

export default _ActivityIndicator;
