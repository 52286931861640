import {useTheme, Text} from '@rneui/themed';
import React, {useContext} from 'react';
import {FlatList, View, Pressable, LayoutAnimation} from 'react-native';
import {useRecoilValue} from 'recoil';
import {AppContext} from '../common/context';
import {PdfHighlight} from '../common/paper';
import {IconComment, IconDelete} from '../platform/icons';
import {currentPaperState} from '../recoil/atoms';
import {Prompt} from './Prompt';

const Button = ({
  icon,
  onPress,
}: {
  title: string;
  icon: React.ReactNode;
  onPress: () => void;
}) => {
  const {theme} = useTheme();
  return <Pressable style={{
    flexDirection: 'row',
    borderWidth: 0,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
    marginRight: 4,
    height: 20,
    width: 20,
    backgroundColor: theme.colors.white,
  }} onPress={onPress}>
    {icon}
    {/* <Text style={{marginLeft: 4, fontSize: 12}}>{title}</Text> */}
  </Pressable>;
};

const HighlightList = ({
  highlights,
  currentHighlight,
  setCurrentHighlight,
  updateHighlights,
}: {
  highlights: PdfHighlight[];
  currentHighlight: PdfHighlight | null;
  setCurrentHighlight: (highlight: PdfHighlight | null) => void;
  updateHighlights: (highlight: PdfHighlight[]) => Promise<void>;
}) => {
  const {theme} = useTheme();
  const {showModal} = useContext(AppContext);
  const paper = useRecoilValue(currentPaperState);

  return <FlatList
    data={highlights}
    extraData={currentHighlight}
    keyExtractor={(item) => item.id}
    style={{backgroundColor: theme.colors.background}}
    renderItem={({item}) => (
      <View style={{
        padding: 8,
        backgroundColor: theme.colors.white,
        marginTop: 4,
        marginHorizontal: 4,
        borderWidth: 1,
        borderColor: theme.colors.border,
      }}>
        <Pressable onPress={() => {
          setCurrentHighlight(item);
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }}>
          <View
            style={{
              borderLeftWidth: 4,
              borderColor: currentHighlight?.id === item.id ?
                    theme.colors.error : theme.colors.border,
              paddingLeft: 4,
            }}>
            <Text numberOfLines={currentHighlight?.id === item.id ? 0 : 3}>
              {item?.content?.text || '[Empty]'}
            </Text>
          </View>
        </Pressable>
        {item?.comment?.text && <Text style={{
          marginTop: 4,
        }} numberOfLines={3}>{item?.comment?.text}</Text>}
        {item?.comment?.emoji}
        {/* <TouchableOpacity
                onPress={() => {
                  if (!currentPaper) return;
                  setCurrentPaper({
                    ...currentPaper,
                    pdfHighlights: [
                      ...currentPaper.pdfHighlights,
                      highlight,
                    ],
                  } as Paper);
                  savePaperAndSync(currentPaper);
                }}><IconEdit /></TouchableOpacity> */}
        {currentHighlight?.id === item.id &&
              <View style={{flexDirection: 'row', marginTop: 4}}>
                <Button
                  title="Comment"
                  icon={<IconComment
                    size={12} />}
                  onPress={() => {
                    if (!paper) return;
                    showModal((close) =>
                      <Prompt
                        title={'Comment'}
                        subTitle={''}
                        initialValue={item.comment?.text || ''}
                        onSubmit={(val) => {
                          updateHighlights(highlights.map((h) =>
                            (h.id === item.id) ? {
                              ...h,
                              comment: {
                                text: val,
                                emoji: '',
                              },
                            } : h));
                          close();
                        }}
                        onCancel={close} />, {
                      title: '',
                      maxWidth: 400,
                    });
                  }}
                />
                <Button
                  title="Delete"
                  icon={<IconDelete size={12} color={theme.colors.black} />}
                  onPress={() => {
                    if (!paper) return;
                    setCurrentHighlight(null);
                    updateHighlights(highlights.filter(
                        (h) => h.id !== item.id));
                  }}
                />
              </View>}
      </View>
    )}
  />;
};

export default HighlightList;
