import {atom} from 'recoil';
import * as Device from 'expo-device';

export const isAdminState = atom<boolean>({
  key: 'IsAdmin',
  default: false,
});

export const deviceTypeState = atom<Device.DeviceType>({
  key: 'DeviceType',
  default: Device.DeviceType.UNKNOWN,
});

export const isReadyState = atom<boolean>({
  key: 'IsReady',
  default: false,
});
