import React, {useContext, useState} from 'react';
import {
  ActivityIndicator,
  Linking,
  View,
} from 'react-native';
import {
  ItemKind,
  PreferencesScreen,
} from '../Preferences';
import {AppContext} from '../../common/context';
import {
  IconDownload,
  IconError,
  IconRefresh,
  IconSuccess,
} from '../../platform/icons';
import moment from 'moment';
import {
  ArXiv, CrossRef, SemanticScholar, PaperShelf, OpenReview,
} from '../PaperInfo/SourceMetadata';
import {Sources} from '../../common/sources';
import {SourceKey} from '../../common/sources/base';
import {useTheme} from '@rneui/themed';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  currentPaperSourcesState, currentPaperState,
} from '../../recoil/atoms';


const Metadata = () => {
  const {actions, showModal} = useContext(AppContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fetchingProgressText, setFetchingProgressText] = useState<string>('');
  const {theme} = useTheme();
  const [paper, setPaper] = useRecoilState(currentPaperState);
  const paperSources = useRecoilValue(currentPaperSourcesState);

  const sourceViews: Record<SourceKey, JSX.Element> = {
    'arxiv': <ArXiv />,
    'crossRef': <CrossRef />,
    'semanticScholar': <SemanticScholar />,
    'paperShelf': <PaperShelf />,
    'openReview': <OpenReview />,
  };

  return (
    <View style={{height: '100%'}}>
      <PreferencesScreen
        padItem={false}
        sections={[
          {
            id: 'actions',
            title: '',
            items: () => [
              {
                id: 'fetch_paper',
                kind: ItemKind.Button,
                title: 'Refresh',
                subTitle: isFetching ?
                  fetchingProgressText :
                    'Last Updated: ' + (
                      Object.entries(paper?.dateFetched || {}).length ? moment(
                          Math.max(...Object.values(paper?.dateFetched || {})),
                      ).calendar() : 'Never'),
                icon: isFetching ? <ActivityIndicator /> : <IconRefresh />,
                disabled: isFetching,
                onPress: () => {
                  if (!paper) return;
                  setIsFetching(true);
                  actions.fetchPaper(
                      paper, undefined, true,
                      (_, msg) =>
                        setFetchingProgressText(msg),
                  ).then((p) => {
                    setPaper(p);
                    setIsFetching(false);
                  });
                },
              },
            ],
          },
          {
            id: 'general',
            title: '',
            items: () => [
              {
                id: 'title',
                kind: ItemKind.Input,
                editable: false,
                title: 'Title',
                value: paper?.title,
                numberOfLines: 2,
                readonly: true,
              },
              {
                id: 'authors',
                kind: ItemKind.Input,
                editable: false,
                title: 'Authors',
                subTitle: paper?.authorFull,
                numberOfLines: 2,
              },
              {
                id: 'affiliations',
                kind: ItemKind.Input,
                editable: false,
                title: 'Affiliations',
                subTitle: paper?.affiliations.join(', '),
                numberOfLines: 2,
              },
              {
                id: 'venue',
                kind: ItemKind.Input,
                editable: false,
                title: 'Venue / Publisher',
                subTitle: paper?.venue,
                inline: true,
              },
              {
                id: 'year',
                kind: ItemKind.Input,
                editable: false,
                title: 'Year',
                subTitle: paper?.year,
                inline: true,
              },
              {
                id: 'size',
                kind: ItemKind.Input,
                editable: false,
                title: 'Size',
                subTitle: paper?.size?.toString(),
                inline: true,
                devOnly: true,
              },
            ],
          },
          {
            id: 'others',
            title: '',
            items: () => [
              {
                id: 'references',
                // kind: ItemKind.Menu,
                // onPress: () => {},
                kind: ItemKind.Menu,
                title: `References` + (
                  paper?.numReferences ?
                  ` (${paper?.numReferences?.toLocaleString()})` :
                  ''),
              },
              {
                id: 'citations',
                // kind: ItemKind.Menu,
                // onPress: () => {},
                kind: ItemKind.Menu,
                title: `Citations` + (
                  paper?.numCitations ?
                  ` (${paper?.numCitations?.toLocaleString()})` :
                  ''),
              },
            ],
          },
          {
            id: 'urls',
            title: '',
            hidden: paper?.urls.length === 0,
            items: () =>
              paper?.urls.map(({url, desc}) => ({
                id: url,
                subTitle: url,
                title: desc,
                onPress: () => Linking.openURL(url),
              })) || [],
          },
          {
            id: 'others',
            title: '',
            items: () => [
              {
                id: 'id',
                kind: ItemKind.Input,
                readonly: true,
                title: 'ID',
                value: paper?.id,
                inline: true,
              },
              {
                id: 'doi',
                kind: ItemKind.Input,
                readonly: true,
                title: 'DOI',
                value: paper?.ids.doi,
                inline: true,
              },
              {
                id: 'mag',
                kind: ItemKind.Input,
                readonly: true,
                title: 'MAG',
                value: paper?.ids.mag,
                inline: true,
              },
              {
                id: 'dblp',
                kind: ItemKind.Input,
                readonly: true,
                title: 'DBLP',
                value: paper?.ids.dblp,
                inline: true,
              },
              {
                id: 'pdf',
                kind: ItemKind.Input,
                title: 'PDF',
                value: paper?.pdfUrl,
                readonly: true,
                inline: true,
              },
              {
                id: 'html',
                kind: ItemKind.Input,
                title: 'HTML',
                value: paper?.htmlUrl,
                readonly: true,
                inline: true,
                devOnly: true,
              },
            ],
          },
          {
            id: 'dates',
            title: '',
            items: () => [
              {
                id: 'date_added',
                kind: ItemKind.Input,
                title: 'Date Added',
                value: paper?.dateAdded ?
                  moment(paper?.dateAdded).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              {
                id: 'date_opened',
                kind: ItemKind.Input,
                title: 'Last Opened',
                value: paper?.dateOpened ?
                  moment(paper?.dateOpened).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              {
                id: 'date_modified',
                kind: ItemKind.Input,
                title: 'Last Modified',
                value: paper?.dateModified ?
                  moment(paper?.dateModified).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              // {
              //   id: 'date_fetched',
              //   kind: ItemKind.Input,
              //   title: 'Last Metadata Fetched',
              //   value: paper?.dateFetched ?
              //     moment(paper?.dateFetched).calendar() : 'Never',
              //   readonly: true,
              //   inline: true,
              // },
            ],
          },
          {
            id: 'sources',
            title: '',
            items: () =>
              Sources.map((s) => {
                const itemColor = paperSources[s.key] ? (
                  paperSources[s.key].error ?
                  theme.colors.error :
                  theme.colors.success) : theme.colors.black;
                return {
                  id: s.name,
                  // kind: ItemKind.Menu,
                  kind: ItemKind.Button,
                  title: s.name,
                  color: itemColor,
                  // route: s.name,
                  onPress: () => showModal(() => sourceViews[s.key], {
                    title: s.name,
                    height: 600,
                    width: 600,
                  }),
                  icon: paperSources[s.key] ? (
                  paperSources[s.key].error ? (
                    <IconError color={itemColor} />
                  ) : (
                    <IconSuccess color={itemColor} />
                  )
                ) : (
                  <IconDownload color={itemColor} />
                ),
                };
              }),
          },
        ]}
      />
    </View>
  );
};

export default Metadata;
