import {TouchableOpacity, View} from 'react-native';
import React from 'react';
import {Text, useTheme} from '@rneui/themed';
import {IconProps} from '../platform/icons';

export const Toolbar = ({
  borderTop,
  borderBottom,
  children,
  vertical = false,
}: {
  borderTop?: boolean;
  borderBottom?: boolean;
  children: React.ReactNode;
  vertical?: boolean;
}): JSX.Element => {
  const {theme} = useTheme();
  return (
    <View
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingVertical: vertical ? 8 : 0,
        flexDirection: vertical ? 'column' : 'row',
        height: vertical ? '100%' : 48,
        width: vertical ? 48 : '100%',
        borderTopWidth: borderTop ? 1 : 0,
        borderBottomWidth: borderBottom ? 1 : 0,
        borderRightWidth: vertical ? 1 : 0,
        borderColor: theme.colors?.border,
        backgroundColor: theme.colors?.header,
        alignItems: 'center',
      }}
    >
      {children}
    </View>
  );
};

export const ToolbarItem = ({
  icon,
  onPress,
  title,
  active,
  disabled,
  marginRight,
  testID,
}: {
  icon?: (props: IconProps) => JSX.Element;
  onPress?: () => void;
  title?: string;
  active?: boolean;
  disabled?: boolean;
  marginRight?: boolean;
  testID?: string;
}): JSX.Element => {
  const {theme} = useTheme();
  return (
    <TouchableOpacity
      style={{
        backgroundColor: active ?
          theme.colors?.backgroundHighlight :
          theme.colors?.header,
        // borderColor: active ? theme.colors?.border : undefined,
        // borderWidth: 1,
        marginRight: marginRight ? 4 : 0,
      }}
      testID={testID}
      onPress={onPress}
      activeOpacity={0.5}
      disabled={disabled}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        {icon && (
          <View style={{height: 32, width: 32, justifyContent: 'center'}}>
            {icon({
              color: disabled ? theme.colors?.disabled : theme.colors?.black,
            })}
          </View>
        )}
        {title && (
          <View
            style={{
              height: 32,
              justifyContent: 'center',
              marginRight: 8,
              marginLeft: icon ? 0 : 8,
            }}
          >
            <Text>{title}</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};
